import React, { Component, Fragment } from "react";
import { connect } from "react-redux";
import { setBreadcrumb } from "../../store/app-state/actions";
import Translate from "../../i18n/translate";
import { Grid, Typography, Tooltip } from "@material-ui/core";
import { AddCircle, Delete, Edit, Visibility } from "@material-ui/icons";
import InternalPageTitle from "../../components/conector-ui/conector-internal-page-title";
import StyledPaper from "../../components/conector-ui/conector-styled-components/styled-paper";
import StyledButton from "../../components/conector-ui/conector-styled-components/styled-button";
import Loading from "../../components/conector-ui/conector-loading";
import ConectorInputText from "../../components/conector-ui/conector-input-text";
import ConectorSelect from "../../components/conector-ui/conector-select";
import BanksService from "./bank-accounts-service";
import Toast from "../../components/toast/toast";
import ConectorDialogConfirmation from "../../components/conector-ui/conector-dialog-confirmation";
import Session from "../../utils/session-info";
import {bankImgLogo, 
		bankAccountTitles, 
		BanksCards, 
		AccountsCards, 
		addButton, 
		labelButton,
		IconButtonContainerStyle,
		IconButtonContainer} from "./bank-accounts-styles";

class BankAccounts extends Component {
	constructor(props) {
		super(props);

		props.setBreadcrumb([{
			label: "integrations"
		}, {
			label: "bank_accounts"
		}])

        this.state = {
			userAccessLevel: 0,
			deleteConfirmationModalProperties: undefined,
			loading: true,
			inputValueSearchBank: "",
			inputValueSearchAccount: "",
			inputBankOptionSelected: undefined,
			inputBanksOptions: new Array,
			cardBankSelected: undefined,
			listAddedBanks: new Array,
			listFilteredAddedBanks: new Array,
			accountsList: new Array,
			listFilteredAccounts: new Array
        }
    }

	componentWillMount = () => {
		BanksService.GetUserPermissionAccessLevelBankAccounts()
		.then(response => {
            if(response.status === 401) throw new Error("notHavePermissionToAccessBankAccounts")
            else return response.json()
        })
		.then(response => this.setState({userAccessLevel: response.accessLevel}, this.getBanks()))
		.catch(error => Toast.error(Translate(error.message)))
	}

	generateBankLogoImagePath(codBank){
		let bankLogoName = "Gesplan"

		switch (codBank) {
			case "237":
				bankLogoName = "Bradesco"
				break;
			
			case "341":
				bankLogoName = "Itaú"
				break;
			
			case "033":
				bankLogoName = "Santander"
				break;

			case "001":
				bankLogoName = "BB"
				break;
		}

		return `/static/images/banksLogos/${bankLogoName}.png`
	}

	getBanks(){
		BanksService.GetBanks().then((banks) => {
			let inputBanksOptions = []

			if(banks != undefined){
				inputBanksOptions = banks.map(bank => {
					return {
						value: bank.cod_bank,
						label: `${bank.cod_bank} - ${bank.legal_name}`,
						legal_name: bank.legal_name,
						trade_name: bank.trade_name
					}
				})
			}

            this.setState({inputBanksOptions}, () => this.getAccounts())
        })
	}

	getAccounts(){
		BanksService.GetBankAccounts()
		.then(accounts => {
			if(accounts == undefined) accounts = []
			accounts = accounts.filter(account => account.cod_instance == Session().codInstance)
			this.getBanksFromAccountsAlreadyRegistered(accounts)
			this.setState({accountsList: accounts, loading: false})
		})
	}

	getBanksFromAccountsAlreadyRegistered(bankAccounts){
		const { inputBanksOptions } = this.state

		let listBanks = new Array

		bankAccounts.forEach(account => {
			inputBanksOptions.forEach(bankOption => {
				if(bankOption.value === account.cod_bank) listBanks.push(bankOption)
			})
		})

		const listAddedBanks = Array.from(new Set(listBanks.map(bank => JSON.stringify(bank)))).map(bank => JSON.parse(bank));

		this.setState({listAddedBanks, listFilteredAddedBanks: listAddedBanks})
	}

	generateCardListAddedBanks(){
		const { listFilteredAddedBanks, userAccessLevel } = this.state

		return listFilteredAddedBanks.map(bankOption => {
			return (
				<BanksCards onClick={() => this.setCardBankSelected(bankOption.value)}>
					<Grid container={true} spacing={24} wrap="nowrap" alignItems="center">
				 		<img src={this.generateBankLogoImagePath(bankOption.value)} style={bankImgLogo}/>
						<Grid item xs={12}>
							<Typography variant="title" style={bankAccountTitles}>{bankOption.legal_name}</Typography>
							<Typography variant="caption" style={bankAccountTitles}>{bankOption.trade_name}</Typography>
						</Grid>
						{userAccessLevel == 2 || userAccessLevel == 4 ? 
						<Grid item>
							<div style={{float: "right"}}>
								<Tooltip placement="left-end" title={<h3>{Translate("AddBankAccount")}</h3>}>
									<IconButtonContainer style={IconButtonContainerStyle} onClick={() => this.redirectToViewOrAddEditAccountPage(bankOption.value)}>
										<AddCircle/><label style={labelButton}>{Translate("AddAccount")}</label>
									</IconButtonContainer>
								</Tooltip> 
								<Tooltip placement="left-end" title={<h3>{Translate("RemoveBank")}</h3>}>
									<IconButtonContainer style={IconButtonContainerStyle} onClick={() => this.removeBankFromList(bankOption.value)}>
										<Delete/><label style={labelButton}>{Translate("RemoveBank")}</label>
									</IconButtonContainer>
								</Tooltip>
							</div>
						</Grid> : undefined}
					</Grid>
				</BanksCards>
			)
		})
	}

	generateButtonsByAccessLevel = bankAccount => {
		const { userAccessLevel } = this.state

		if(userAccessLevel == 1){
			return(
				<Grid item>
					<Tooltip placement="left-end" title={<h3>{Translate("ViewBankAccount")}</h3>}>
						<IconButtonContainer style={IconButtonContainerStyle} onClick={() => this.redirectToViewOrAddEditAccountPage(bankAccount.cod_bank, bankAccount.id_account)}>
							<Visibility/>
						</IconButtonContainer>
					</Tooltip>
				</Grid>
			)
		}

		if(userAccessLevel == 2 || userAccessLevel == 4){
			return(
				<Grid item>
					<Tooltip placement="left-end" title={<h3>{Translate("EditBankAccount")}</h3>}>
						<IconButtonContainer style={IconButtonContainerStyle} onClick={() => this.redirectToViewOrAddEditAccountPage(bankAccount.cod_bank, bankAccount.id_account)}>
							<Edit/>
						</IconButtonContainer>
					</Tooltip>
					<Tooltip placement="left-end" title={<h3>{Translate("RemoveBankAccount")}</h3>}>
						<IconButtonContainer style={IconButtonContainerStyle} onClick={() => this.openDeleteConfirmationModal(bankAccount)}>
							<Delete/>
						</IconButtonContainer>
					</Tooltip>
				</Grid>
			)
		}
	}

	generateCardListAddedAccounts(){
		const { listFilteredAccounts } = this.state

		return listFilteredAccounts.map(account => {
			return (
				<AccountsCards>
					<Grid container={true} spacing={24} wrap="nowrap" alignItems="center">
						<Grid item xs={12}>
							{account.account_digit == undefined || account.account_digit == '' ? 
								<Typography variant="title" style={bankAccountTitles}>{`C/C ${account.account}`}</Typography>
								: <Typography variant="title" style={bankAccountTitles}>{`C/C ${account.account}-${account.account_digit}`}</Typography>
							}
							{account.bank_branch_digit == undefined || account.bank_branch_digit == '' ? 
								<Typography variant="caption" style={bankAccountTitles}>{`AG ${account.bank_branch}`}</Typography>
								: <Typography variant="caption" style={bankAccountTitles}>{`AG ${account.bank_branch}-${account.bank_branch_digit}`}</Typography>
							}
						</Grid>
						{this.generateButtonsByAccessLevel(account)}
					</Grid>
				</AccountsCards>
			)
		})
	}

	setInputValueSearchBank(inputSearchValue){
		this.setState({
			inputValueSearchBank: inputSearchValue
		}, () => this.filterListAddedBanks())		
	}

	setInputValueSearchAccount(inputSearchValue){
		this.setState({
			inputValueSearchAccount: inputSearchValue
		}, () => this.filterListAccounts())
	}

	setCardBankSelected = codBank => this.setState({cardBankSelected: codBank}, () => this.filterListAccounts())

	addBankToList(){
		const { listAddedBanks, inputBankOptionSelected } = this.state

		if(inputBankOptionSelected != undefined || inputBankOptionSelected != null){
			let alreadyAdded = listAddedBanks.some(AddedBank => {
				return AddedBank.value == inputBankOptionSelected.value
			})
	
			if(alreadyAdded) Toast.error("BankAlreadyBeenAdded")
			else {
				listAddedBanks.push(inputBankOptionSelected)

				this.setState({listAddedBanks}, () => {
					this.setInputValueSearchBank("")
					this.setInputValueSearchAccount("")
					this.setCardBankSelected(undefined)
				})
			}
		} else Toast.error("SelectBankOption")
	}

	redirectToViewOrAddEditAccountPage(codBank, idAccount = undefined){
		if(idAccount == undefined) window.location.replace(`#/bank_accounts/${codBank}`)
		else window.location.replace(`#/bank_accounts/${codBank}/${idAccount}`)
	}

	removeBankFromList(codBank){
		const { listAddedBanks, accountsList } = this.state

		let existAccounts = accountsList.some(account => {return account.cod_bank == codBank})

		if(existAccounts) Toast.error("notPossibleDeleteThisBank")
		else {
			listAddedBanks.forEach((AddedBank, index) => {
				if(AddedBank.value == codBank) listAddedBanks.splice(index, 1)
			})
	
			this.setState({listAddedBanks}, () => {
				this.setInputValueSearchBank("")
				this.setInputValueSearchAccount("")
				this.setCardBankSelected(undefined)
			})
		}
	}

	openDeleteConfirmationModal(account){
		this.setState({
			deleteConfirmationModalProperties: {
				type: "danger",
				title: "deleteConfirmation",
				message: "reallyWantDeleteBankAccount",
				open: true,
				onDismiss: () => this.setState({deleteConfirmationModalProperties: {open: false}}),
				onClose: () => this.removeAccountFromList(account)
			}
		})
	}

	removeAccountFromList(account){
		let idAccount = account.id_account
		let cod_bank = account.cod_bank

		BanksService.DeleteAccount(idAccount, cod_bank)
        .then(response => {
            if(response.status === 401) throw new Error("notHavePermissionToDeleteBankAccounts")
			if(response.status === 409) throw new Error("AccountExistsInStructure")
            else return response.json()
        })
		.then(() => {
			BanksService.GetBankAccounts()
			.then(accounts =>
				this.setState({
					deleteConfirmationModalProperties: {open: false},
					accountsList: accounts}, () => {
						this.setInputValueSearchBank("")
						this.setInputValueSearchAccount("")
						this.setCardBankSelected(cod_bank)
						this.getBanksFromAccountsAlreadyRegistered(this.state.accountsList)
					}
				)
			)
		})
		.catch(error => {
			this.setState({deleteConfirmationModalProperties: {open: false}}, () => Toast.error(Translate(error.message)))
		})
	}

	filterListAddedBanks(){
		const { listAddedBanks, inputValueSearchBank } = this.state

		let listFilteredAddedBanks = listAddedBanks.filter(bank => (
			bank.legal_name.toLowerCase().includes(inputValueSearchBank.toLowerCase())
		))

		this.setState({listFilteredAddedBanks})
	}

	filterListAccounts(){
		const { accountsList, inputValueSearchAccount, cardBankSelected } = this.state

		let listFilteredAccounts = accountsList.filter(account => (
			account.account.toLowerCase().includes(inputValueSearchAccount.toLowerCase())
		)).filter(account => {if(account.cod_bank == cardBankSelected) return account})

		this.setState({listFilteredAccounts})
	}

    render(){
        const {
			loading,
			userAccessLevel,
			deleteConfirmationModalProperties,
			inputValueSearchBank,
			inputValueSearchAccount,
			inputBanksOptions,
			inputBankOptionSelected
		} = this.state

        if (loading) return <Loading />

        return (
			<Fragment>
				<InternalPageTitle title={Translate("bank_accounts")} />
				<Grid container spacing={24}>
					<Grid item xs={6}>
						<Grid container spacing={24}>
						{userAccessLevel == 2 || userAccessLevel == 4 ?
							<Grid item xs={12}>
								<StyledPaper>
									<Grid item xs={12}>
										<ConectorSelect
											placeholder="SelectBank"
											onChange={selectedOption => this.setState({inputBankOptionSelected: selectedOption})}
											options={inputBanksOptions}
										/>
										<StyledButton
											variant="contained"
											disabled={inputBankOptionSelected == undefined ? true : false}
											onClick={() => this.addBankToList()} 
											style={addButton}>
											<AddCircle/>{Translate("AddBank")}
										</StyledButton>
									</Grid>
								</StyledPaper>
							</Grid>: undefined}
							<Grid item xs={12}>
								<StyledPaper>
									<ConectorInputText
										value={inputValueSearchBank} 
									    onChange={element => {this.setInputValueSearchBank(element.target.value)}}
										placeholder="SearchBank"/>
									{this.generateCardListAddedBanks()}
								</StyledPaper>
							</Grid>
						</Grid>
					</Grid>
					<Grid item xs={6}>
						<Grid container={true} spacing={24}>
							<Grid item xs={12}>
								<StyledPaper>
									<ConectorInputText 
										value={inputValueSearchAccount}
										onChange={element => {this.setInputValueSearchAccount(element.target.value)}}
										placeholder="SearchAccount" />
									{this.generateCardListAddedAccounts()}
								</StyledPaper>
							</Grid>
						</Grid>
					</Grid>
				</Grid>

				<ConectorDialogConfirmation dialogOptions={deleteConfirmationModalProperties}/>
            </Fragment>
        )
    }
}

const mapDispatchToProps = (dispatch) => {
	return {
		setBreadcrumb: (steps) => dispatch(setBreadcrumb(steps))
	}
}

export default connect(null, mapDispatchToProps)(BankAccounts);