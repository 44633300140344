import React, { Component, Fragment } from "react";
import { connect } from "react-redux";
import * as appStateReducer from "../../../store/app-state/reducer";
import { setBreadcrumb } from "../../../store/app-state/actions";
import styled from "styled-components";

import StyledButton from "../../../components/conector-ui/conector-styled-components/styled-button";
import ConectorGrid from "../../../components/conector-ui/conector-grid/conector-grid";
import Translate from "../../../i18n/translate";

import { CSVLink } from 'react-csv';
import LogService from "./template-history-service";
import { Grid } from "@material-ui/core";
import StyledPaper from "../../../components/conector-ui/conector-styled-components/styled-paper";
import InternalPageTitle from "../../../components/conector-ui/conector-internal-page-title"

import Session from "../../../utils/session-info";
import ConectorSelect from "../../../components/conector-ui/conector-select";

const StyledGrid = styled(Grid)`

    && {
        position: relative;

        > div:nth-child(3) {
            width: calc(100% - 24px);
            height: calc(100% - 24px);
            top: 12px;
            position: absolute;
            z-index: 3;
            background-color: #FFF;
        }
    }

`;

class TemplateHistory extends Component {
    constructor(props) {
        super(props);

        props.setBreadcrumb([{
            label: "administration"
        }, {
            label: "template_history"
        }]);
        
        this.state = {
            ...props,
            dataSource: [],
            interfaceOption: [],
            loading: false,
            optionSelect: 0,
            baseSource: [],
            id: props.id,
            gridOptions: {
				multiSelect: false,
                hideBtnNew: true,
                hideBtnEdit: true,
                hideBtnDelete: true,
                hideBtnCopy: true,
                hideFilter: true,
				columns: [{
                    title: "company",
                    field: "companyLog"
                }, {
                    title: "instance",
                    field: "instanceLog"
                }, {
                    title: "interface",
                    field: "desinterface"
                }, {
                    title: "events",
                    field: "event"
                }, {
                    title: "status",
                    field: "statusLog"
                }, {
                    title: "user",
                    field: "userresponsable"
                }, {
                    title: "Modificado Em",
                    field: "datechange",
                    cellFilter: "ddMMyyyy-hh:mm"
                }]
			}
        };
    }

    componentWillMount() {
        const { codInstance } = Session();
        let { interfaceOption, baseSource } = this.state;

        LogService.GetAllByTemplateHistory(codInstance).then(dataSource => {
            if (dataSource){
                baseSource = dataSource
                interfaceOption = dataSource
                    .filter(res => res.event = res.event.deseventname)
                    .map(item => ({ 
                        value: item.interfaceId,
                        label: item.desinterface
                    }))
                    
                    baseSource.map(item => {
                        delete item.id
                        delete item.codinstance
                    })

                    interfaceOption = interfaceOption.filter((item, a) => {
                        const b = JSON.stringify(item)
    
                        return a === interfaceOption.findIndex(c => {
                            return JSON.stringify(c) === b
                        })
                    })

                    interfaceOption.unshift({value: 0, label: "all"})

                    this.setState({ 
                        interfaceOption,
                        baseSource 
                    });

            }

            this.hendleSeach()
        });
        
    }

    handleChangeSelectOption = (connectionTypeValue) => {
        let { optionSelect } = this.state;

        optionSelect = connectionTypeValue.value

        this.setState({ optionSelect }, () => {
            this.hendleSeach()
        });

    }

    hendleSeach = () => {
        const { optionSelect, baseSource } = this.state;

         this.setState({ dataSource: optionSelect === 0 ? 
                                    baseSource : 
                                    baseSource.filter(item => item.interfaceId === optionSelect) })
    }

    render() {
        const { dataSource, gridOptions, interfaceOption, optionSelect } = this.state;
        return (
            <Fragment>
                <InternalPageTitle title={Translate("template_history")} />
                <StyledPaper>
                    <Grid spacing={24} container alignItems={"center"}>
                        <Grid item xs={12} md={6} xl={4}>
                            <ConectorSelect
                                label="templateFilter"
                                help="select_template"
                                name="codInterfaceInput"
                                placeholder="choose"
                                onChange={this.handleChangeSelectOption}
                                options={interfaceOption}
                                value={interfaceOption.find(option => option.value === optionSelect) || null}
                            /> 
                        </Grid>
                        <Grid item xs={4} md={2}>
                            <CSVLink data={dataSource} filename={"templateHistory.csv"} style={{ textDecoration: 'none' }}>
                            <StyledButton variant="contained">
                                {Translate("downloadReport")}
                            </StyledButton>
                        </CSVLink>
                        </Grid>
                    </Grid>
                    <Grid container={true} spacing={24}>
                        <StyledGrid item={true} xl={12} lg={12} md={12} sm={12}>
                            <ConectorGrid 
                                gridOptions={gridOptions} 
                                dataSource={dataSource}
                            >
                            </ConectorGrid>
                        </StyledGrid> 
                    </Grid>
                </StyledPaper>
            </Fragment>
        );
    }
}

const mapStateToProps = store => {
	return {
		permission: appStateReducer.getUserPermissionByView(store)
	}
}

const mapDispatchToProps = dispatch => {
    return {
        setBreadcrumb: (steps) => dispatch(setBreadcrumb(steps))
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(TemplateHistory);
