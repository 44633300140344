import React, { Component, Fragment } from 'react'
import styled from 'styled-components'
import StyledFormHelpText from './conector-styled-components/styled-form-help-text'
import { Checkbox, FormControlLabel } from '@material-ui/core'
import Translate from '../../i18n/translate'

const StyleFormControlLabel = styled(FormControlLabel)`
  && { 
    height: 100%; 
    span { 
      color: #6F8296; 
    }
    .MuiIconButton-root {
      padding: 0 12px;
    }
  }
`

class ConectorCheckbox extends Component {
  constructor(props) {
    super(props);
    this.state = { 
      ...props
    }
  }

  componentWillReceiveProps(props) {
    this.setState({...props})
  }

  handleChange = e => {
    if (this.state.onChange) {
      this.state.onChange(e)
    }
  }

  render() {
    const {
      invisible,
      name,
      checked,
      value,
      color,
      label,
      help
    } = this.state;

    if (invisible) {
      return (<Fragment></Fragment>);
    }

    return (
      <Fragment>
        <StyleFormControlLabel
          control={
            <Checkbox
              id={`conector-input-checkbox-${name}`}
              name={name}
              checked={checked}
              onChange={this.handleChange.bind(this)}
              value={Boolean(value) ? value.toString() : ''}
              color={color}
            />
          }
          labelPlacement='end'
          label={Translate(label)}
        />
        {
          help 
            ? <StyledFormHelpText>{Translate(help)}</StyledFormHelpText>
            : null
        }
      </Fragment>
    )
  }
}

export default ConectorCheckbox;