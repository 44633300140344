import React, {
    Component,
    Fragment
} from "react";
import { connect } from "react-redux";
import styled from "styled-components";
import { getFlatDataFromTree } from "react-sortable-tree";

import {
    Tabs,
    Tab,
    Grid
} from "@material-ui/core";

import Session from "../../../utils/session-info";
import ObjectUtils from "../../../utils/object-utils";

import Loading from "../../../components/conector-ui/conector-loading";

import { setStepStatus } from "../../../store/app-state/actions";
import * as inputSelector from "../../../store/interfaces/reducer";
import * as wizardStore from "../../../store/wizard/wizard-store-reducer";
import { setWizardState } from "../../../store/wizard/wizard-store-actions";

import Translate from "../../../i18n/translate";

import WizardService from "../wizard-service";

import WizardStructureExtras from "./extras/wizard-structure-extras";

import WizardStructureDetailsTabs from "./details/wizard-structure-details-tabs";
import WizardStructureDetailsTree from "./details/wizard-structure-details-tree";

const StyledTabs = styled(Tabs)`
    && {
        position: absolute;
        width: 100%;
        top: -47px;

        button {
            color: #6F8296;
            font-size: 0.75rem;

            border-top: 1px solid #fff;
            border-left: 1px solid #fff;
            border-right: 1px solid #fff;

            &:hover, &.MuiTab-selected {
                background-color: #f4f4f4;
                border-top: 1px solid #B8C6D6;
                border-left: 1px solid #B8C6D6;
                border-right: 1px solid #B8C6D6;
                border-top-left-radius: 4px;
                border-top-right-radius: 4px;
            }
        }

        .MuiTabs-fixed {
            overflow-x: initial;
        }

        .MuiTabs-indicator {
            height: 0;
            background-color: #B8C6D6;
        }
    }
`

const StyledGrid = styled(Grid)`
    && {
        padding: 20px;
        margin-top: 48px;
        border: 1px solid #B8C6D6;
        border-top-right-radius: 4px;
        border-bottom-left-radius: 4px;
        border-bottom-right-radius: 4px;
        min-height: calc(100vh - 495px);
    }
`

const StyledGridItem = styled(Grid)`
    && {
        margin-top: 20px;

        @media screen and (min-width:1280px) {
            padding-left: 20px;
            margin-top: 0;
        }
    }
`

class WizardStructureOld extends Component {
    constructor(props) {
        super(props);

        var {
            wizardState,
        } = props;

        const stepProperties = wizardState.eventProperties[wizardState.currentStep - 1];

        var stepStructure = wizardState.event.structures.find(struc =>
            struc.codEventProperty === stepProperties.codEventProperty
        )
        if (!stepStructure) {
            stepStructure = {
                codStructure: 0,
                codConnectionAction: wizardState.event.codConnectionAction,
                codConnectionType: wizardState.event.codConnectionType,
                codInstanceConnection: wizardState.event.codInstanceConnection,
                codEventProperty: stepProperties.codEventProperty,
                codInstance: Session().codInstance,
                structureDetails: [],
                structureExtras: [],
                isMain: true,
                indPosition: 1,
                codMaster: 0
            }

            wizardState.event.structures.push(stepStructure);
            props.setWizardState(wizardState);
        }
        this.state = {
            stepExtras: {
                detailsExtras: [],
                structureExtras: []
            },
            stepAttributes: {},
            stepProperties,
            stepStructure,
            structureIn: stepProperties.desEventProperty === 'structure_in',
            treeData: [],
            selectedTab: 0,
            tabs: [],
            isLoading: true,
            ...props
        }
    }

    getDetailsAndFieldsBySelectedInput = newState => {
        WizardService.GetDetailsAndInputsByCodStructure(newState.structureInInput.codStructure)
            .then(({
                detailsFields = [],
                structureDetails = []
            }) => {

                var newTreeData = []
                var details = []

                structureDetails.sort((o1, o2) => o2.indPosition - o1.indPosition)
                structureDetails.forEach(e => {
                    e.children = []
                    e.codStructureDetail = e.idStructureDetail,
                        e.idDetailInput = e.idStructureDetail
                })

                structureDetails.forEach(e => {
                    if (e.codMaster) {
                        let parent = structureDetails.find(detail =>
                            detail.indPosition === e.codMaster
                        )
                        parent.children.push(e)
                        parent.children.sort((o1, o2) => o1.indPosition - o2.indPosition)
                    }
                })

                structureDetails
                    .sort((o1, o2) => o1.indPosition - o2.indPosition)
                    .forEach(e => { e.expanded = true });

                structureDetails
                    .filter(detail => !detail.codMaster)
                    .forEach(detail => {
                        details.push({
                            codStructureDetail: detail.idStructureDetail,
                            title: detail.title,
                            ...detail
                        })
                    })

                details.forEach(detail => {
                    newTreeData = newTreeData.concat([detail]);
                })

                if (newTreeData.length) {
                    newTreeData[0].firstSelectAfterInputChange = true;
                }

                newState.treeData = newTreeData;
                newState.detailsFields = detailsFields;
                this.setState(newState);
            });
    }

    updateFromTree = newState => {
        let state = { ...this.state };

        if (newState.treeData) {
            state.treeData = newState.treeData;
        }

        state.selectedNode = newState.selectedNode;
        state.selectedNodeId = newState.selectedNodeId;

        if (newState.structureInInput) {
            state.structureInInput = newState.structureInInput;
            state.stepStructure.structureInInput = newState.structureInInput;
        }

        if (state.selectedNode
            && state.structureInInput) {
            state.selectedInputFields = state.detailsFields.filter(detailField =>
                state.selectedNode.node.idDetailInput === detailField.id
            );
        } else {
            delete state.selectedInputFields;
        }

        if (newState.structureInInput) {
            this.getDetailsAndFieldsBySelectedInput(state);
        } else {
            this.setState(state);
        }
    }

    updateFromTabs = (selectedNode) => {
        this.setState({ selectedNode })
    }

    setChangedDetail(action, sourceDetail) {
        let { wizardState } = this.state;

        if (sourceDetail.idStructureDetail) {
            let changedDetail = wizardState.event.changedDetails[sourceDetail.path];
            if (!changedDetail) {
                changedDetail = {};
                wizardState.event.changedDetails[sourceDetail.path] = changedDetail;
            }

            changedDetail.action = action;
            changedDetail.changes = {
                title: sourceDetail.title,
                codStructureDetail: sourceDetail.codStructureDetail,
                codMaster: sourceDetail.codMaster,
                indPosition: sourceDetail.indPosition
            };
        }
    }

    setChangedField(action, sourceField) {
        let { wizardState } = this.state;

        if (sourceField.codField) {
            let changedField = wizardState.event.changedFields[sourceField.path];
            if (!changedField) {
                changedField = {};
                wizardState.event.changedFields[sourceField.path] = changedField;
            }

            changedField.action = action;
            changedField.changes = {
                desField: sourceField.desField,
                indPosition: sourceField.indPosition,
                fieldAttributes: sourceField.fieldAttributes.map(attribute => {
                    return {
                        codAttribute: attribute.codAttribute,
                        desValue: attribute.desValue,
                        desLabel: attribute.desLabel,
                        indPosition: attribute.indPosition
                    }
                })
            };
        }
    }

    getAttributes = (
        stepExtras,
        tabs,
        treeData,
        attributeGroups,
        structureModel
    ) => {

        var { stepAttributes } = this.state;

        if (stepExtras.structureExtras.length) {
            const tabIdx = tabs.length ? 1 : 0;
            tabs.push({
                label: Translate("structure_extra"),
                indPosition: tabIdx
            })
        }

        const idsDetails = stepExtras.detailsExtras.map(
            extra => extra.codAttribute
        );

        const idsStructure = stepExtras.structureExtras.map(
            extra => extra.codAttribute
        );

        WizardService.GetAttributesByIdArray(idsDetails.concat(idsStructure))
            .then(attrs => {
                var masterAttrs = attrs.filter(attr => !attr.CodMaster);

                attrs.forEach(attr => {
                    if (attr.CodMaster) {
                        var master = masterAttrs.find(master =>
                            master.codAttribute === attr.CodMaster
                        )

                        if(master != undefined){
                            if (master.options) {
                                master.options.push(attr);
                            } else {
                                master.options = [attr];
                            }
                        }
                    }
                });

                masterAttrs.sort((o1, o2) => o1.indPosition < o2.indPosition);

                stepAttributes.detailsExtrasAttributes = [];
                stepAttributes.structureExtrasAttributes = [];
                masterAttrs.forEach(attr => {
                    if (idsStructure.includes(attr.codAttribute)) {
                        stepAttributes.structureExtrasAttributes.push(attr);
                    } else {
                        stepAttributes.detailsExtrasAttributes.push(attr);
                    }
                });

                this.setState({
                    stepAttributes,
                    tabs,
                    attributeGroups,
                    stepExtras,
                    structureModel,
                    treeData,
                    isLoading: false
                });
            });
    }

    componentDidMount = _ => {
        let {
            wizardState,
            stepProperties,
            tabs,
            stepStructure,
            treeData,
            attributes,
            structureModel
        } = this.state;

        if (stepStructure.structureDetails.length) {
            let detailWithModel = stepStructure.structureDetails.find(detail =>
                detail.output && detail.idStructureDetail
            );

            if (detailWithModel) {
                structureModel = detailWithModel.output;
            }

            let orderedDetails = stepStructure.structureDetails.sort(
                (s1, s2) => { return s1.codMaster - s2.codMaster }
            ).map(detail => {
                if (!detail.path) {
                    detail.path = detail.title;
                }

                return {
                    ...detail,
                    expanded: true,
                    children: [],
                    codInstance: Session().codInstance,
                    detailExtras: detail.structureExtras
                }
            })

            orderedDetails.forEach(detail => {
                if (detail.codMaster > 0) {
                    let parentDetail = orderedDetails.find(det => {
                        if (detail.codMaster === det.codStructureDetail) {
                            detail.path = det.path + "." + detail.path

                            return det;
                        }
                    });

                    parentDetail.children.push(detail);
                }

                (detail.fields || []).map(field => {
                    if (!field.path) {
                        let fieldName = field.fieldAttributes.find(attribute => attribute.codAttribute == attributes.to);
                        if (!fieldName) {
                            fieldName = field.fieldAttributes.find(attribute => attribute.codAttribute == attributes.from);
                        }

                        field.path = detail.path + "." + (fieldName ? fieldName.desValue : "")
                    }
                });
            })

            treeData = orderedDetails.filter(detail => detail.codMaster === 0);
        }

        //buscar extras e attribute groups
        var codConnectionMode;
        if (wizardState.event.instanceConnection) {
            codConnectionMode = wizardState.event.instanceConnection.codConnectionMode
        } else {
            codConnectionMode = 0;
        }

        WizardService.GetExtrasAndAttrGroupsByCodConnectionActionCodEventProp(
            wizardState.event.codConnectionAction,
            codConnectionMode,
            stepProperties.codProperty
        ).then((responses) => {
            const eventExtras = responses[0].json();
            const attributeGroups = responses[1].json();

            Promise.all([eventExtras, attributeGroups]).then(
                (jsons) => {
                    const eventExtras = jsons[0];

                    const stepExtras = {
                        detailsExtras: eventExtras.filter(extra => !extra.isHeader),
                        structureExtras: eventExtras.filter(extra => extra.isHeader)
                    }

                    const attributeGroups = jsons[1];

                    if (attributeGroups.length) {
                        tabs.push({
                            label: Translate("structure_detail"),
                            indPosition: 0
                        })
                    }

                    if (eventExtras.length) {
                        this.getAttributes(
                            stepExtras,
                            tabs,
                            treeData,
                            attributeGroups,
                            structureModel
                        );
                    } else {
                        this.setState({
                            isLoading: false,
                            tabs,
                            attributeGroups,
                            treeData,
                            structureModel
                        })
                    }
                }
            )
        });
    }

    componentWillMount = _ => {
        var {
            stepStructure,
            inputs,
            structureIn
        } = this.state;

        //separar em duas funcoes
        if (structureIn) {
            var structureInInput,
                stepInputs = [...inputs];

            if (stepStructure.structureDetails.length) {
                var options = stepStructure
                    .structureDetails
                    .filter(detail => detail.idDetailInput > 0)
                    .map(detail => detail.idDetailInput);

                structureInInput = stepInputs.find(structureDetail =>
                    options.includes(structureDetail.idStructureDetail)
                );
            }

            if (!ObjectUtils.equals({}, structureInInput)) {
                this.getDetailsAndFieldsBySelectedInput({
                    structureInInput,
                    stepInputs,
                    structureInputOptions: stepInputs.map(option => {
                        return {
                            label: option.output,
                            value: option.codStructure,
                            codStructureDetail: option.idStructureDetail
                        }
                    })
                })
            } else {
                this.setState({
                    stepInputs,
                    structureInputOptions: stepInputs.map(option => {
                        return {
                            label: option.output,
                            value: option.codStructure,
                            codStructureDetail: option.idStructureDetail
                        }
                    })
                })
            }
        } else {
            if (stepStructure.structureDetails) {
                const detailWithOutName = stepStructure.structureDetails.find(detail =>
                    detail.output
                )
                if (detailWithOutName) {
                    stepStructure.desOutputDetails = detailWithOutName.output;
                    this.setState({ stepStructure });
                }
            }
        }
    }

    componentWillUnmount = _ => {
        let {
            wizardState,
            structureIn,
            treeData,
            stepStructure,
            stepProperties,
            stepExtras,
            stepAttributes
        } = this.state;

        // arrumar esse bloco
        wizardState.event.structures.forEach(struc => {
            if (struc.structureExtras) {
                struc.structureExtras.forEach(extra => {
                    extra.value = String(extra.value);
                });
            }
        });
        //

        const treeNodes = getFlatDataFromTree({
            treeData,
            getNodeKey: ({ treeIndex }) => {
                return treeIndex;
            },
            ignoreCollapsed: false
        })

        let newDetails = [];

        treeNodes.forEach(node => {
            const parentId = node.parentNode
                ? node.path[node.path.length - 2] + 1
                : 0,
                id = node.treeIndex + 1,
                title = node.node.title;

            let detail = stepStructure.structureDetails.find(detail => {
                if (node.node.idStructureDetail) {
                    return detail.idStructureDetail === node.node.idStructureDetail
                }
            })

            if (!detail) {
                detail = {
                    codStructureDetail: id,
                    indPosition: id,
                    codMaster: parentId,
                    fields: node.node.fields,
                    structureExtras: node.node.detailExtras,
                    idDetailInput: node.node.idDetailInput,
                    structureExtras: node.node.detailExtras,
                    codInstance: Session().codInstance,
                    title
                }
            } else {
                detail.codMaster = parentId
                detail.codStructureDetail = id;
                detail.indPosition = id;
                detail.title = title;
                detail.fields = node.node.fields;
                detail.structureExtras = node.node.detailExtras;
            }

            if (structureIn) {
                if (stepStructure.structureInInput) {
                    detail.input = stepStructure.structureInInput.output;
                }
            } else {
                detail.output = stepStructure.desOutputDetails || detail.output;
            }

            newDetails.push(detail);
        });

        stepStructure.structureDetails.filter((detail) => detail.idStructureDetail).forEach(
            (detail) => {
                if (!newDetails.find(nd => nd.idStructureDetail === detail.idStructureDetail)) {
                    wizardState.event.deletedDetails.push(detail.idStructureDetail);
                }
            }
        );

        stepStructure.structureDetails = newDetails;

        const idx = wizardState.event.structures.findIndex(struc =>
            struc.codEventProperty === stepProperties.codEventProperty
        )
        wizardState.event.structures.splice(idx, 1);
        wizardState.event.structures.push(stepStructure);

        this.props.setWizardState({
            event: wizardState.event,
            detailsExtras: stepExtras.detailsExtras,
            detailsExtrasAttributes: stepAttributes.detailsExtrasAttributes
        })
    }

    handleChangeTab = (_, selectedTab) => {
        if (selectedTab === this.state.selectedTab) {
            return;
        }

        this.setState({ selectedTab });
    }

    renderTabs = () => {
        const { tabs } = this.state
        return tabs.map(tab => (
            <Tab key={tab.label} label={tab.label} />
        ))
    }

    componentWillReceiveProps = props => {
        this.setState({
            wizardState: { ...props.wizardState }
        })
    }

    renderContent = selectedTab => {
        const {
            attributes,
            attributeGroups,
            structureIn,
            stepStructure,
            stepProperties,
            stepAttributes,
            treeData,
            wizardState,
            structureInInput,
            structureInputOptions,
            selectedNodeId,
            selectedInputFields,
            stepExtras,
            selectedNode,
            stepInputs,
            tabs
        } = this.state

        if (tabs[selectedTab].label === Translate("structure_detail")) {
            return (
                <Fragment>
                    <Grid item xs={12} lg={4}>
                        <WizardStructureDetailsTree
                            structureIn={structureIn}
                            stepStructure={stepStructure}
                            stepProperties={stepProperties}
                            structureInputOptions={structureInputOptions}
                            structureInInput={structureInInput}
                            event={wizardState.event}
                            stepInputs={stepInputs}
                            treeData={treeData}
                            treeHeight={'calc(100vh - 690px)'}
                            update={this.updateFromTree.bind(this)}
                            selectedNode={selectedNode}
                            selectedNodeId={selectedNodeId}
                            setChangedDetail={this.setChangedDetail.bind(this)}
                            renderEvent={this.render.bind(this)}
                        />
                    </Grid>
                    <StyledGridItem item xs={12} lg={8}>
                        <WizardStructureDetailsTabs
                            attributes={attributes}
                            detailsExtras={stepExtras.detailsExtras}
                            detailsExtrasAttributes={stepAttributes.detailsExtrasAttributes}
                            selectedNode={selectedNode}
                            wizardState={wizardState}
                            stepStructure={stepStructure}
                            stepProperties={stepProperties}
                            attributeGroups={attributeGroups}
                            structureInInput={structureInInput}
                            structureInputOptions={structureInputOptions}
                            selectedInputFields={selectedInputFields}
                            update={this.updateFromTabs.bind(this)}
                            setChangedField={this.setChangedField.bind(this)}
                        />
                    </StyledGridItem>
                </Fragment>
            )
        } else if (tabs[selectedTab].label === Translate("structure_extra")) {
            if (stepAttributes.structureExtrasAttributes) {
                return (
                    <Fragment>
                        <WizardStructureExtras structureExtras={stepExtras.structureExtras}
                            structureExtrasAttributes={stepAttributes.structureExtrasAttributes}
                            wizardState={wizardState}
                            stepStructure={stepStructure}
                            stepProperties={stepProperties}
                            updateStepStructure={this.updateStepStructure}
                        />
                    </Fragment>
                )
            } else {
                return null
            }
        }
    }

    disabledStep = _ => {
        const {
            wizardState
        } = this.state;

        const isDisabled = false;
        this.props.setStepStatus(wizardState.currentStep, !isDisabled)

        return isDisabled;
    }

    updateStepStructure = stepStructure => {
        this.setState({ stepStructure });
    }

    render() {
        const {
            selectedTab,
            isLoading,
            wizardState
        } = this.state

        if (isLoading) return <Loading />

        const disabledStep = this.disabledStep();

        if (disabledStep !== wizardState.disabledStep) {
            this.props.setWizardState({ disabledStep })
        }

        return (
            <Fragment>
                <StyledTabs
                    value={selectedTab}
                    onChange={this.handleChangeTab.bind(this)}
                >
                    {this.renderTabs()}
                </StyledTabs>
                <StyledGrid container>
                    {this.renderContent(selectedTab)}
                </StyledGrid>
            </Fragment>
        )
    }
}

const mapDispatchToProps = dispatch => {
    return {
        setWizardState: wizardState => dispatch(
            setWizardState(wizardState)
        ),
        setStepStatus: (step, complete) => dispatch(setStepStatus(step, complete)),
    }
}

const mapStateToProps = store => {
    const [inputs] = inputSelector.getInputs(store);

    return {
        inputs,
        wizardState: wizardStore.getWizardState(store),
        attributes: wizardStore.getAttributes(store)
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(WizardStructureOld);