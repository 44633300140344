import styled from 'styled-components'
import AppBar from '@material-ui/core/AppBar'

const StyledHeaderAppBar = styled(AppBar)`
  && {
        z-index: 100;
        box-shadow: 0 0;
        position: fixed;
        background-color: ${props => props.theme.default.header.bgColor};
        border-left: 0px solid  ${props => props.theme.default.header.borderColor};
        border-bottom: 1px solid ${props => props.theme.default.header.borderColor};

        .MuiToolbar-regular {
            min-height: 64px;
        }

        .menuButton {
            margin-left: 10px;
            margin-right: 10px;
            color: ${props => props.theme.default.header.menuButton};
        }

        .groupButtons {
            position: absolute;
            top: 0;
            right: 0;
            display: flex;
            justify-content: space-between;
            align-items: center;
            min-height: 64px;

            svg { color: ${props => props.theme.default.header.iconButton.svgColor}; }
            
            button {
                padding: 0 20px;
                min-height: 64px;
                border-radius: 0;
                background-color: ${props => props.theme.default.header.button.bgColor};
                &:hover { background-color: ${props => props.theme.default.header.button.bgColorHover}; }
            }

            .btnScenario {
                display: flex;
                padding: 0 25px;
                min-height: 64px;
                align-items: center;
                border-left: 1px solid ${props => props.theme.default.header.button.borderColor};
                border-right: 1px solid ${props => props.theme.default.header.button.borderColor};
                background-color: ${props => props.theme.default.header.button.bgColor};
                cursor: pointer;
                
                svg {  font-size: 32px; margin-left: 15px; }
                &:hover { background-color: ${props => props.theme.default.header.button.bgColorHover}; }
                
                div {
                    display: flex;
                    flex-direction: column;

                    p {
                        display: inline-block;
                        text-align: left;
                        margin-left: 10px;
                        font-size: 0.725rem;
                        color: ${props => props.theme.default.header.button.textColor};
                        strong { color: ${props => props.theme.default.header.button.textColorStrong}; }
                    }
                }
            }
        }
    }
`

export default StyledHeaderAppBar