import React, {
    Component,
    Fragment
} from "react";
import { connect } from "react-redux";

import { Grid } from "@material-ui/core";

import Session from "../../utils/session-info";
import request from "../../utils/request";

import Translate from "../../i18n/translate";

import Toast from "../../components/toast/toast";
import ConectorSelect from "../../components/conector-ui/conector-select";
import ConectorInputText from "../../components/conector-ui/conector-input-text";
import InternalPageTitle from "../../components/conector-ui/conector-internal-page-title";
import StyledButton from "../../components/conector-ui/conector-styled-components/styled-button";
import StyledPaper from "../../components/conector-ui/conector-styled-components/styled-paper";

import { setBreadcrumb } from "../../store/app-state/actions";

class PermissionDetails extends Component {
    constructor(props) {
        super(props);

        let accessreleaseID = window.location.hash.substring(16)

        props.setBreadcrumb([{
            label: "registrations"
        }, {
            label: "permissions",
            url: "#/accessrelease"
        }, {
            label: (accessreleaseID !== "add" ? "edit_access" : "set_up_new_access")
        }]);

        this.state = {
            accessreleaseID: accessreleaseID,
            schedule: {},
            search: "",
            searchHelp: Translate("enter_email"),
            userFound: null,
            editPermission: false,
            isReady: (accessreleaseID === "add"),
            validations: [],
            condition: null,
            permission: {
                codUser: null,
                codProfile: null,
                codCompany: Session().codCompany,
                codInstance: Session().codInstance,
                isDefault: false
            }
        };
    }

    getPermission = id => {
        var params = id.split("&");
        request.executeRequest(
            request.baseUrl + "profilessecurity/get?codUser=" + params[0] + "&codProfile=" + params[1],
            request.getOptions()
        )
            .then(res => res.json())
            .then(data => {
                data.profile = data.codProfile
                this.setState({
                    ...this.state,
                    permission: data,
                    entityLoaded: true,
                    search: data.User.desName
                })
            })
    }

    getProfiles = _ => {
        request.executeRequest(
            request.baseUrl + "profilessecurity/allProfiles",
            request.getOptions()
        )
            .then(res => res.json())
            .then(data => {
                this.setState({
                    ...this.state,
                    profiles: data
                })
            })
    }

    componentWillMount() {
        let { accessreleaseID } = this.state
        this.getProfiles()
        var isEditing = false

        if (accessreleaseID !== "add") {
            this.getPermission(accessreleaseID)
            isEditing = true
        }

        this.setState({
            ...this.state,
            editPermission: isEditing
        })
    }

    cancel = _ => {
        window.location.replace('#/accessrelease')
    }

    save = _ => {
        let validations = []
        this.setState({ ...this.state, validations })
        let permission = { ...this.state.permission }

        if (!permission.codUser) {
            validations.push({
                field: Translate("user_email"),
                description: Translate("enter_user_email")
            })
        }

        if (!permission.codProfile) {
            validations.push({
                field: Translate("Profile"),
                description: Translate("choose_profile")
            })
        }

        if (validations.length) {

            this.setState({ ...this.state, validations })
            return

        } else {
            if (!this.state.editPermission) {
                request.executeRequest(
                    request.baseUrl + "profilessecurity/insertUserProfile",
                    { ...request.postOptions(), body: JSON.stringify(permission) }
                ).then(onSuccess.bind(this))
            } else {
                request.executeRequest(
                    request.baseUrl + "profilessecurity/editUserProfile",
                    { ...request.putOptions(), body: JSON.stringify(permission) }
                ).then(onSuccess.bind(this))
            }

            function onSuccess(res) {
                res.json().then(function (data) {
                    var msgFromDB = data != null ? data.msg : null
                    if (res.ok) {
                        Toast.success(msgFromDB != null ? msgFromDB : Translate("record_success_saved"))
                        window.location.replace('#/accessrelease')
                    } else {
                        Toast.error(msgFromDB != null ? msgFromDB : Translate("error_saving_record"))
                    }
                })
            }
        }
    }

    isReady = _ => {
        let {accessreleaseID} = this.state

        return accessreleaseID !== "add" ? !!this.state.entityLoaded : !!this.state.typesLoaded
    }

    handleChange = event => {
        let state = { ...this.state }

        switch (event.target.name) {
            case "user_email":
                state.search = event.target.value
            default:
                state.permission[event.target.name] = event.target.value
        }

        this.setState(state)
    }

    handleChangeSelect = (value) => {
        let state = { ...this.state }
        state.permission.codProfile = value.value
        this.setState(state)
    }

    makeOptionsList = list => {
        var options = list.map(r => {
            return {
                value: r.codProfile,
                label: r.description
            }
        })
        return options
    }

    search = () => {
        request.executeRequest(
            `${request.baseUrl}accessrelease/getUserWithoutProfileByEmail?&desEmail=${this.state.search}&codCompany=${this.state.permission.codCompany}&codInstance=${this.state.permission.codInstance}`,
            { ...request.getOptions() }
        ).then(onSuccess.bind(this))

        function onSuccess(res) {
            var self = this

            res.json().then(function (data) {
                var msgFromDB = data != null ? data.msg : null
                if (res.ok) {
                    if (msgFromDB != null) {
                        let state = { ...self.state }
                        state.condition = "warning"
                        state.userFound = null
                        state.searchHelp = msgFromDB
                        self.setState(state)
                    } else {
                        let state = { ...self.state }
                        state.userFound = data.desName
                        state.permission.codUser = data.codUser
                        state.condition = "success"
                        state.searchHelp = Translate("user_found")
                        self.setState(state)
                    }
                } else {
                    let state = { ...self.state }
                    if (msgFromDB != null) {
                        state.condition = "error"
                        state.searchHelp = msgFromDB
                    }
                    state.userFound = null
                    self.setState(state)
                }
            })
        }
    }

    render() {
        const { permission, profiles, validations, accessreleaseID } = this.state
        const options = !!profiles ? this.makeOptionsList(profiles) : []

        return (
            <Fragment>
                <InternalPageTitle title={accessreleaseID !== "add" ? Translate("edit_access") : Translate("set_up_new_access")} />

                <StyledPaper>
                    <Grid container spacing={24}>
                        <Grid item xs={12} md={6}>
                            <ConectorInputText
                                label={Translate("user_email")}
                                help={this.state.searchHelp}
                                name={"user_email"}
                                type={"text"}
                                value={this.state.search}
                                disabled={permission.codUser != null ? true : false}
                                onChange={this.handleChange}
                                condition={this.state.condition}
                                onKeyPress={this.search}
                                endIcon={"search"}
                                endTooltip={Translate("search")}
                                endActionButton={this.search}
                            />
                        </Grid>

                        <Grid item={true} xs={12} md={6}>
                            <ConectorSelect
                                label={Translate("profile")}
                                help={Translate("enter_type")}
                                placeholder={" "}
                                name={"codProfile"}
                                onChange={this.handleChangeSelect}
                                value={options.find(option => option.value === permission.codProfile)}
                                options={options}
                                className={validations.find(validation => validation.field === Translate("profile")) ? "error" : ""}
                                disabled={permission.codUser == null ? true : false}
                            />
                        </Grid>
                    </Grid>
                    <Grid container justify="flex-end">
                        <Grid item>
                            <StyledButton
                                variant="contained"
                                onClick={this.cancel}
                                color="primary">
                                {Translate("cancel")}
                            </StyledButton>
                            <StyledButton
                                className="no-margin"
                                variant="contained"
                                onClick={this.save}>
                                {Translate("save")}
                            </StyledButton>
                        </Grid>
                    </Grid>
                </StyledPaper>
            </Fragment>
        )
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        setBreadcrumb: (steps) => dispatch(setBreadcrumb(steps)),
    }
}

export default connect(null, mapDispatchToProps)(PermissionDetails)