import InterfacesService from '../../../routes/interfaces/interfaces-service'
import * as moment from 'moment';

const interfaceModesFilter = value => {
    const type = InterfacesService.InterfaceModes.find(mode =>
        mode.value === value
    );

    return type ? type.label : value;
}

export default (value, type) => {

    if (!value && type !== "boolean") {
        return;
    }

    switch (type) {
        case "boolean":
            return Boolean(value) ? "Sim" : "Não"
        case "ddMMyyyy-hh:mm":
            return moment(new Date(value)).format('DD/MM/YYYY HH:mm');
        case "capitalize":
            return value.charAt(0).toUpperCase() + (value.length > 1 ? value.slice(1) : '');
        case "interfaceLogType":
            if (value === 'INFO') {
                return "Informativas";
            } else {
                return value;
            }
        case "interfaceMode":
            return interfaceModesFilter(value);
        default:
            return value
    }
}