import React, {
    Component,
    Fragment
} from "react";

import { Grid } from "@material-ui/core";

import { StyledButtonLogin } from "../login-styles";

import Toast from "../../../components/toast/toast";
import ConectorInputText from "../../../components/conector-ui/conector-input-text";
import ConectorInputPassword from "../../../components/conector-ui/conector-input-password";

class ForgotYourPasswordForm extends Component {
    componentWillMount() {
        if (localStorage.hasOwnProperty("loggedOutByInvalidToken")) {
            Toast.info("userLoggedInAnotherSession", true)
            localStorage.removeItem("loggedOutByInvalidToken");
        }

        if (localStorage.hasOwnProperty("expiredAuth")) {
            setTimeout(Toast.info("expired_auth"), 2000);
            localStorage.removeItem("expiredAuth");
        }
    }

    onChange = ({target: {name, value}}) => {
        this.props.updateValues(name, value);
    }

    onKeyPress = ({key}) => {
        if (key === "Enter") {
            this.props.makeLogin();
        }
    }

    render() { 
        const {
            password,
            user,
            isButtonDisabled 
        } = this.props; 

        return ( 
            <Fragment>
                <Grid container className={"marginForm"}>
                    <Grid item xs={12}>
                        <ConectorInputText 
                            label={"Login"}
                            name={"user"}
                            startIcon={"person"}
                            value={user}
                            required={true}
                            onChange={this.onChange}
                            onKeyPress={this.onKeyPress}
                        />
                    </Grid>
                    <Grid item xs={12}>
                        <ConectorInputPassword 
                            label="password"
                            name="password"
                            required={true}
                            startIcon="lock"
                            endIcon={true}
                            value={password}
                            type={false}
                            onChange={this.onChange}
                            onKeyPress={this.onKeyPress}
                        />
                    </Grid>
                    <Grid item xs={12}>
                        <StyledButtonLogin fullWidth 
                            variant="contained"
                            onClick={this.props.makeLogin}
                            disabled={isButtonDisabled}
                        >Login
                        </StyledButtonLogin>
                    </Grid>
                </Grid>

                <Grid container>
                    <Grid item xs={12} sm={12} lg={12} md={12}>
                        <StyledButtonLogin  onClick={this.props.setForgotPassword} fullWidth 
                                variant="contained" className={"forgotPassword"}>
                            Esqueceu a sua senha?
                        </StyledButtonLogin>
                    </Grid>
                    
                </Grid>
            </Fragment>
        );
    }

}

export default ForgotYourPasswordForm;