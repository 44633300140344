import React, { Component, Fragment } from "react";
import { connect } from "react-redux";

import {
    AddCircle,
    Edit,
    Delete,
    Settings,
    FileCopy,
    Dvr,
    Pause,
    PlayArrow,
    FormatIndentIncrease
} from "@material-ui/icons";
import {
    CardContent,
    CardHeader,
    Avatar,
    Grid,
    Divider,
    Tooltip,
    Icon,
    Menu as MenuCore,
    MenuItem,
    ListItemIcon,
    DialogContent
} from "@material-ui/core";

import {
    StyledCardContainer,
    StyledCard,
    CardTitle,
    CardBody,
    StyledCardActions,
    IconContainer,
    StyledCardActionArea,
    StyledDialogZIndex
} from "./interfaces-styles";

import Toast from "../../components/toast/toast";
import Loading from "../../components/conector-ui/conector-loading";
import InterfacesCopyModal from "./interfaces-copy-modal/interfaces-copy-modal";
import HistoricInterfaceStatus from "./historic-interface-status-modal/historic-interface-status-modal";
import InterfacesRepositoryModal from "./interfaces-repository-modal/interfaces-repository-modal";
import InternalPageTitle from "../../components/conector-ui/conector-internal-page-title";
import StyledPaper from "../../components/conector-ui/conector-styled-components/styled-paper";
import ConectorDialogConfirmation from "../../components/conector-ui/conector-dialog-confirmation";
import StyledBottomDiv from "../../components/conector-ui/conector-styled-components/styled-bottom-div";
import {
    StyledDialogTitle
} from "../../components/conector-ui/conector-styled-components/styled-dialog";

import { setBreadcrumb } from "../../store/app-state/actions";
import * as appStateReducer from "../../store/app-state/reducer";

import Translate from "../../i18n/translate";

import moment from "moment";

import RepositoryService from "./interfaces-repository-modal/interface-repository-service";
import InterfacesService from "./interfaces-service";
import Session from "../../utils/session-info";
import ConectorSelect from "../../components/conector-ui/conector-select";
import ConectorInputText from '../../components/conector-ui/conector-input-text';

moment.locale("pt-br");

class Interfaces extends Component {

    constructor(props) {
        super(props);

        props.setBreadcrumb([{
            label: "integrations"
        }, {
            label: "interfaces"
        }]);

        this.state = {
            ...props,
            itf:[],
            interfaces: [],
            filteredInterfaces: [],
            loading: true,
            timerSelectedOption: 1,
            interfaceStatusSelectedOption: 99,
            openCopyInterfaceModal: false,
            openHistoricInterfaceStatusModal: false,
            openRepositoryModal: false,
            targetInterface: {},
            intervalTimer: null,
            timers: [{
                id: 1,
                label: `3 ${Translate("minutes")}`
            }, {
                id: 2,
                label: `5 ${Translate("minutes")}`
            }, {
                id: 3,
                label: `10 ${Translate("minutes")}`
            }, {
                id: 4,
                label: `15 ${Translate("minutes")}`
            }, {
                id: 5,
                label: `30 ${Translate("minutes")}`
            }],
            interfaceStateOptions: [
            {
                id: 99,
                label: Translate("all")
            },
            {
                id: 1,
                label: Translate("running")
            },
            {
                id: 2,
                label: Translate("running_with_warning")
            },
            {
                id: 3,
                label: Translate("stopped_critical_error")
            },
            {
                id: 4,
                label: Translate("stopped_manually")
            },
            {
                id: 5,
                label: Translate("awaiting_execution")
            }],            
            searchTextInput: "",
            InterfacesServiceRequestCompleted: false,
			allowedUser: false,
            editPermission:false
        };
    }

    async componentDidMount() {
        await this.updateButtonVisibility();
    }

	async updateButtonVisibility() {
        const res = await InterfacesService.GetUserPermissionInterface();
        const permission = res
        this.setState({
            allowedUser: permission,
            InterfacesServiceRequestCompleted: true,
        });

        const resEdit = await InterfacesService.GetUserPermissionEditInterface();
        const permissionEdit = resEdit
        this.setState({
            editPermission: permissionEdit,
        });
    }

    componentWillMount = _ => {
        const timerSelectedOption = this.state;
        this.getInterfaces();
        this.handleTimer(timerSelectedOption);
    }

    getInterfaces = _ => {
        const { searchTextInput, interfaceStatusSelectedOption } = this.state

        InterfacesService
            .GetAllInterfacesByInstance(Session().codInstance)
            .then(({ interfaces }) => {
                interfaces = interfaces.sort((interface1, interface2) => {
                    return interface1.codInstanceInterface - interface2.codInstanceInterface;
                });

                this.setState({
                    loading: false,
                    interfaces
                });

                this.filterInterfaces(searchTextInput, interfaceStatusSelectedOption)
            });
    }

    openCopyInterfaceModal(targetInterface) {
        this.setState({ openCopyInterfaceModal: true, targetInterface });
    }

    closeCopyInterfaceModal(success) {
        this.setState({
            openCopyInterfaceModal: false,
            targetInterface: {}
        }, this.getInterfaces);
    }

    closeHistoricInterfaceStatusModal() {
        this.setState({
            openHistoricInterfaceStatusModal: false,
            targetInterface: {}
        }, this.getInterfaces);
    }

    closeRepositoryModal(success) {
        this.setState({
            openRepositoryModal: false,
        }, this.getInterfaces);
    }

    openHistoricInterfaceStatusModal(targetInterface){
        this.setState({ openHistoricInterfaceStatusModal: true, targetInterface });
    }

    renderCards = _ => {
        const {
            filteredInterfaces,
            allowedUser,
            editPermission
        } = this.state;

        let cardMessages = [
            Translate("running"),
            Translate("running_with_warning"),
            Translate("stopped_critical_error"),
            Translate("stopped_manually"),
            Translate("awaiting_execution")
        ]

        let cardClassColors = [
            "running",
            "running-with-warning",
            "stopped-by-critical-error",
            "stopped-by-user",
            "awaiting-execution"
        ]

        const handleClick = (event, itf) => {
            this.setState({
                anchorEl: event.currentTarget,
                anchorPositionTop: event.clientY,
                anchorPositionLeft: event.clientX,
                itf: itf
            });
        };

        const handleClose = () => {
            this.setState({ anchorEl: null });
        };
        const cards = filteredInterfaces.map((itf, idx) => (
            <StyledCardContainer key={idx} item={true} xl={2} lg={4} md={6} sm={6}>
                <StyledCard className={cardClassColors[itf.codInterfaceStatus-1]}>
                    <div className={"content-status-message"}>{cardMessages[itf.codInterfaceStatus-1]}</div>
                    <CardContent>
                        <Tooltip placement="top" title={
                            <h2>{itf.description}</h2>
                        }>
                            <CardTitle id={"card-interface-title-" + idx} gutterBottom variant="h5" component="h2" style={{"overflow": "hidden", "textOverflow": "ellipsis"}}>
                                { itf.description }
                            </CardTitle>
                        </Tooltip>
                        <CardBody component="p">
                            <strong>{Translate("executions")}</strong>
                        </CardBody>
                        <Divider style={{ margin: "5px 0" }} />
                        <CardBody component="p">
                            <strong>{Translate("last")}: </strong>
                            {moment(itf.datLastExecution).format("DD/MM/YYYY - HH:mm")}
                        </CardBody>
                        <CardBody component="p">
                            <strong>{Translate("next")}: </strong>
                            {
                                (() => {
                                    if (itf.datLastExecution === itf.datNextExecution) {
                                        return "-";
                                    } else {
                                        return new Date(itf.datNextExecution).getFullYear()
                                            ? moment(itf.datNextExecution).format("DD/MM/YYYY - HH:mm")
                                            : Translate("not_parameterized")
                                    }
                                })()
                            }

                        </CardBody>
                        <CardBody component="p" style={{ marginTop: "5px" }}>
                            <strong>{Translate("filter")}</strong>
                        </CardBody>
                        <Divider style={{ margin: "5px 0" }} />
                        <CardBody component="p">
                            <strong>{Translate("beginning")}: </strong>
                            {moment(itf.datSync).format("DD/MM/YYYY - HH:mm")}
                        </CardBody>
                        <CardBody component="p">
                            <strong>{Translate("end")}: </strong>
                            {moment(itf.datNextSync).format("DD/MM/YYYY - HH:mm")}
                        </CardBody>
                        {
                            itf.codInterfaceStatus === 4 ? (
                                <Fragment>
                                    <CardBody component="p" style={{ marginTop: "5px" }}>
                                        <strong>{Translate("stopped_by")}</strong>
                                    </CardBody>
                                    <Divider style={{ margin: "5px 0" }} />
                                    <CardHeader avatar={
                                        <Avatar aria-label="Recipe">
                                            <img style={{"width": "100%"}} src={itf.userAvatar || "data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBzdGFuZGFsb25lPSJubyI/PjwhRE9DVFlQRSBzdmcgUFVCTElDICItLy9XM0MvL0RURCBTVkcgMS4xLy9FTiIgImh0dHA6Ly93d3cudzMub3JnL0dyYXBoaWNzL1NWRy8xLjEvRFREL3N2ZzExLmR0ZCI+PHN2ZyBjbGFzcz0iaWNvbiIgaGVpZ2h0PSI1MTIiIHZpZXdCb3g9IjAgMCAxMDI0IDEwMjQiIHZlcnNpb249IjEuMSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj48cGF0aCBkPSJNNTEyIDI0LjM4MDk1MkMyNDIuNTkwNDc2IDI0LjM4MDk1MiAyNC4zODA5NTIgMjQyLjU5MDQ3NiAyNC4zODA5NTIgNTEyczIxOC4yMDk1MjQgNDg3LjYxOTA0OCA0ODcuNjE5MDQ4IDQ4Ny42MTkwNDggNDg3LjYxOTA0OC0yMTguMjA5NTI0IDQ4Ny42MTkwNDgtNDg3LjYxOTA0OFM3ODEuNDA5NTI0IDI0LjM4MDk1MiA1MTIgMjQuMzgwOTUyeiBtLTcuMzE0Mjg2IDE3MC42NjY2NjdjOTAuMjA5NTI0IDAgMTY0LjU3MTQyOSA3MC43MDQ3NjIgMTY0LjU3MTQyOSAxNTguNDc2MTkxcy03My4xNDI4NTcgMTU4LjQ3NjE5LTE2NC41NzE0MjkgMTU4LjQ3NjE5QzQxNC40NzYxOSA1MTIgMzQxLjMzMzMzMyA0NDEuMjk1MjM4IDM0MS4zMzMzMzMgMzUzLjUyMzgxczczLjE0Mjg1Ny0xNTguNDc2MTkgMTYzLjM1MjM4MS0xNTguNDc2MTkxek03OTIuMzgwOTUyIDc4Mi42Mjg1NzFjMCA0NS4xMDQ3NjItOTUuMDg1NzE0IDQ2LjMyMzgxLTIxMi4xMTQyODUgNDYuMzIzODFINDQzLjczMzMzM0MzMjYuNzA0NzYyIDgyOC45NTIzODEgMjMxLjYxOTA0OCA4MjguOTUyMzgxIDIzMS42MTkwNDggNzgyLjYyODU3MXYtMTMuNDA5NTIzYzAtMTEzLjM3MTQyOSA5NS4wODU3MTQtMjA0LjggMjEyLjExNDI4NS0yMDQuOGgxMzYuNTMzMzM0YzExNy4wMjg1NzEgMCAyMTIuMTE0Mjg2IDkxLjQyODU3MSAyMTIuMTE0Mjg1IDIwNC44djEzLjQwOTUyM3oiIGZpbGw9IiM2NjY2NjYiIC8+PC9zdmc+"} />
                                        </Avatar>
                                    } title={itf.userName} style={{"padding-bottom": "0"}} />
                                </Fragment>
                            ) : null
                        }
                    </CardContent>
                    <StyledCardActions>
                    <Fragment>
                    <MenuCore
                        anchorEl={this.state.anchorEl}
                        open={Boolean(this.state.anchorEl)}
                        onClose={handleClose}
                        elevation={0}
                        getContentAnchorEl={null}
                        autoFocus={false}
                        disableAutoFocusItem={true}
                        PaperProps={{
                            style: {
                                border: '2px solid #ccc',
                            },
                        }}
                        anchorOrigin={{
                            vertical: 'top', 
                            horizontal: 'left', 
                        }}
                        transformOrigin={{
                            vertical: 'top',
                            horizontal: 'left', 
                        }}
                        anchorReference="anchorPosition"
                        anchorPosition={
                            this.state.anchorEl
                                ? { top: this.state.anchorPositionTop, left: this.state.anchorPositionLeft }
                                : undefined
                        }
                    >
                        {
                            allowedUser ? (
                                <Fragment>
                                    <MenuItem onClick={_ => { this.openCopyInterfaceModal(this.state.itf); handleClose(); }}>
                                        <ListItemIcon>
                                            <FileCopy />
                                        </ListItemIcon>
                                        <h6> {Translate("copy")}</h6>
                                    </MenuItem>
                                    <Divider />
                                    <MenuItem onClick={_ => { this.deleteInterface(this.state.itf); handleClose(); }}>
                                        <ListItemIcon>
                                            <Delete />
                                        </ListItemIcon>
                                        <h6> {Translate("remove")}</h6>
                                    </MenuItem>
                                    <Divider />
                                    <Divider />
                                    {/* <MenuItem onClick={_ => { this.suggestInterface(itf) }}>
                                        <ListItemIcon>
                                        <Icon>emoji_objects</Icon> 
                                        </ListItemIcon>
                                        <h6>{Translate("suggest_template")}</h6>
                                    </MenuItem> */}
                                </Fragment>
                            ) : null
                        }
                        <MenuItem  onClick={_ => { this.openHistoricInterfaceStatusModal(this.state.itf); handleClose(); }}>
                            <ListItemIcon>
                                <FormatIndentIncrease/>
                            </ListItemIcon>
                                <h6>{Translate("historicInterfaceStatus")}</h6>
                        </MenuItem>
                    </MenuCore>
                    </Fragment>                   
                            <Tooltip placement="top" title={<h3>{Translate("more_options")}</h3>}>
                                <IconContainer onClick={(event) => handleClick(event, itf)}>
                                    <Icon>more_horiz</Icon>
                                </IconContainer>
                            </Tooltip>

                        <Tooltip  placement="top" title={
                            <h3>{Translate(itf.isActive ? "stop" : Session().instanceBlock? "block" : "start")}</h3>
                        }>
                            <IconContainer  onClick={_ => {

                                if(!Session().instanceBlock){
                                itf.isActive = !itf.isActive;
                                this.activateInterface(itf.codInstanceInterface, itf.isActive)
                                }
                            }}>
                                {itf.isActive ? <Pause className="pause-button" /> : <PlayArrow className="play-button"/>}
                            </IconContainer>
                        </Tooltip>
                        <Tooltip placement="top" title={
                            <h3>{Translate("logs")}</h3>
                        }>
                            <IconContainer onClick={_ => { this.viewLogs(itf.codInstanceInterface) }}>
                                <Dvr />
                            </IconContainer>
                        </Tooltip>
                        {
                            allowedUser ? (
                                <Fragment>
                                    <Tooltip placement="top" title={
                                        <h3>{Translate("edit")}</h3>
                                    }>
                                        <IconContainer onClick={_ => { this.editInterface(itf.codInstanceInterface) }}>
                                            <Edit />
                                        </IconContainer>
                                    </Tooltip>                                                                
                                    <Tooltip placement="top" title={
                                        <h3>{Translate("define_events")}</h3>
                                    }>
                                        <IconContainer onClick={_ => { this.editInterfaceEvents(itf.codInstanceInterface) }}>
                                            <Settings />
                                        </IconContainer>
                                    </Tooltip>
                                </Fragment>
                            ) : editPermission ?
                            <Fragment>
                                    <Tooltip placement="top" title={
                                        <h3>{Translate("edit")}</h3>
                                    }>
                                        <IconContainer onClick={_ => { this.editInterface(itf.codInstanceInterface) }}>
                                            <Edit />
                                        </IconContainer>
                                    </Tooltip> 
                            </Fragment>
                             : null
                        }
                    </StyledCardActions>
                </StyledCard>
            </StyledCardContainer>
        ))

        cards.push(
            <StyledCardContainer key={"new-interface"} item={true} xl={2} lg={4} md={6} sm={6}>
                <StyledCard className={"new-interface"}>
                    <StyledCardActionArea onClick={this.addInterface}>
                        <CardTitle className={"new-interface"} gutterBottom variant="h5" component="h2">
                            <AddCircle /><br />
                            {Translate("add_new_interface")}
                        </CardTitle>
                    </StyledCardActionArea>
                </StyledCard>
            </StyledCardContainer>
        );

        return cards;
    }

    addInterface = () => {
        window.location.replace("#/interfaces/interface/add")
    }

    viewLogs = id => {
        window.location.replace(`#/interfaces/logs/${id}`)
    }

    editInterface = id => {
        window.location.replace(`#/interfaces/interface/${id}`)
    }

    editInterfaceEvents = id => {
        window.location.replace(`#/interfaces/events/${id}`)
    }

    activateInterface = (interfaceId, active) => {
        const { codInstance } = Session();
        InterfacesService
            .ActivateInterface(interfaceId, codInstance, active)
            .then(this.getInterfaces);
    }

    deleteInterface = targetInterface => {
        this.setState({
            confirmationModalOptions: {
                type: "danger",
                title: "interface_delete_confirm",
                message: "delete_select_interface",
                open: true,
                onDismiss: this.onDismissModal,
                onClose: this.onConfirmDeleteInterface.bind(this, InterfacesService),
            },
            targetInterface
        });
    }

    onConfirmDeleteInterface = (InterfacesService) => {
        const { codInstance } = Session(), { targetInterface } = this.state;
        InterfacesService
            .DeleteInterface(targetInterface.codInstanceInterface, codInstance)
            .then(({ status, ok, errors }) => {
                if(status == 226){
                    Toast.info(Translate("interface_cant_deleted"))
                }
                else if (ok) {
                    Toast.success(Translate("Interface_success_deleted"));
                    this.getInterfaces();
                }
                else {
                    Toast.error(errors)
                }
                this.setState({
                    confirmationModalOptions: { open: false },
                    targetInterface: {}
                });
            });
    }

    suggestInterface = targetInterface => {
        this.setState({
            confirmationModalOptions: {
                type: "info",
                title: "interface_suggest",
                message: "suggest_interface",
                open: true,
                onDismiss: this.onDismissModal,
                onClose: this.onConfirmSuggedtInterface.bind(this, RepositoryService),
            },
            targetInterface
        });
    }

    onDismissModal = () => {
        this.setState({
            confirmationModalOptions: {
                open: false
            }
        });
    }

    onConfirmSuggedtInterface = (RepositoryService) => {
        const { targetInterface } = this.state;
        var codInterface = targetInterface.codInstanceInterface;
        var name = targetInterface.description;
        RepositoryService.Exists(codInterface).then((response) => {
            if (response.suggested) {
                if (response.cause === "published") {
                    Toast.info("template_already_suggested");
                } else {
                    Toast.info("suggestion_under_review");
                }
            } else {
                RepositoryService.SuggestTemplate({
                    codInterface,
                    name,
                    suggestedBy: Session().codUser
                }).then(() => {
                    Toast.success("template_analysis");
                });
            }
            this.setState({
                confirmationModalOptions: { open: false },
                targetInterface: {}
            });
        });
    }

    handleTimer = (timerOptionValues) => {
        let { timerSelectedOption } = this.state
        var milliseconds
       
        if (timerOptionValues.id)
            timerSelectedOption = timerOptionValues.id

        switch (timerSelectedOption) {
            case 1:
                milliseconds = 180000 // 3 minutes
                break;
            case 2:
                milliseconds = 300000 // 5 minutes
                break;
            case 3:
                milliseconds = 600000 // 10 minutes
                break;
            case 4:
                milliseconds = 900000 // 15 minutes
                break;
            case 5:
                milliseconds = 1800000 // 30 minutes
                break;
            default:
                break;
        }

        this.setState({ timerSelectedOption })
        this.updateInterfaces(milliseconds)
    }

    updateInterfaces(milliseconds){
        clearInterval(this.state.intervalTimer)
 
        this.state.intervalTimer = setInterval(() => {
            if(window.location.href.endsWith('interfaces')){
                const { codInstance } = Session();
                InterfacesService.GetAllInterfacesByInstance(codInstance)
                .then(this.getInterfaces);
            }
            else
                clearInterval(this.state.intervalTimer)
        }, milliseconds)
    }

    filterInterfaces = (searchInput, codState) => {
        const { interfaces } = this.state

        if(codState != 99)
            var filteredInterfacesList = interfaces.filter(interfaceElement => (
                interfaceElement.description.toLowerCase().includes(searchInput.toLowerCase()) &&
                interfaceElement.codInterfaceStatus == codState
            ))
        else
            var filteredInterfacesList = interfaces.filter(interfaceElement => (
                interfaceElement.description.toLowerCase().includes(searchInput.toLowerCase())
            ))

        this.setState({ searchTextInput: searchInput,
                        interfaceStatusSelectedOption: codState,
                        filteredInterfaces: filteredInterfacesList, })
    }

    filterInterfacesByState = stateOption => {
        const { searchTextInput } = this.state

        if(stateOption.id){
            this.filterInterfaces(searchTextInput, stateOption.id)
        }        
    }

    render() {
        const {
            loading,
            targetInterface,
            openRepositoryModal,
            openCopyInterfaceModal,
            openHistoricInterfaceStatusModal,
            confirmationModalOptions,
            timers,
            timerSelectedOption,
            interfaceStateOptions,
            interfaceStatusSelectedOption,
            allowedUser
        } = this.state;

        return (
            <Fragment>
                <InternalPageTitle title={Translate("interfaces")} />
                
                <StyledPaper>
                    <Grid container={true} spacing={24}>
                        <Grid item xs={4}>
                            <ConectorInputText
                                label="searchInterfaces"
                                placeholder="toTypeSearchInterface"
                                help="interfaceDescriptionFilter"
                                onChange={(data) => {
                                    this.filterInterfaces(data.target.value, interfaceStatusSelectedOption)
                                }}
                            />
                        </Grid>

                        <Grid item xs={4}>   
                            <ConectorSelect
                                label="interfaceState"
                                help="interfaceStateFilter"
                                name="interfaceState"
                                onChange={this.filterInterfacesByState}
                                options={interfaceStateOptions}
                                value={interfaceStateOptions.find(e => e.id === interfaceStatusSelectedOption) || null}
                            />
                        </Grid>

                        <Grid item xs={3}>                
                            <ConectorSelect
                                label="timer"
                                help="select_period"
                                name="timers"
                                onChange={this.handleTimer}
                                options={timers}
                                value={timers.find(e => e.id === timerSelectedOption) || null}
                            />
                        </Grid>
                    </Grid>

                    <Grid container={true} spacing={24}>
                        {
                            loading ? (<Loading />) : this.renderCards()
                        }
                    </Grid>
                </StyledPaper>                    
                {
                    (() => {
                        if(openHistoricInterfaceStatusModal){
                            return (
                                <StyledDialogZIndex fullWidth={true} maxWidth={"sm"} open={openHistoricInterfaceStatusModal}>
                                    <StyledDialogTitle id="confirmation-dialog-title" className="confirmation">
                                        {Translate("historicInterfaceStatus")} - {targetInterface.description}
                                    </StyledDialogTitle>
                                    <HistoricInterfaceStatus
                                        open={openHistoricInterfaceStatusModal}
                                        targetInterface={targetInterface}
                                        close={this.closeHistoricInterfaceStatusModal.bind(this)}
                                    />
                                </StyledDialogZIndex>
                            )
                        }
                        if (openCopyInterfaceModal) {
                            return (
                                <StyledDialogZIndex fullWidth={true} maxWidth={"sm"} open={openCopyInterfaceModal}>
                                    <StyledDialogTitle id="confirmation-dialog-title" className="confirmation">
                                        {Translate("interface_copy")} - {targetInterface.description}
                                    </StyledDialogTitle>
                                    <InterfacesCopyModal
                                        open={openCopyInterfaceModal}
                                        targetInterface={targetInterface}
                                        close={this.closeCopyInterfaceModal.bind(this)}
                                    />
                                </StyledDialogZIndex>
                            );
                        } else if (openRepositoryModal) {
                            return (
                                <InterfacesRepositoryModal
                                    open={openRepositoryModal}
                                    close={this.closeRepositoryModal.bind(this)}
                                />
                            );
                        }
                    })()
                }

                <ConectorDialogConfirmation dialogOptions={confirmationModalOptions}/>
            </Fragment>
        );
    }
}

const mapStateToProps = store => {
	return {
		permission: appStateReducer.getUserPermissionByView(store)
	}
}

const mapDispatchToProps = dispatch => {
    return {
        setBreadcrumb: (steps) => dispatch(setBreadcrumb(steps))
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(Interfaces);