import React, { Component } from "react";

import {
    DialogActions,
    DialogContent,
    DialogContentText
} from "@material-ui/core/";

import {
    StyledDialog,
    StyledDialogTitle
} from "./conector-styled-components/styled-dialog";
import StyledButton from "./conector-styled-components/styled-button";
import Loading from "../../components/conector-ui/conector-loading-small";

import Translate from "../../i18n/translate";

class DialogComponent extends Component {

    constructor(props) {
        super(props);

        this.state = {
            ...props,
            open: false
        }
    }

    componentWillReceiveProps(props) {
        this.setState({ ...props.dialogOptions });
    }

    handleClose = _ => {
        if (this.state.onClose) {
            this.state.onClose()
        }
    }

    handleDismiss = _ => {
        if (this.state.onDismiss) {
            this.state.onDismiss()
        }

        this.setState({ open: false })
    }

    render() {
        const props = { ...this.state }

        return (
            <StyledDialog
                open={props.open}
                aria-labelledby="confirmation-dialog-title"
                aria-describedby="confirmation-dialog-description"
            >
                <StyledDialogTitle className={(props.type)}
                    id="confirmation-dialog-title">
                    {Translate(props.title)}
                </StyledDialogTitle>
                {
                props.loading ? (
                    <Loading />
                ) : (
                    <DialogContent>
                    <DialogContentText id="confirmation-dialog-description">
                        {Translate(props.message)}
                    </DialogContentText>
                    </DialogContent>
                )
                }
                <DialogActions>
                    <StyledButton
                        variant="contained"
                        onClick={this.handleDismiss.bind(this)}
                        autoFocus
                        color="primary">
                        {Translate("cancel")}
                    </StyledButton>
                    <StyledButton
                        variant="contained"
                        onClick={this.handleClose.bind(this)}>
                        {Translate("confirm")}
                    </StyledButton>
                </DialogActions>
            </StyledDialog>
        )
    }
}

export default DialogComponent