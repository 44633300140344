import React, { Component } from "react";
import { connect } from "react-redux";

import Toast from "../../../../components/toast/toast";
import ConectorDialogConfirmation from "../../../../components/conector-ui/conector-dialog-confirmation";

import { addEvent } from "../../../../store/instances/actions";
import { setInterfaceEvents } from "../../../../store/interfaces/actions";
import { setWizardState } from "../../../../store/wizard/wizard-store-actions";
import { getAllEventsByInterface } from "../../../../store/interfaces/actions";
import * as interfaceSelector from "../../../../store/interfaces/reducer";

import Session from "../../../../utils/session-info";
import UuidUtils from "../../../../utils/uuid-utils";

import Translate from "../../../../i18n/translate";

import InterfacesEventsService from "../interfaces-events-service";

import ConnectionActionModal from "../connection-action-modal/connection-action-modal";

import EventCard from "./event-card";
import { DropZoneDiv } from "./events-timeline-styles";

class EventsTimeline extends Component {

	constructor(props) {
		super(props);

		this.state = {
			eventList: props.eventList,
			selectedInterfaceCode: props.selectedInterfaceCode,
			banksList: props.banksList,
			bankAccounts: props.bankAccounts
		};
	}

	componentWillReceiveProps = ({ eventList }) => this.setState({ eventList })

	onDropNewCard = (event) => {
		event.preventDefault();
		const eventInfo = JSON.parse(event.dataTransfer.getData("eventInfo")),
			{ eventList } = this.state;

		let eventTypes = [];

		eventList.forEach(event => {
			if (event.children) {
				eventTypes = [...eventTypes, ...event.children];
			} else {
				eventTypes.push(event);
			}
		});

		const {
			codConnectionType,
			codEvent,
			label
		} = eventTypes.find(e => {
			if (eventInfo.codConnectionType) {
				return e.codConnectionType === eventInfo.codConnectionType;
			} else {
				return e.codEvent === eventInfo.codEvent;
			}
		});

		//rever esse negócio de adicionar uma estrutura que no init wizard ele remove. código redundante
		let newEvent = {
			codEvent,
			indPosition: this.props.count,
			uuid: UuidUtils.generateUuid(),
			desEventName: Translate("new_event"),
			isCritical: true,
			structures: [{
				codConnectionMode: eventInfo.codConnectionMode,
				label,
				codConnectionType
			}]
		};

		if (eventInfo.codBank != undefined) newEvent.codBank = eventInfo.codBank

		this.props.addEvent(newEvent);
		this.editEvent(newEvent);
	}

	editEvent = selectedEvent => {
		const { codInstance } = Session(),
		{ selectedInterfaceCode } = this.state,
		{ codConnectionType, codConnectionAction, label } = selectedEvent.structures[0];

		const event = {
			...selectedEvent,
			codConnectionAction,
			codConnectionType,
			codInstance,
			eventType: label,
			codInstanceInterface: selectedInterfaceCode,
			deletedExtras: [],
			deletedDetails: [],
			deletedFields: [],
			deletedFieldTransforms: [],
			deletedFieldTransformsCase:[],
			deleteStructure: [],
			changedDetails: {},
			changedFields: {}
		};

		if (!event.codConnectionAction) this.openConnectionActionModal(event)
		else this.initWizard(event);
	};

	copyEvent = (event) => {
		this.setState({
			confirmationModalOptions: {
				type: "info",
				message: "copy_records",
				title: "copy_confirmation",
				open: true,
				onDismiss: this.dismissDeleteModal.bind(this),
				onClose: this.onCopyConfirmation.bind(this)
			},event
		});
	}
	onCopyConfirmation() {
		const { event } = this.state;
		InterfacesEventsService.Copy(event.codInterfaceEvent, `${event.desEventName} - ${Translate("copy_item")}`).then(_ => {
			this.props.getAllEventsByInterface(event.codInstanceInterface);
			Toast.success(Translate("operation_perform_success"));
		});

		this.setState({
			confirmationModalOptions: {
				open: false
			}
		});
	}

	openConnectionActionModal(eventModal) {
		this.setState({
			openConnectionActionModal: true,
			eventModal
		});
	}

	onCloseConnectionActionModal(codConnectionAction) {
		var { eventModal } = this.state;
		eventModal.codConnectionAction = codConnectionAction;
		this.initWizard(eventModal);
	}

	dismissConectionActionModal() {
		let { events } = this.props;
		events.pop();

		this.props.setInterfaceEvents(events);
		this.setState({ openConnectionActionModal: false });
	}

	openDeleteModal(eventModal) {
		this.setState({
			confirmationModalOptions: {
				type: "danger",
				message: "remove_records",
				title: "exclusion_confirmation",
				open: true,
				onDismiss: this.dismissDeleteModal.bind(this),
				onClose: this.onDeleteConfirmation.bind(this)
			},
			eventModal
		});
	}

	onDeleteConfirmation() {
		const { eventModal } = this.state;
		let events = [...this.props.events];
		let eventsOrder = {};

		InterfacesEventsService.DeleteEvent(eventModal.codInterfaceEvent).then(() => {
			this.props.getAllEventsByInterface(eventModal.codInstanceInterface);

			Toast.success(Translate("event_deleted_success"));
		});

		for (let e = eventModal.indPosition; e < events.length; e++) {
			if (eventModal.codInterfaceEvent === events[e].codInterfaceEvent) {
				continue;
			}

			events[e].indPosition--;
			eventsOrder[events[e].codInterfaceEvent] = events[e].indPosition;
		}

		events.splice(events.indexOf(eventModal), 1);

		this.props.setInterfaceEvents(events);

		InterfacesEventsService.UpdateEventsOrder(eventsOrder);

		this.setState({
			confirmationModalOptions: {
				...this.state.confirmationModalOptions,
				open: false
			}
		});
	}

	dismissDeleteModal() {
		this.setState({ confirmationModalOptions: { open: false } });
	}

	initWizard(event) {
		const dummyStructureIdx = event.structures.findIndex(
			struc => typeof struc.codStructure !== "number"
		);

		if (dummyStructureIdx !== -1) {
			event.structures.splice(dummyStructureIdx, 1);
		}

		event.structures.forEach(struc => {
			if (!struc.structureExtras) {
				struc.structureExtras = [];
			}
		});

		this.props.setWizardState({
			event,
			isEdit: event.codInterfaceEvent > 0
		});

		setTimeout(() => {
			window.location.replace('#/wizard/')
		});
	}

	render() {
		const {
			openConnectionActionModal,
			confirmationModalOptions,
			eventModal,
			banksList,
			bankAccounts
		} = this.state;

		return (
			<div style={{"display": "block", "height": "100%"}}>
				<DropZoneDiv
					onDragOver={e => {
						e.preventDefault();
					}}
					onDrop={this.onDropNewCard.bind(this)}
				>
					<EventCard
						onClickEdit={this.editEvent.bind(this)}
						onClickCopy={this.copyEvent.bind(this)}
						onClickDelete={this.openDeleteModal.bind(this)}
						banksList={banksList}
						bankAccounts={bankAccounts}
					/>
				</DropZoneDiv>

				<ConectorDialogConfirmation
					dialogOptions={confirmationModalOptions}
				/>

				<ConnectionActionModal
					open={openConnectionActionModal}
					event={eventModal}
					onDismiss={this.dismissConectionActionModal.bind(this)}
					onClose={this.onCloseConnectionActionModal.bind(this)}
				/>
			</div>
		);
	}
}

const mapStateToProps = state => {
	const [events] = interfaceSelector.getEvents(state);
	var count = Object.keys(events).length + 1;

	return {
		events,
		count
	};
};

const mapDispatchToProps = dispatch => {
	return {
		getAllEventsByInterface: codInterface => { dispatch(getAllEventsByInterface(codInterface)); },
		setWizardState: wizardState => dispatch(setWizardState(wizardState)),
		addEvent: event => { dispatch(addEvent(event)) },
		setInterfaceEvents: interfaceEvents => { dispatch(setInterfaceEvents(interfaceEvents)) }
	};
};

EventsTimeline.defaultProps = {
	openConnectionActionModal: false,
	openDeleteEventModal: false
};

EventsTimeline = connect(mapStateToProps, mapDispatchToProps)(EventsTimeline);

export default EventsTimeline;