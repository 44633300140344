import React, {
    Component,
    Fragment
} from "react";
import { connect } from "react-redux";

import styled from "styled-components";

import * as wizardStore from "../../../../store/wizard/wizard-store-reducer";

import StyledButton from "../../../../components/conector-ui/conector-styled-components/styled-button";
import { StyledDialog, StyledDialogTitle } from "../../../../components/conector-ui/conector-styled-components/styled-dialog";

import {
    Card,
    CardContent,
    CardActionArea,
    DialogActions,
    DialogContent,
    Grid
} from "@material-ui/core/";

import Translate from "../../../../i18n/translate";

const StyledCardContent = styled(CardContent)`
    && {
        display: flex;
        justify-content: center;
        background-color: #F4F4F4;
        align-items: center;
        color: #827272;
        height: 80px;
        word-wrap: break-word;
        font-size: 20px;
        font-weight: 600;
    }
`

const StyledCard = styled(Card)`
    && {
        animation: fadein 0.8s;
        @keyframes fadein {
            from { opacity: 0; }
            to   { opacity: 1; }
        }
        color: #827272;
        line-height: 300px;
        word-wrap: break-word;
        border: 2px solid black;
        text-align: center;
    }
`

class ConnectionActionsModal extends Component {
    constructor(props) {
        super(props);
        this.state = { ...props }
    }

    componentWillReceiveProps = props => {
        this.setState({ ...props });
    }

    renderCards(connectionActions) {
        return connectionActions.map((e, idx) =>
            <Grid key={idx} item={true} xl={3} lg={4} md={6} sm={12} xs={12}>
                <StyledCard>
                    <CardActionArea>
                        <StyledCardContent onClick={_ => { this.props.onClose(e.id) }}>
                            <span>{e.description}</span>
                        </StyledCardContent>
                    </CardActionArea>
                </StyledCard>
            </Grid>
        )
    }

    render() {
        var { open, connectionActions, event } = this.state;

        if (!open) {
            return <Fragment></Fragment>;
        }

        connectionActions = [...connectionActions].filter(connAction =>
            connAction.codConnectionType === event.codConnectionType
        );

        if (connectionActions.length === 1) {
            this.props.onClose(connectionActions[0].id);
            return <Fragment></Fragment>;
        }

        return (
            <StyledDialog maxWidth="md"
                fullWidth={true}
                open={open}
            >
                <StyledDialogTitle className="confirmation">
                    {Translate("set_event_type")}
                </StyledDialogTitle>
                <DialogContent style={{
                    borderBottom: "1px solid #e4e1e1"
                }}>
                    <Grid style={{ justifyContent: "center" }}
                        container={true}
                        spacing={24}>
                        {this.renderCards(connectionActions)}
                    </Grid>
                </DialogContent>
                <DialogActions>
                    <StyledButton
                        variant="contained"
                        onClick={this.props.onDismiss}>
                        {Translate("cancel")}
                    </StyledButton>
                </DialogActions>
            </StyledDialog>
        );
    }
}

const mapStateToProps = store => {
    return {
        connectionActions: wizardStore.getConnectionActions(store)
    };
};

ConnectionActionsModal = connect(mapStateToProps)(ConnectionActionsModal);

export default ConnectionActionsModal;