import React, {
    Component,
    Fragment
} from "react";
import { connect } from "react-redux";
import Loading from "../../components/conector-ui/conector-loading";
import ConectorGrid from "../../components/conector-ui/conector-grid/conector-grid";
import { setBreadcrumb } from "../../store/app-state/actions";
import * as appStateReducer from "../../store/app-state/reducer";
import AttemptsService from "./attempts-old-service";
import Translate from "../../i18n/translate";
import { CSVLink } from 'react-csv';
import StyledPaper from "../../components/conector-ui/conector-styled-components/styled-paper";
import StyledButton from "../../components/conector-ui/conector-styled-components/styled-button";
import InternalPageTitle from "../../components/conector-ui/conector-internal-page-title";

class AttemptsOLD extends Component {
	constructor(props) {
		super(props);

		props.setBreadcrumb([{
            label: "administration"
        }, {
            label: "attempts"
        }]);

		this.state = {
			loading: true,
			gridOptions: {
				multiSelect: false,
				hideBtnNew: true,
				hideBtnEdit: true,
				hideBtnDelete: true,
				hideBtnCopy: true,
				columns: [{
                    title: "name",
                    field: "user",
                }, {
                    title: "status",
                    field: "status",
					hideFilter: true
                }, {
                    title: "dataAttempt",
                    field: "DatAttempt",
                    cellFilter: "ddMMyyyy-hh:mm"
                }]
			}
		}
	}

    componentWillMount() {
		AttemptsService
		.GetAllByAttempts()
		.then((dataSource) => {
			if(dataSource != null){
				dataSource.map(item => {
					delete item.codUser
					if(item.status == false){
						item.status = Translate("FALSE")
					} else if(item.status == true){
						item.status = Translate("TRUE")
					}
				})
				this.setState({
					dataSource: dataSource,
					loading: false
				});
			}
        });
	}

	render() {
		const {
			loading,
			gridOptions,
            dataSource,
		} = this.state;

		if (loading) { return <Loading /> }

		return (
			<Fragment>
				<InternalPageTitle title={Translate("attempts")} />
                <StyledPaper>
					<CSVLink data={dataSource} filename={"attempts.csv"} style={{ textDecoration: 'none' }}>
						<StyledButton variant="contained">{Translate("downloadReport")}</StyledButton>
					</CSVLink>
					<ConectorGrid gridOptions={gridOptions} dataSource={dataSource} />
                </StyledPaper>
			</Fragment>
		)
    }
}

const mapStateToProps = store => {
	return {
		permission: appStateReducer.getUserPermissionByView(store)
	}
}

const mapDispatchToProps = (dispatch) => {
	return {
		setBreadcrumb: (steps) => dispatch(setBreadcrumb(steps))
	}
}

export default connect(mapStateToProps, mapDispatchToProps)(AttemptsOLD);