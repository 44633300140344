import styled from 'styled-components'
import { Button } from '@material-ui/core'

export const bankImgLogo = {
    width: "50px", 
    height: "50px", 
    borderRadius: "20%"
}

export const bankAccountTitles = { color: "#6F8296" }

export const addButton = {
    width: "181px", 
    marginTop: "5px"
}

export const labelButton = { cursor: "pointer" }

export const IconButtonContainerStyle = { float: 'right' }

export const IconButtonContainer = styled(Button)`
    && {
        width: auto;
        color: rgba(0,0,0,0.54); 
        text-transform: none;
    }
`

export const BanksCards = styled.div`
    background-color: #f4f4f4;
    margin-top: 5px;
    padding: 10px 20px 10px;
    border-radius: 5px;
    border: 1px solid #b8c6d6;
    cursor: pointer;
    &:hover { background-color: #eee; }
`

export const AccountsCards = styled.div`
    background-color: #f4f4f4;
    margin-top: 5px;
    padding: 5px 20px 5px;
    border-radius: 5px;
    border: 1px solid #b8c6d6;
    &:hover { background-color: #eee; }
`