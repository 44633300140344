import styled from 'styled-components'
import Stepper from '@material-ui/core/Stepper'

const StyledStepper = styled(Stepper)`
  && {

    svg.MuiSvgIcon-root {
      font-size: 28px;
      color: ${props => props.theme.default.content.stepper.svg.color};
      transition: color 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
      
      text {
        fill: ${props => props.theme.default.content.stepper.svg.textColor};
        transition: fill 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
      }

      &.MuiStepIcon-active {
        color: ${props => props.theme.default.content.stepper.svg.activeColor};
        font-size: 40px;
        margin-top: -5px;
        z-index: 999;
      }
    }

    .MuiStepConnector-root {
      top: 14px;
      left: calc(-50% + 42px);
      right: calc(50% + 42px);
      z-index: 99;
      height: 2px;
      background-color: ${props => props.theme.default.content.stepper.stepConnector.bgColor};
      transition: left 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms, right 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;

      span { 
        border-color: ${props => props.theme.default.content.stepper.stepConnector.borderColor};
        background-color: ${props => props.theme.default.content.stepper.stepConnector.bgColor};
        transition: border-color 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
      }

      &.MuiStepConnector-active {
        left: calc(-50% + 10px);
        right: calc(50% + 10px);
        background-color: ${props => props.theme.default.content.stepper.stepConnector.activeBgColor};

        span {
          border-color: ${props => props.theme.default.content.stepper.stepConnector.activeBorderColor};
          background-color: ${props => props.theme.default.content.stepper.stepConnector.activeBgColor};
        }
      }
    }

    .MuiStepLabel-labelContainer {
      span {
        color: ${props => props.theme.default.content.stepper.stepLabel.textColor};
        border-radius: 4px;
        padding-top: 5px;
        padding-bottom: 5px;
        margin-top: 10px;
      }

      span.MuiStepLabel-active {
        color: ${props => props.theme.default.content.stepper.stepLabel.activeTextColor};
        font-weight: bold;
        margin-top: 9px;
        font-size: 1rem;
        line-height: 1.2;
      }
    }

    button:hover {
      svg { color: ${props => props.theme.default.content.stepper.button.svgColor}; }
      .MuiStepLabel-labelContainer { 
        span { color: ${props => props.theme.default.content.stepper.button.svgColor}; }
        span.MuiStepLabel-active {
          color: ${props => props.theme.default.content.stepper.button.activeSvgColor};
        } 
      }
    }

    .completed {
      button, button:hover {
        svg { color: ${props => props.theme.default.content.stepper.button.completedSvgColor}; }
        .MuiStepLabel-labelContainer { 
          span { color: ${props => props.theme.default.content.stepper.stepLabel.completedTextColor}; }
        }
      }
      
      .MuiStepConnector-root {
        left: calc(-50% + 10px);
        right: calc(50% + 10px);
        background-color: ${props => props.theme.default.content.stepper.stepConnector.completedBgColor};

        span { 
          border-color: ${props => props.theme.default.content.stepper.stepConnector.completedBorderColor};
          background-color: ${props => props.theme.default.content.stepper.stepConnector.completedBgColor};
        }
      }
    }
    
    .disabled { 
      opacity: 0.5;
      button { 
        cursor: not-allowed;
        &:hover {
          svg { color: ${props => props.theme.default.content.stepper.button.disableSvgColor} !important; }
          .MuiStepLabel-labelContainer { 
            span { color: ${props => props.theme.default.content.stepper.stepLabel.disableTextColor} !important; } 
          }
        }
      }
    }
  }
`

export default StyledStepper