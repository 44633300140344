import React, { Component, Fragment } from "react";
import { Scrollbars } from "react-custom-scrollbars";
import styled from "styled-components";

import {
    Grid,
    Card,
    List,
    ListItem,
    ListItemIcon,
    ListItemText,
    Typography
} from "@material-ui/core/";
import DoneIcon from "@material-ui/icons/Done";

import Translate from "../../../../../../../i18n/translate";
import Session from "../../../../../../../utils/session-info";
import ObjectUtils from "../../../../../../../utils/object-utils";

import AttributeTypeComponentList from "../../../../../attribute-type-component-list";

import ConectorInputText from "../../../../../../../components/conector-ui/conector-input-text";
import StyledModal from "../../../../../../../components/conector-ui/conector-styled-components/styled-modal";
import StyledButton from "../../../../../../../components/conector-ui/conector-styled-components/styled-button";

const StyledTypography = styled(Typography)`
    && { 
        padding-bottom: 20px;
        color: #6F8296;
    }
`

const StyledGrid = styled(Grid)`
    && { 
        width: calc(100% + 12px);

        div.MuiGrid-item:first-child {
            max-width: 100%;
            flex-basis: 100%;
        }
    }
`

const DivSearch = styled.div`
    && {
        margin: 20px 20px 30px 20px;
        display: flex;
        align-items: center;
        > div { flex-basis: 80%; }
    }
`

const StyledList = styled(List)`
    && {
        padding-top: 0;
        padding-bottom: 0;
        border-bottom: 1px solid ${props => props.theme.default.content.chipInterface.borderColor};
    }
`

const StyledCard = styled(Card)`
  && {
    margin: 20px;
    box-shadow: 0 0 0 0;
    border: 1px solid #B8C6D6;
    width: 560px;
    height: 300px;
  }
`

const StyledListItem = styled(ListItem)`
    && {
        width: 560px;
        border-top: 1px solid ${props => props.theme.default.content.chipInterface.borderColor};
        background-color: ${props => props.theme.default.content.chipInterface.bgColor};
        color: ${props => props.theme.default.content.chipInterface.textColor};
        
        &:first-child { border-top: 0px; }
        &:hover { background-color: #B8C6D6; }

        .MuiListItemText-root span {
            padding-top: 7px;
            padding-bottom: 3px;
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
            font-size: 0.8rem;
            color: ${props => props.theme.default.content.chipInterface.textColor};
        }
        
        &.selected {
            background-color: #009cdf;
            .MuiListItemIcon-root svg,
            .MuiListItemText-root span {
                color: #fff;
            }
        }
    }
`

const FooterDiv = styled.div`
    width: 100%;
    height: 55px;
    box-sizing: border-box;
    padding: 0 10px 10px 10px;

    button {
      float: right;
      margin-right: 10px;
    }

    @media screen and (max-width:390px) {
      button {
        min-width: 115px !important;
      }
    }

    @media screen and (max-width:320px) {
      button {
        min-width: calc(100% - 20px) !important;
        margin-bottom: 10px;
      }
    }
`

class FieldsSubaction extends Component {
    constructor(props) {
        super(props);

        this.state = {
            attrs: [],
            openModalFrom: false,
            optionSelected: {},
            optionInputSelected: {},
            options: [],
            optionsFilter: [],
            optionSearch: "",
            ...props,
        }
    }
    
    getOptions = attr => {
        const { selectedInputFields } = this.state;

        if (attr.options) {
            if (selectedInputFields && selectedInputFields.length) {
                const validFieldDescriptions = selectedInputFields.map(detailField => {
                    if (detailField.fieldAttributes && detailField.fieldAttributes.length) {
                        return detailField.fieldAttributes.find(attr => attr.codAttribute === 1).desValue
                    }
                });
                attr.options = attr.options.filter(option => validFieldDescriptions.includes(option.description));
            }

            return attr.options.map(option => {
                return {
                    label: option.label,
                    value: option.codAttribute,
                    description: option.description,
                    master: option.CodMaster,
                }
            }).sort((o1, o2) => { return o1.label - o2.label })
        }
    }

    componentDidMount = _ => {
        let state = { ...this.state }
        this.props.update({
            ...state
        });
    }

    handleChangeSelect = e => {
        let { selectedField, attrs } = this.state;

        const attrFromList = attrs.find(attr =>
            attr.codAttribute === e.master
        );

        let attr = selectedField.fieldAttributes.find(fieldAttr =>
            fieldAttr.codAttribute === attrFromList.codAttribute
        );

        if (!attr) {
            selectedField.fieldAttributes.push({
                codAttribute: parseInt(e.master, 10),
                desValue: e.description,
                desLabel: String(e.value),
                indPosition: attrFromList.indPosition,
                codInstance: Session().codInstance,
                description: attrFromList.description
            });
        } else {
            attr.desValue = e.description;
            attr.desLabel = String(e.value);
            attr.description = attrFromList.description;
        }

        //selectedField.attributeType = e;

        this.props.update({
            ...this.state,
            selectedField
        });
    }

    handleChange = e => {
        let { selectedField, attrs } = this.state;
        const name = parseInt(e.target.name, 10);
        const newValue = e.target.value;

        if (!selectedField.fieldAttributes) {
            selectedField.fieldAttributes = [];
        }

        let attr = selectedField.fieldAttributes.find(fieldAttr =>
            fieldAttr.codAttribute === name
        );

        const attrFromList = attrs.find(attr =>
            attr.codAttribute === name
        );

        if (!attr) {
            selectedField.fieldAttributes.push({
                codAttribute: name,
                desValue: String(newValue),
                indPosition: attrFromList.indPosition,
                codInstance: Session().codInstance,
                description: attrFromList.description
            })
        } else {
            attr.desValue = String(newValue);
            attr.description = attrFromList.description;
        }
        this.props.update({
            ...this.state,
            selectedField
        });
    }

    getValue = attr => {
        const { selectedField } = this.state;

        if (!selectedField.fieldAttributes) {
            selectedField.fieldAttributes = [];
        }

        if (attr.options) {
            const fieldAttr = selectedField.fieldAttributes.find(at =>
                at.codAttribute === attr.codAttribute
            );

            if (fieldAttr) {
                const attrType = attr.options.find(att =>
                    att.description === fieldAttr.desValue
                );
                if (attrType) {
                    return attrType;
                } else {
                    return null;
                }
            }

        } else {
            let fieldAttribute = selectedField
                .fieldAttributes
                .find(fieldAttribute =>
                    fieldAttribute.codAttribute === attr.codAttribute
                );

            if (fieldAttribute) {
                if (fieldAttribute.desValue.trim() === "") {
                    return fieldAttribute.desValue.replaceAll(' ', '');
                } else {
                    return fieldAttribute.desValue;
                }
            } else {
                return "";
            }
        }
    }

    isBusy = requiredAttrs => {

        const state = { ...this.state };

        const filledAttributes = state.selectedField.fieldAttributes.map(attr => {
            if (!!attr.desValue) {
                return attr.codAttribute;
            }
        });

        return !!requiredAttrs.find(requiredAttr =>
            !filledAttributes.includes(requiredAttr)
        )
    }

    componentWillReceiveProps = props => {
        if (props.selectedField) {
            props.selectedField.fieldAttributes.map(fieldAttribute => {
                const attribute = props.attrs.find(attribute =>
                    attribute.codAttribute == fieldAttribute.codAttribute
                );

                if (attribute) {
                    fieldAttribute.description = attribute.description
                }
            });
        }

        this.setState({ ...props })
    }

    getAttrsToRender = _ => {
        const { selectedInputFields, attributeGroups, selectedField, attrs } = this.state;

        if (!selectedInputFields || !selectedField || !selectedField.fieldAttributes) {
            return attrs;
        }

        let attrType = selectedField.fieldAttributes.find(fieldAttribute => fieldAttribute.description == "field_type");
        if (!attrType) {
            return attrs;
        }

        attrType = '2';

        let attrsToRender = attributeGroups[0]
            .AttributeGroupCodes
            .filter(attr => !attr.listMaster || attr.listMaster == attrType);

        const idsToRender = attrsToRender.map(attr => attr.codAttribute);

        return attrs.filter(attr =>
            idsToRender.includes(attr.codAttribute)
        );
    }

    getOptionsSelectedDetailFieldFrom = _ => {
        const { selectedInputFields, selectedField } = this.state
        let options = []

        selectedInputFields.forEach(option => {
            const fieldAttrType = option.fieldAttributes.find(fieldAttribute => fieldAttribute.codAttribute === 1);
            const attrType = selectedField.fieldAttributes.find(fieldAttribute => fieldAttribute.description == "field_type");

            if (attrType && fieldAttrType.desValue === attrType.desValue) {
                const fieldAttrTo = option.fieldAttributes.find(fieldAttribute => fieldAttribute.codAttribute === 11);

                options.push({
                    label: fieldAttrTo.desValue,
                    value: fieldAttrTo.codAttribute,
                    description: fieldAttrTo.desValue,
                    master: fieldAttrTo.codField
                });
            }
        })

        options.sort((o1, o2) => { return o1.label > o2.label ? 1 : -1 })

        return options
    }

    handleChangeSelectedDetailFieldFrom = (itemSelected) => {

        let { selectedField, attrs } = this.state
        let fromAttr = attrs.find(attr => attr.description.indexOf("from") !== -1)

        let attr = selectedField.fieldAttributes.find(
            fieldAttr => fieldAttr.codAttribute === fromAttr.codAttribute
        )

        if (!attr) {
            selectedField.fieldAttributes.push({
                codAttribute: fromAttr.codAttribute,
                codMaster: itemSelected.master,
                desValue: itemSelected.label,
                indPosition: 1,
                codInstance: Session().codInstance,
                description: "from"
            })
        } else {
            attr.desValue = itemSelected.label;
            attr.codMaster = itemSelected.master;
            attr.descritpion = "from";
        }

        this.props.update({
            ...this.state,
            optionSelected: {},
            optionSearch: "",
            openModalFrom: false,
            selectedField
        });
    }

    getValueSelectedDetailFieldFrom = (options, attr) => {
        const { selectedField } = this.state;

        if (!selectedField || !selectedField.fieldAttributes) {
            return {};
        }

        let fieldAttribute = selectedField.fieldAttributes.find(
            att => att.codAttribute === attr.codAttribute
        );

        if (fieldAttribute) {
            return options.find(option =>
                option.master === fieldAttribute.codMaster
                || option.description === fieldAttribute.desValue
            ) || {}
        } else {
            return {};
        }
    }

    handleOpenModal = _ => {
        let state = { ...this.state };
        state.optionSelected = state.optionInputSelected;
        state.openModalFrom = true;
        this.setState(state);
    }

    handleCloseModal = _ => {
        this.setState({
            optionSelected: {},
            optionSearch: "",
            openModalFrom: false
        })
    }

    handleSearch = _ => {
        let state = { ...this.state };
        state.optionsFilter = state.options.map(r => r).filter(r => r.label.includes(state.optionSearch));
        this.setState(state);
    }

    handleSearchChange = event => {
        let state = { ...this.state };
        state.optionSearch = event.target.value;
        state.optionsFilter = state.options.map(r => r).filter(r => r.label.includes(state.optionSearch));
        this.setState(state);
    }

    handleOptionSelected = value => {
        let state = { ...this.state };
        state.optionSelected = value;
        state.optionsFilter = state.options.map(r => r).filter(r => r.label.includes(state.optionSearch));
        this.setState(state);
    }

    handleChangeCheckbox = e => {
        let { selectedField, attrs } = this.state;
        const name = parseInt(e.target.name, 10);

        if (!selectedField.fieldAttributes) {
            selectedField.fieldAttributes = [];
        }

        let attr = selectedField.fieldAttributes.find(fieldAttr =>
            fieldAttr.codAttribute === name
        );

        const attrFromList = attrs.find(attr =>
            attr.codAttribute === name
        );

        if (!attr) {
            selectedField.fieldAttributes.push({
                codAttribute: name,
                desValue: "true",
                indPosition: attrFromList.indPosition,
                codInstance: Session().codInstance,
                description: attrFromList.description
            })
        } else {
            attr.desValue = attr.desValue === "true" ? "false" : "true";
            attr.description = attrFromList.description;
        }
        this.props.update({
            ...this.state,
            selectedField
        });
    }

    getChecked = ({ codAttribute }) => {
        const { selectedField } = this.state;

        let detailExtra = selectedField.fieldAttributes.find(fieldAttr => fieldAttr.codAttribute === codAttribute);

        if (detailExtra) {
            return detailExtra.desValue === "true";
        }

        return false;
    }

    getRenderedAttrs = attrs => {
        const {
            selectedField,
            selectedInputFields
        } = this.state;
        var itens = [],
            requiredFields = [];

        if (!selectedField.fieldAttributes) {
            selectedField.fieldAttributes = [];
        }

        var fieldTypeAttr = selectedField.fieldAttributes.find(att =>
            att.codAttribute === 1
        ) || {};

        for (let i = 0; i < attrs.length; i++) {
            const attr = attrs[i];

            if (attr.label.indexOf("format_to") !== -1
                || attr.label.indexOf("format_from") !== -1
            ) {
                if (ObjectUtils.equals(fieldTypeAttr, {})) {
                    continue;
                }

                const containFormat = [
                    "datetime",
                    "date",
                    "decimal",
                    "system",
                    "string"
                ];

                if (!containFormat.includes(fieldTypeAttr.desValue)) {
                    continue;
                }
            } else if (attr.label === "from" && fieldTypeAttr.desValue === "system" && attrs.find(({label}) => label === "from_system") || 
                    attr.label === "from_system" && fieldTypeAttr.desValue !== "system") {
                continue;
            }

            if (selectedInputFields
                && selectedInputFields.length
                && attr.label.indexOf("from") !== -1
            ) {

                const TypeComponent = AttributeTypeComponentList("type_from")
                this.state.options = this.getOptionsSelectedDetailFieldFrom(attr)
                this.state.optionsFilter = this.state.options
                this.state.optionInputSelected = this.getValueSelectedDetailFieldFrom(this.state.options, attr)

                itens.push(
                    <Grid item xs={12} sm={6} xl={4} key={attr.codAttribute}>
                        {
                            React.createElement(TypeComponent, {
                                label: attr.label,
                                name: String(attr.codAttribute),
                                required: attr.isRequired === "true",
                                value: this.state.optionInputSelected.label,
                                endIcon: "aspect_ratio",
                                endTooltip: Translate("view_options"),
                                endActionButton: this.handleOpenModal
                            })
                        }
                    </Grid>
                );
            } else {
                const TypeComponent = AttributeTypeComponentList(attr.codAttributeType);
                const options = this.getOptions(attr) || [];

                let value = this.getValue(attr);

                if (attr.codAttributeType === 7 && value) {
                    value = options.find(option => option.value == value.codAttribute);
                }

                itens.push(
                    <Grid item xs={12} sm={6} xl={4} key={attr.codAttribute}>
                        {
                            React.createElement(TypeComponent, {
                                required: attr.isRequired === "true",
                                options: options,
                                label: attr.label,
                                checked: this.getChecked(attr),
                                value: value,
                                name: String(attr.codAttribute),
                                showTime: true,
                                onChange: ((() => {
                                    if (attr.options) {
                                        return this.handleChangeSelect.bind(this);
                                    } else if (attr.codAttributeType === 5) {
                                        return this.handleChangeCheckbox.bind(this);
                                    } else {
                                        return this.handleChange.bind(this);
                                    }
                                }).bind(this))()
                            })
                        }
                    </Grid>
                )
            }

            if (attr.isRequired === "true") {
                requiredFields.push(parseInt(attr.codAttribute, 10));
            }
        }

        return {
            renderedAttrs: itens,
            requiredFields
        };
    }

    generateOptionsToSave = optionsFilter => {
        const { optionSelected, selectedNode } = this.state

        if(Object.keys(selectedNode).length !== 0){
            if(selectedNode.node.hasOwnProperty('fields')){
                let savedFields = selectedNode.node.fields
    
                if(savedFields != undefined){
                    savedFields.forEach(savedField => {
                        optionsFilter.forEach((option, index) => {
                            if(savedField.from == option.label){
                                optionsFilter.splice(index, 1);
                            }
                        })
                    })
                }
            }
        }

        return optionsFilter.map(r =>
            <StyledListItem
                button
                key={r.label}
                onClick={e => this.handleOptionSelected(r)}
                className={r.label === optionSelected.label ? "selected" : ""}
            >
                    {
                        r.label === optionSelected.label ?
                            <ListItemIcon>
                                <DoneIcon />
                            </ListItemIcon>
                            : ""
                    }
                    <ListItemText inset title={r.label} primary={r.label} />
            </StyledListItem>
        )
    }

    render() {
        const { stepBusy, openModalFrom, optionSearch, optionsFilter, optionSelected } = this.state,
            attrs = this.getAttrsToRender() || [],
            { renderedAttrs, requiredFields } = this.getRenderedAttrs(attrs);

        if (stepBusy !== this.isBusy(requiredFields)) {
            this.props.update({ stepBusy: !stepBusy })
        }

        return (
            <Fragment>
                <StyledTypography>{Translate("select_field_type")}</StyledTypography>

                <StyledGrid container spacing={24}>
                    {renderedAttrs}
                </StyledGrid>

                <StyledModal
                    aria-labelledby="simple-modal-title"
                    aria-describedby="simple-modal-description"
                    open={openModalFrom}
                    onClose={this.handleCloseModal}>
                    <div className={"contentModal"}>
                        <Typography className={"titleModal"}>{Translate("select_options_below")}</Typography>

                        <DivSearch>
                            <ConectorInputText
                                name={"search_modal"}
                                type={"text"}
                                placeholder={"search"}
                                value={optionSearch}
                                onChange={this.handleSearchChange}
                                onKeyPress={this.handleSearch}
                                endIcon={"search"}
                                endTooltip={Translate("search")}
                                endActionButton={this.handleSearch}
                            />
                        </DivSearch>

                        <StyledCard>
                            <Scrollbars>
                                <StyledList component="nav">
                                    {
                                        this.generateOptionsToSave(optionsFilter)
                                    }
                                </StyledList>
                            </Scrollbars>
                        </StyledCard>

                        <FooterDiv>
                            <StyledButton
                                variant="contained"
                                onClick={_ => { this.handleChangeSelectedDetailFieldFrom(optionSelected) }}
                            >
                                {Translate("select")}
                            </StyledButton>

                            <StyledButton
                                color="primary"
                                variant="contained"
                                onClick={this.handleCloseModal}
                            >
                                {Translate("cancel")}
                            </StyledButton>
                        </FooterDiv>

                    </div>
                </StyledModal>
            </Fragment>
        )
    }
}

export default FieldsSubaction