import styled from "styled-components";
import StyledButton from "../../../components/conector-ui/conector-styled-components/styled-button";
import { StyledDialog } from "../../../components/conector-ui/conector-styled-components/styled-dialog";

export const StyledDialogZIndex = styled(StyledDialog)`
    && {
        .MuiPaper-root {
            overflow-y: unset;
        }

        .MuiDialogContent-root {
            padding-top: 0;
            overflow-y: unset;
        }
    }`

export const StyledLeftButton = styled(StyledButton)`
    && {
        margin-inline-end: auto;
        margin-left: 8px;
    }`

export const StyledLoading = styled.div`
    && > div {
        height: 256px;
    }`
    
export const titleStyles = {
    marginBottom: "12px",
    fontSize: "19px",
    paddingBottom: "3px",
    fontWeight: "bold",
    color: "#949494",
    fontFamily: "Roboto, Helvetica, Arial, sans-serif",
    borderBottom: "1px solid #a7a7a7"
}