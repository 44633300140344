import request from '../../../../utils/request'
const getVersion = async (system) => {
    return request.executeRequest(
        `${request.baseUrl}path_notes/get_last_version/${system}`,
        request.getOptions()
    ).then(res => res.json())
}
const  getPathNotes = async (system) => {
    return request.executeRequest(
        `${request.baseUrl}path_notes/${system}`,
        request.getOptions()
    ).then(res => res.json())
}
const  addOrEditVersion = async (object) => {
    return request.executeRequest(
        `${request.baseUrl}path_notes/new`,
        {
            ...request.postOptions(),
            body: JSON.stringify(object)
        }
    ).then(res => {
        if (!res.ok) {
            return Promise.reject(res.statusText)
        }
        return res.json();
    })
}
const deletePathNotes = async (id) => {
    return request.executeRequest(
        `${request.baseUrl}path_notes/delete/${id}`,
        {
            ...request.deleteOptions()
        }
    ).then(res => res.json())
}
export default {
    getVersion: getVersion,
    getPathNotes:getPathNotes,
    addOrEditVersion:addOrEditVersion,
    deletePathNotes,deletePathNotes
}