import React, { Component, Fragment } from 'react'
import AllVersionsModal from './allversions-modal'
import Typography from '@material-ui/core/Typography'
import styled from 'styled-components'
import Link from '@material-ui/core/Link'
import Translate from "../../../i18n/translate"
import request from "../../../utils/request"
import Session from '../../../utils/session-info'
import InfoVersionButton from './widget-version/info-version-button'
import version from "../version/generic/version";

const ColoredBold = styled.strong`
    && {
        color: #ffffff !important;
    }
`

const StyledVersion = styled.label ` 

	& {
		width: 100%;
		position: absolute;
		left: 0px;
		bottom: 10px;
		color: #b8c6d6;
		text-align: center;
		font-size: 1rem;
		font-weight: 400;
		font-family: "Roboto", "Helvetica", "Arial", sans-serif;
	}

`;

class VersionButton extends Component {

    constructor(props) {
        super(props)
        this.state = {
            ...props.appState,
            openVersionModal: false,
            openAllVersionsModal: false,
            outOfDate: false,
        }
    }

    handleOpenModal = () => {
        this.setState({ openAllVersionsModal: true });
    }

    handleDismissModal = () => {
        this.setState({ openAllVersionsModal: false });
    }

    componentWillReceiveProps({ appState }) {
        this.setState({ ...appState });
    }

    async componentDidMount() {
        const lastVersion = await version.getVersion("Worker")
        var ultimo = lastVersion

          getVersionWorker()
            .then((x) => {
              const result = compareVersion(x.desversionworker, ultimo);
              if (result >= 0)
                this.setState({ outOfDate: false });
              else
                this.setState({ outOfDate: true });
            })
            .catch(() => {
              this.setState({ outOfDate: true });
            });
      }

    render() {

        const { openAllVersionsModal, outOfDate } = this.state;
        return (
            <Fragment>
                <div className={'btnVersion'} onClick={this.handleOpenModal}>
                    <div>
                        <Typography>
                            <StyledVersion>
                                <Link color="inherit">
                                    <span style={{marginLeft: '75px', display: 'flex', alignItems: 'center' }}>
                                        {Translate("versions")}                             
                                        {
                                            (() => {
                                                if(outOfDate){
                                                    return <InfoVersionButton />
                                                }
                                            })()
                                        }
                                    </span>
                                </Link>
                            </StyledVersion>
                        </Typography>
                    </div>
                </div>
                {
                    (() => {
                        if (openAllVersionsModal) {
                             return <AllVersionsModal onDismiss={this.handleDismissModal.bind(this)} />
                        }
                    })()
                }
            </Fragment>
        )
    }
}

function getVersionWorker(codInstance = Session().codInstance) {
    return request.executeRequest(`${request.baseUrl}globals/getversionworker/${codInstance}`, request.getOptions()).then(response => {
        if (!response.ok) {
            return Promise.reject(response.statusText);
        }

        return response.json();
    }).then(worker => {
        sessionStorage.setItem("worker", JSON.stringify(worker));

        return worker;
    });
}

function compareVersion(version1, version2) {
    const v1Parts = version1.split('.').map(Number);
    const v2Parts = version2.split('.').map(Number);
  
    for (let i = 0; i < v1Parts.length; i++) {
      if (v1Parts[i] < v2Parts[i]) {
        return -1; // version1 é menor
      } else if (v1Parts[i] > v2Parts[i]) {
        return 1; // version1 é maior
      }
    }
  
    return 0; // As versões são iguais
  }

export default VersionButton