import React, { Component, useState, Fragment } from "react";
import { connect } from "react-redux";
import styled from "styled-components";
import { Table, Divider, FormGroup, FormControlLabel, Switch, Grid } from '@material-ui/core/';
import Loading from "../../components/conector-ui/conector-loading";
import { setBreadcrumb } from "../../store/app-state/actions";
import Tooltip from '@material-ui/core/Tooltip';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import ExpandLessIcon from '@material-ui/icons/ExpandLess';
import ConectorDatePicker from "../../components/conector-ui/conector-date-picker";
import './customer-management.css'
import ConectorInputText from "../../components/conector-ui/conector-input-text";
import InputMask from 'react-input-mask';
import ConectorDialogConfirmation from "../../components/conector-ui/conector-dialog-confirmation";
import Session from "../../utils/session-info";
import CustomerManagementService from "./customer-management-service";
import { parse, format } from 'date-fns';
import Toast from "../../components/toast/toast";

export const StyledDivider = styled(Divider)`
			&& {
				bottom: 0;
				position: sticky;
				background-color: #B8C6D6;
			}
		`

export const StyledGridContainer = styled.div`
			&& {
				width: 100%;
				overflow: auto;
				&.scrollWidth { 
					hr { width: 100%; }

					&.scrollHeight {
						hr { width: 100%; }
					}
					:before {
						content: "";
						width: 1px;
						right: 32px;
						z-index: 9999;
						position: absolute;
						background-color: #B8C6D6;
					}
				}

				&.scrollHeight {
					hr { width: calc(100% - 7px); }
				}

				::-webkit-scrollbar-corner { background-color: #eee; }
				::-webkit-scrollbar {
					width: 6px;
					height: 6px;
					background-color: #eee;
					cursor: pointer;
				}

				::-webkit-scrollbar-thumb {
					border-radius: 10px;
					background-color: rgba(0, 0, 0, 0.2);
					cursor: pointer;
				}
			}
		`

export const StyledTableFooter = styled(Table)`
			&& { 
				background: white;
				tr { border: 1px solid #B8C6D6; }
			}
		`

export const StyledTable = styled(Table)`
			&& {

				tbody > tr.contentRow > td:not(:last-child):not(.checkbox) {
					padding: 10px 24px;
				}

				tbody > tr.contentRow > td:not(.checkbox) {
					padding-top: 10px;
					padding-bottom: 10px
				}

				tbody td.pointer { cursor: pointer; }

				tr {
					cursor: 'pointer';
					height: auto;
					width: auto;
					border-right: 1px solid #B8C6D6;
					border-left: 1px solid #B8C6D6;
					&.MuiTableRow-selected { 
						td { 
							background-color: rgba(224, 224, 224, 1);
							cursor: default;
						} 
					}

					th {
						color: rgb(76, 99, 123);
						padding: 10px 24px;
						background-color: #B8C6D6;
						text-transform: uppercase;
						font-size: 0.75rem;
						font-weight: 500;
						text-align: center;
						height: auto;
						span { color: #4c637b; }
						&:hover { background-color: #b1bfce; }
						&.bgActive { font-weight: bold; font-size: 0.8rem; }
						&.MuiTableCell-paddingCheckbox {
							width: 50px;
							padding: 0 12px;
							svg { color: #6F8296; } 
						}
					}
					
					td {
 						z-index: 1;
						position: relative;
						white-space: nowrap; 
						overflow: hidden;
						text-overflow: ellipsis;
						background-color: #fff;
						color: #6F8296;
						line-height: 25px;
						text-align: center;
						min-width: 50px;
						font-size: 0.8125rem;
						font-weight: 400;
						height: auto;
						width: auto;
						&.MuiTableCell-paddingCheckbox { 
							padding: 0 12px;
							svg { color: #6F8296; } 
						}

						.iconCopy {
							position: absolute;
							margin-left: -30px;
							top: calc(50% - 13px);
							cursor: pointer;
							&:hover { color: #009CDF; }
						}

						&.notFound {
							text-align: center;
							padding-top: 20px;
							padding-bottom: 20px;
							font-weight: bold;
						}
					}

					&:last-child { td { border-bottom: 0; }}
					tbody tr { cursor: pointer; }
				}

				thead > tr > th {
					top: 0;
					z-index: 2;
					position: sticky;
				}

				thead > tr > th:last-child { padding-right: 0px; }

				&&.full-size-container {
					width: 100%;
					margin-right: 0;
				}

				margin: auto;
				border-collapse: collapse;
			}
		`