import React, { Component, Fragment } from 'react'
import Translate from '../../i18n/translate'
import styled from 'styled-components'
import Typography from '@material-ui/core/Typography'

const Title = styled(Typography)`
    && {
        color: ${props => props.theme.default.content.pageTitle.textColor};
        font-size: 20px;
        &.marginLeft { margin-left: 170px; }
    }
`

class PageTitle extends Component {
    constructor(props) {
        super(props);
        this.state = {
            ...props.appState
        }
    }

    componentWillReceiveProps = props => {
        let state = {...this.state, ...props.appState, openDrawer: props.appState.openDrawer}
        this.setState(state)
    }

    render() {
        
        const { breadcrumb, openDrawer } = this.state

        if (!breadcrumb || !breadcrumb.length) {
            return ( <Fragment></Fragment> )
        }

        /*
            Caso remover a seção principal do breadcrumb, 
            criar uma action e setar a prop na store.
        */
        let title = breadcrumb[1].label;

        return ( 
            <Title className={ openDrawer ? 'marginLeft' : '' }>
                {Translate(title)}
            </Title>
        )
    }
}

export default PageTitle