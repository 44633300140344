import styled from "styled-components";
import SortableTree from "react-sortable-tree";

import { darken } from "@material-ui/core/styles/colorManipulator";

import StyledButton from "../../../components/conector-ui/conector-styled-components/styled-button";

export const StyledContainer = styled.div`
    && {
        margin-top: 15px;
    }

`;

export const StyledButtonContainer = styled.div`
    && {
        text-align: center;
        margin-top: 15px;
    }

`;

export const StyledGridButton = styled(StyledButton)`
    && { 
        min-width: 50px;
        margin: 0 20px 20px 0;

        &:last-child {
            margin-right: 0;
        }

        @media screen and (max-width:500px) {
            min-width: calc(50% - 13px);
            &:last-child { min-width: calc(100% - 15px); }
        }
    }

`;

export const StyledSortableTree = styled(SortableTree)`
    && {
        overflow: auto hidden;
        background-color: #fff;
        border: 1px solid #B8C6D6;
        border-radius: 4px;
        padding-right: 8px;
        > div { height: auto !important; }
        &.rst__tree > div > div.ReactVirtualized__Grid.ReactVirtualized__List { 
            height: auto !important;
            min-height: calc(100vh - 690px);
            ::-webkit-scrollbar { height: 8px; }
            ::-webkit-scrollbar-track { 
                -webkit-border-radius: 4px; 
                background: #f5f5f5; 
                border-radius: 4px; 
            }
            ::-webkit-scrollbar-thumb { 
                -webkit-border-radius: 4px; 
                border-radius: 4px; 
                background: #009cdf;
            }
            ::-webkit-scrollbar-thumb:window-inactive { background-color: #009cdf; }
        }

        .rst__moveHandle { 
            border: 0;
            box-shadow: 0 0;
            background-color: #B8C6D6;
            border-radius: 2px 0 0 2px;
            &:hover { background-color: ${darken("#B8C6D6", 0.2)}; }
        }

        .rst__rowContents { 
            min-width: 30px;
            box-shadow: 0 0;
            border: 1px solid #B8C6D6;
            border-radius: 0 2px 2px 0;
            cursor: pointer;
            &:hover { background-color: #f5f5f5; }
        }

        .rst__node { height: 59px !important; }
        .rst__collapseButton, .rst__expandButton { box-shadow: 0 0 0 1px #009CDF; }
        .rst__rowLabel { padding-right: 10px; color: #4C637B; }
        .rst__lineBlock, .rst__lineChildren { &::after, &::before { background-color: #B8C6D6; } }
        .rst__toolbarButton {
            button { 
                color: #6F8296;
                &:hover {
                    color: #009CDF;
                    background-color: transparent;    
                }
            }
        }

        .rst__rowTitle {
            font-size: 12px;
            font-weight: normal;
            font-family: "Roboto", "Helvetica", "Arial", sans-serif;
        }

        .rst__row.selected {
            .rst__rowContents {
                cursor: default;
                background-color: #4C637B;
                .rst__rowLabel { color: #fff; }
                .rst__toolbarButton {
                    button { 
                        color: #fff;
                        &:hover { color: #fff; }
                    }
                }
            }
        }

        ::-webkit-scrollbar-corner { background-color: #eee; }
        ::-webkit-scrollbar {
            width: 6px;
            height: 6px;
            background-color: #eee;
            cursor: pointer;
        }

        ::-webkit-scrollbar-thumb {
            border-radius: 10px;
            background-color: rgba(0, 0, 0, 0.2);
            cursor: pointer;
        }
    }

`;

export const StyledSeparator = styled.div`
    && {
        width: 100%;
        height: 1px;
        background-color: #B8C6D6;
    }

`;

export const StyledContainerTableAndRadio = styled.div`
    && {
        text-align: center;
    }
`;

export const StyledRadioGroup = styled.div`
    && {
        display: flex;
    }
`;

export const StyledGridTable = styled.div`
    && {
        width: 100%;
        margin-top: 10px;
    }
`;