import React, { Component } from "react";
import { StyledList, PanelTitle } from "./scenario-selection-modal-styles";
import Translate from "../../../i18n/translate";
import StyledButton from "../../conector-ui/conector-styled-components/styled-button";
import {
    Radio,
    ListItem,
    ListItemText,
    DialogContent,
    Grid,
    DialogActions,
    FormGroup
} from "@material-ui/core";
import Session from "../../../utils/session-info";
import CompaniesService from "../../../routes/companies/companies-service";
import {
    StyledDialog,
    StyledDialogTitle
} from "../../conector-ui/conector-styled-components/styled-dialog";
import ConectorInputText from '../../conector-ui/conector-input-text'
import { postChangedAuthentication } from "../../../store/login/actions";
import SessionUtils from "../../../utils/session-utils";
import instancesService from "../../../routes/instances/instances-service";

class ScenarioSelection extends Component {
    constructor(props) {
        super(props);

        const { codCompany, codInstance } = Session();

        this.state = {
            selectedCompany: codCompany,
            selectedInstance: codInstance,
            companies: [],
            companyInstances: [],
            companiesFiltered: [],
            companyInstancesFiltered: []
        };
    }

    componentWillMount = () => {
        CompaniesService.GetCompanies().then(companies => {
            this.setState({ 
                companies, 
                companiesFiltered: companies,
            });
            this.updateInstances();
        });
    };

    updateInstances = () => {
        const {
            companies,
            selectedCompany
        } = this.state;

        if (selectedCompany) {
            instancesService.GetInstances(selectedCompany).then(instancesList => {
                this.setState({
                    companyInstances: instancesList,
                    companyInstancesFiltered: instancesList
                });
            });
        }
    };

    onChangeCompany = ({ target: { value } }) => {
        this.setState(
            {
                selectedCompany: Number(value),
                selectedInstance: null
            },
            this.updateInstances
        );
    };

    onChangeInstance = ({ target: { value } }) => {
        this.setState({ selectedInstance: Number(value) });
    };

    onChangeScenario = () => {
        const { selectedCompany, selectedInstance } = this.state;
        postChangedAuthentication(selectedCompany, selectedInstance).then(
            SessionUtils.changeUserScenario
        );

        window.location.replace('#/')
    };

    searchCompanies = (searchInput) => {
        let { companies } = this.state;

        var companiesFilteredList = companies.filter(company => (
            company.description.toLowerCase().includes(searchInput.toLowerCase())
        ));

        this.setState({ companiesFiltered: companiesFilteredList });
    }

    searchInstances = (searchInput) => {
        let { companyInstances } = this.state;
      
        var companyInstancesFilteredList = companyInstances.filter((instance) => {
          const lowerCaseSearchInput = searchInput.toLowerCase();
          const instanceId = instance.id ? instance.id.toString().toLowerCase() : '';
          return (
            instance.description.toLowerCase().includes(lowerCaseSearchInput) ||
            instanceId.includes(lowerCaseSearchInput)
          );
        });
      
        this.setState({ companyInstancesFiltered: companyInstancesFilteredList });
    };

    renderCompanies = () => {
        const { companiesFiltered, selectedCompany } = this.state;

        const sortedCompanies = companiesFiltered.slice().sort((a, b) => a.description.localeCompare(b.description, 'en', { sensitivity: 'base' }));
      
        const companiesList = sortedCompanies.map(company => (
          <ListItem id={company.id} key={company.id}>
            <ListItemText primary={company.description} />
            <Radio
              checked={selectedCompany === company.id}
              onChange={this.onChangeCompany}
              value={company.id}
              name={company.description}
              color="default"
            />
          </ListItem>
        ));

        return (
            <div>
                <StyledList>{companiesList}</StyledList>
            </div>
        );
    };

    renderInstances = () => {
        const { companyInstancesFiltered, selectedInstance } = this.state;
      
        const sortedInstances = companyInstancesFiltered.slice().sort((a, b) => a.description.localeCompare(b.description, 'en', { sensitivity: 'base' }));
      
        const instancesList = sortedInstances.map(instance => (
          <ListItem key={instance.id}>
            <ListItemText primary={`${instance.description} - ${instance.id}`} />
            <Radio
              checked={selectedInstance === instance.id}
              onChange={this.onChangeInstance}
              value={instance.id}
              name={instance.description}
              id={instance.description}
              color="default"
            />
          </ListItem>
        ));

        return (
            <div>
                <StyledList>{instancesList}</StyledList>
            </div>
        );
    };

    render() {
        const { selectedCompany, selectedInstance } = this.state;
        return (
            <StyledDialog
                open={true}
                disableEscapeKeyDown={true}
                disableBackdropClock={true}
                fullWidth={true}
                maxWidth={"lg"}
            >
                <StyledDialogTitle className="info">
                    {Translate("scenario_selection")}
                </StyledDialogTitle>
                <DialogContent>
                    <Grid container>
                        <DialogContent style={{paddingTop: 0}}>
                            <PanelTitle>{Translate("companies")}</PanelTitle>
                            <ConectorInputText
                                placeholder={Translate("searchCompany")}
                                onChange={(data) => {
                                    this.searchCompanies(data.target.value)
                                }}/>
                            {this.renderCompanies()}
                        </DialogContent>

                        <DialogContent>
                            <PanelTitle>{Translate("instances")}</PanelTitle>
                            <ConectorInputText
                                placeholder={Translate("searchInstance")}
                                onChange={(data) => {
                                    this.searchInstances(data.target.value)
                                }}/>
                            {this.renderInstances()}
                        </DialogContent>
                    </Grid>
                </DialogContent>
                <DialogActions>
                    <StyledButton
                        color="primary"
                        variant="contained"
                        onClick={this.props.onDismiss}
                    >
                        {Translate("cancel")}
                    </StyledButton>
                    <StyledButton
                        onClick={this.onChangeScenario}
                        disabled={!selectedCompany || !selectedInstance}
                    >
                        {Translate("apply_changes")}
                    </StyledButton>
                </DialogActions>
            </StyledDialog>
        );
    }
}

export default ScenarioSelection;