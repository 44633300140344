import styled from 'styled-components'
import FormHelperText from '@material-ui/core/FormHelperText'

const StyledFormHelpText = styled(FormHelperText)`
    && {
        color: ${props => props.theme.default.content.input.helperText.textColor};
        font-size: 0.8rem;
        margin-bottom: 0;
    }
`

export default StyledFormHelpText