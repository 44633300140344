import React, {
    Component,
    Fragment
} from "react";
import { connect } from 'react-redux'

import Loading from '../../components/conector-ui/conector-loading'
import ConectorGrid from '../../components/conector-ui/conector-grid/conector-grid'

import { setBreadcrumb } from '../../store/app-state/actions'
import * as workerReducer  from '../../store/worker/worker-store-reducer'
import { defineWorkerProperties } from '../../store/worker/worker-store-actions'


class Worker extends Component {
    constructor(props){
        super(props);

        props.setBreadcrumb([{
            label: "dataworker"
        }, {
            label: "worker"
        }]);
        this.state = {
            loading: true,
            gridOptions: {
                multiSelect: false,
                sortField: "datinstall",
                sortType: "desc",
                columns: [{
                    title: "token",
                    field: "instancetoken"
                }, {
                    title: "osPlatform",
                    field: "desosplatform"
                }, {
                    title: "osVersion",
                    field: "desosversion"
                }, {
                    title: "hostName",
                    field: "deshostname"
                }, {
                    title: "domainName",
                    field: "desdomainname"
                }, {
                    title: "totalMemory",
                    field: "destotalmemory"
                }, {
                    title: "language",
                    field: "language"
                }, {
                    title: "active",
                    field: "isactive",
					cellFilter: "boolean"
                }, {
                    title: "datInstall",
                    field: "datinstall",
                    cellFilter: "ddMMyyyy-hh:mm"
                }, {
                    title: "datUninstall",
                    field: "datuninstall",
                    cellFilter: "ddMMyyyy-hh:mm"
                }, {
                    title: "workVersion",
                    field: "desversionworker"
                }, {
                    title: "systemType",
                    field: "dessystemtype"
                }]
            }
        }
    }

    componentWillReceiveProps(props) {
        let { gridOptions } = this.state;
        gridOptions.hideBtnNew = true;
		gridOptions.hideBtnEdit = true;
		gridOptions.hideBtnDelete = true;
        gridOptions.hideBtnCopy = true;

		this.setState({ gridOptions });
    }

    componentDidMount = () => {
        this.props.defineWorkerProperties()
	}

    render() {
		const { gridOptions } = this.state
        const { workerProperties } = this.props

		if (!workerProperties) {
			return <Loading />
		}

		return (
			<Fragment>
				<ConectorGrid
					gridTitle={"worker_list"}
					gridOptions={gridOptions}
					dataSource={workerProperties}>
				</ConectorGrid>
			</Fragment>
		)
    }

}

const mapStateToProps = store => {
	return {
        workerProperties: workerReducer.getWorkerProperties(store)
	}
}

const mapDispatchToProps = (dispatch) => {
	return {
		setBreadcrumb: (steps) => dispatch(setBreadcrumb(steps)),
        defineWorkerProperties: () => dispatch(defineWorkerProperties()),
	}
}

export default connect(mapStateToProps, mapDispatchToProps)(Worker);