/*
    https://coolors.co/b8c6d6-6f8296-5b728a-4c637b-344c67
    https://coolors.co/52b4b7-cd5c5c-ffc34b-009cdf-eeeeee
*/
export default {
    white : "#FFFFFF",
    verdigris : "#52B4B7", /* Success */
    indianRed : "#CD5C5C", /* Error */
    pastelOrange : "#FFC34B", /* Warning */
    
    /* Cinza / Azul */
    vividCerulean : "#009CDF", /* Highlight */
    deepKoamaru : "#344C67",
    paynesGrey : "#4C637B",
    uclaBlue : "#5B728A",
    slateGray : "#6F8296",
    pastelBlue : "#B8C6D6",
    isabelline : "#EEEEEE",
    whiteSmoke : "#F5F5F5",
    infoBlue: "#4C637B",
    green: "#4caf50"
}