import React, {
    Component,
    Fragment
} from "react";
import { connect } from "react-redux";
import styled from "styled-components";
import { getFlatDataFromTree } from "react-sortable-tree";

import {
    Tabs,
    Tab,
    Grid
} from "@material-ui/core";

import Session from "../../../utils/session-info";
import ObjectUtils from "../../../utils/object-utils";

import Loading from "../../../components/conector-ui/conector-loading";

import { setStepStatus } from "../../../store/app-state/actions";
import * as inputSelector from "../../../store/interfaces/reducer";
import * as wizardStore from "../../../store/wizard/wizard-store-reducer";
import { setWizardSelectedStructure, setWizardState, setWizardStructureArray, setTreeData, setStepStructure, setStepAuxiliary, setIsStructureChangeTab } from "../../../store/wizard/wizard-store-actions";
import Translate from "../../../i18n/translate";
import WizardService from "../wizard-service";
import WizardStructureTabs from "./wizard-structure-tabs-details";
import StyledButton from "../../../components/conector-ui/conector-styled-components/styled-button"
import ConectorDialogConfirmation from "../../../components/conector-ui/conector-dialog-confirmation";
import Toast from '../../../components/toast/toast'

const StyledTabs = styled(Tabs)`
    && {
        position: absolute;
        width: 100%;
        top: -47px;

        button {
            color: #6F8296;
            font-size: 0.75rem;

            border-top: 1px solid #fff;
            border-left: 1px solid #fff;
            border-right: 1px solid #fff;

            &:hover, &.MuiTab-selected {
                background-color: #f4f4f4;
                border-top: 1px solid #B8C6D6;
                border-left: 1px solid #B8C6D6;
                border-right: 1px solid #B8C6D6;
                border-top-left-radius: 4px;
                border-top-right-radius: 4px;
            }
        }

        .MuiTabs-fixed {
            overflow-x: initial;
        }

        .MuiTabs-indicator {
            height: 0;
            background-color: #B8C6D6;
        }
    }
`

const StyledGrid = styled(Grid)`
    && {
        padding: 20px;
        margin-top: 48px;
        border: 1px solid #B8C6D6;
        border-top-right-radius: 4px;
        border-bottom-left-radius: 4px;
        border-bottom-right-radius: 4px;
        min-height: calc(100vh - 495px);
    }
`

const StyledButtonGrid = styled(Grid)`
    && { 
        padding: 0;
        margin-top: 45px
    }
`

class WizardStructure extends Component {

    constructor(props) {
        super(props);

        var {
            wizardState
        } = props;

        const stepProperties = wizardState.eventProperties[wizardState.currentStep - 1];

        var structuresArray = [] 
        wizardState.event.structures.forEach(struc => {
            if(struc.codEventProperty === stepProperties.codEventProperty) {
                structuresArray.push(struc)
            }
        })

        structuresArray.sort((a,b) => {
            return a.codStructure - b.codStructure
        })

        var stepStructure = structuresArray[0]
        let newEvent = false

        if (!stepStructure) {
            newEvent = true
            stepStructure = {
                isMain: true,
                codStructure: 0,
                codMaster: 0,
                codConnectionAction: wizardState.event.codConnectionAction,
                codConnectionType: wizardState.event.codConnectionType,
                codInstanceConnection: wizardState.event.codInstanceConnection,
                codEventProperty: stepProperties.codEventProperty,
                codInstance: Session().codInstance,
                indPosition: 1,
                structureDetails: [],
                structureExtras: []
            }

            wizardState.event.structures.push(stepStructure);
            props.setWizardState(wizardState);
            structuresArray.push(stepStructure)
        }

        this.props.setWizardStructureArray(structuresArray);
        this.props.setStepStructure(stepStructure)

        this.state = {
            stepExtras: {
                detailsExtras: [],
                structureExtras: []
            },
            stepAttributes: {},
            stepProperties,
            stepStructure,
            structureIn: stepProperties.desEventProperty === 'structure_in',
            treeData: [],
            selectedTab: 0,
            tabs: [],
            detailTabs: [],
            isLoading: true,
            structuresArray,
            attributeGroupsDetails: [],
            isStepAuxiliary: false,
            createAux: wizardState.event.codConnectionType === (5)  ? true : wizardState.event.codConnectionType === (2) ? true : false,
            isStructureChangeTab: false,
            newEvent,
            dialog: { open: false },
            removeStructore: false,
            ...props
        }
    }

    setChangedDetail(action, sourceDetail) {
        let { wizardState } = this.state;

        if (sourceDetail.idStructureDetail) {
            let changedDetail = wizardState.event.changedDetails[sourceDetail.path];
            if (!changedDetail) {
                changedDetail = {};
                wizardState.event.changedDetails[sourceDetail.path] = changedDetail;
            }

            changedDetail.action = action;
            changedDetail.changes = {
                title: sourceDetail.title,
                codStructureDetail: sourceDetail.codStructureDetail,
                codMaster: sourceDetail.codMaster,
                indPosition: sourceDetail.indPosition
            };
        }
    }

    setChangedField(action, sourceField) {
        let { wizardState } = this.state;

        if (sourceField.codField) {
            let changedField = wizardState.event.changedFields[sourceField.path];
            if (!changedField) {
                changedField = {};
                wizardState.event.changedFields[sourceField.path] = changedField;
            }

            changedField.action = action;
            changedField.changes = {
                desField: sourceField.desField,
                indPosition: sourceField.indPosition,
                fieldAttributes: sourceField.fieldAttributes.map(attribute => {
                    return {
                        codAttribute: attribute.codAttribute,
                        desValue: attribute.desValue,
                        desLabel: attribute.desLabel,
                        indPosition: attribute.indPosition
                    }
                })
            };
        }
    }

    getAttributes = (
        stepExtras,
        attributeGroups,
        structureModel
    ) => {

        var { stepAttributes, detailTabs } = this.state;

        if (stepExtras.structureExtras.length && !this.props.isStepAuxiliary && !this.props.isStructureChangeTab) {
            const tabIdx = detailTabs.length ? 1 : 0;
            detailTabs.push({
                label: Translate("structure_extra"),
                indPosition: tabIdx
            })
        }

        const idsDetails = stepExtras.detailsExtras.map(
            extra => extra.codAttribute
        );

        const idsStructure = stepExtras.structureExtras.map(
            extra => extra.codAttribute
        );

        WizardService
            .GetAttributesByIdArray(
                idsDetails.concat(idsStructure)
            )
            .then(attrs => {
                var masterAttrs = attrs.filter(attr => !attr.CodMaster);

                attrs.forEach(attr => {
                    if (attr.CodMaster) {
                        var master = masterAttrs.find(master =>
                            master.codAttribute === attr.CodMaster
                        )

                        if (master.options) {
                            master.options.push(attr);
                        } else {
                            master.options = [attr];
                        }
                    }
                });

                masterAttrs.sort((o1, o2) => o1.indPosition < o2.indPosition);

                stepAttributes.detailsExtrasAttributes = [];
                stepAttributes.structureExtrasAttributes = [];
                masterAttrs.forEach(attr => {
                    if (idsStructure.includes(attr.codAttribute)) {
                        stepAttributes.structureExtrasAttributes.push(attr);
                    } else {
                        stepAttributes.detailsExtrasAttributes.push(attr);
                    }
                });

                this.setState({
                    stepAttributes,
                    detailTabs,
                    attributeGroups,
                    stepExtras,
                    structureModel,
                    isLoading: false
                });
            });
    }

    handleDidMount = (selectedTabs) => {
        let {
            wizardState,
            stepProperties,
            tabs,
            structureModel,
            detailTabs,
            attributeGroupsDetails,
            structuresArray,
            stepStructure,
            structureIn,
            createAux
        } = this.state;

        structuresArray.sort((a,b) => {
            return a.codMaster - b.codMaster
        })

        stepStructure = structuresArray[selectedTabs]

        this.props.setStepStructure(stepStructure)

        stepStructure.structureDetails.length ? stepStructure.desOutputDetails = stepStructure.structureDetails[0].output : stepStructure.desOutputDetails = stepStructure.desOutputDetails

        var codConnectionMode;
        if (wizardState.event.instanceConnection) {
            codConnectionMode = wizardState.event.instanceConnection.codConnectionMode
        } else {
            codConnectionMode = 0;
        }

        WizardService.GetExtrasAndAttrGroupsByCodConnectionActionCodEventProp(
            wizardState.event.codConnectionAction,
            codConnectionMode,
            stepProperties.codProperty
        ).then((responses) => {
            const eventExtras = responses[0].json();
            const attributeGroups = responses[1].json();

            Promise.all([eventExtras, attributeGroups]).then(
                (jsons) => {
                    const eventExtras = jsons[0];

                    const stepExtras = {
                        detailsExtras: eventExtras.filter(extra => !extra.isHeader),
                        structureExtras: eventExtras.filter(extra => extra.isHeader)
                    }

                    const attributeGroups = jsons[1];

                    if(structureIn) {
                        if(structuresArray.length){
                            tabs.push({
                                id: "main",
                                label: "Estrutura Principal" ,
                                indPosition: 0
                            })
                        }
                        if (attributeGroups.length) {
                            detailTabs.push({
                                label: Translate("structure_detail"),
                                indPosition: 0
                            })
                        }
                        this.props.setIsStructureChangeTab(false)
                        this.props.setStepAuxiliary(false)
                    }

                    if(!this.props.isStructureChangeTab && !this.props.isStepAuxiliary && !structureIn) {
                        if(structuresArray.length){
                            structuresArray.forEach(struc => {
                                tabs.push({
                                    id: struc.codMaster === 0 || struc.codMaster === undefined ? "main" : "aux",
                                    label: struc.codMaster === 0 || struc.codMaster === undefined ? "Estrutura Principal" : "Estrutura Auxiliar",
                                    indPosition: struc.codMaster
                                })
                            })
                            if(createAux){
                                if(wizardState.event.codConnectionType !== 2 || wizardState.event.codConnectionType === 2 && (wizardState.event.codConnectionAction === 3 || wizardState.event.codConnectionAction === 2) ) {
                                    tabs.push({
                                        id: "add",
                                        label: "+",
                                        indPosition: tabs.length
                                    })
                                }
                            }
                        } 
                        if (attributeGroups.length) {
                            detailTabs.push({
                                label: Translate("structure_detail"),
                                indPosition: 0
                            })
                        }

                        tabs.sort((a,b) => {
                            return a.indPosition - b.indPosition
                        })

                    } 

                    attributeGroupsDetails = attributeGroups
                    this.setState({attributeGroupsDetails})
                    if (eventExtras.length) {
                        this.getAttributes(
                            stepExtras,
                            tabs,
                            attributeGroups,
                            structureModel
                        );
                    } else {
                        this.setState({
                            isLoading: false,
                            detailTabs,
                            attributeGroups,
                            tabs,
                            structureModel
                        })
                    }
                }
            )
        });

        this.setState({stepStructure})
    }

    componentDidMount = _ => {
        this.props.setIsStructureChangeTab(false)
        this.props.setStepAuxiliary(false)
        this.handleDidMount(0)
    }

    componentWillUnmount = _ => { 
        this.handleWillUnmount()
    }

    handleWillUnmount = _ => {
        let {
            wizardState,
            structureIn,
            stepProperties,
            idx,
            selectedTab
        } = this.state;
        let { treeData, stepStructure } = this.props

        // arrumar esse bloco
        wizardState.event.structures.forEach(struc => {
            if (struc.structureExtras) {
                struc.structureExtras.forEach(extra => {
                    extra.value = String(extra.value);
                });
            }
        });
        //

        const treeNodes = getFlatDataFromTree({
            treeData,
            getNodeKey: ({ treeIndex }) => {
                return treeIndex;
            },
            ignoreCollapsed: false
        })

        let newDetails = [];

        treeNodes.forEach(node => {
            const parentId = node.parentNode
                ? node.path[node.path.length - 2] + 1
                : 0,
                id = node.treeIndex + 1,
                title = node.node.title;

            let detail = stepStructure.structureDetails.find(detail => {
                if (node.node.idStructureDetail) {
                    return detail.idStructureDetail === node.node.idStructureDetail
                }
            })

            if (!detail) {
                detail = {
                    codStructureDetail: id,
                    indPosition: id,
                    codMaster: parentId,
                    fields: node.node.fields,
                    structureExtras: node.node.detailExtras,
                    idDetailInput: node.node.idDetailInput,
                    structureExtras: node.node.detailExtras,
                    codInstance: Session().codInstance,
                    title
                }
            } else {
                detail.codMaster = parentId
                detail.codStructureDetail = id;
                detail.indPosition = id;
                detail.title = title;
                detail.fields = node.node.fields;
                detail.structureExtras = node.node.detailExtras;
            }

            if (structureIn) {
                if (stepStructure.structureInInput) {
                    detail.input = stepStructure.structureInInput.output;
                }
            } else {
                detail.output = stepStructure.desOutputDetails || detail.output;
            }

            newDetails.push(detail);
        });

        stepStructure.structureDetails.filter((detail) => detail.idStructureDetail).forEach(
            (detail) => {
                if (!newDetails.find(nd => nd.idStructureDetail === detail.idStructureDetail)) {
                    wizardState.event.deletedDetails.push(detail.idStructureDetail);
                }
            }
        );

        stepStructure.structureDetails = newDetails;

        if(stepProperties.desEventProperty === 'structure_in'){
            idx = wizardState.event.structures.findIndex(struc =>
                struc.codEventProperty === stepProperties.codEventProperty
            )
        } else {
            _ !== undefined ? selectedTab = _ : null
            idx = wizardState.event.structures.findIndex(struc =>
                struc.codEventProperty === stepProperties.codEventProperty && struc.codMaster === selectedTab
            )
        }

        wizardState.event.structures.splice(idx, 1);
        wizardState.event.structures.push(stepStructure);
        treeData = []
        this.props.setTreeData(treeData)

        this.props.setWizardState({ event: wizardState.event })
    }

    handleChangeTab = (_, selectedTab) => {
        var{ selectedStructure, tabs, removeStructore } = this.state;
        if(!this.disabledStep() || removeStructore){
        
            if (selectedTab === this.state.selectedTab) {
                return;
            }

            this.props.setIsStructureChangeTab(true)
        
            selectedStructure = selectedTab
            this.props.setWizardSelectedStructure( selectedStructure )
        
            this.setState({ selectedTab, selectedStructure });
            if(tabs[selectedTab].id != "add") {
                this.handleDidMount(selectedTab)  

            }
        }
        else{
            Toast.error(Translate("finish_register_structure"))
        }
    }

    renderTabs = () => {
        const { tabs } = this.state
        return tabs.map(tab => (
            <Tab key={tab.label} label={tab.label} />
        ))
    }

    componentWillReceiveProps = props => {
        this.setState({
            wizardState: { ...props.wizardState }
        })
    }

    changePosition = (arr, from, to) => {
        arr.splice(to, 0, arr.splice(from, 1)[0]);
                    
        arr.map((tab, index)=>{
            return tab.indPosition = index
        })

        return arr;
    };

    addStructure = () => {
        const { tabs, selectedTab, structuresArray } = this.state
        let { treeData, wizardState } = this.state

        tabs.push({
            id: "aux",
            label: "Estrutura Auxiliar",
            indPosition: tabs[selectedTab].indPosition
        })

        let from = tabs[selectedTab].indPosition
        this.changePosition(tabs, from, tabs.length-1)
        
        let position = tabs.length-1 
        tabs[tabs.length-1].indPosition = position

        this.props.setStepAuxiliary(true)

        //this.handleWillUnmount(selectedTab -1)
        
        var structureAux = {
            isMain: false,
            codConnectionAction: structuresArray[0].codConnectionAction,
            codConnectionMode: structuresArray[0].codConnectionMode,
            codConnectionType: structuresArray[0].codConnectionType,
            codEventProperty: structuresArray[0].codEventProperty,
            codInstance: structuresArray[0].codInstance,
            codInstanceConnection: structuresArray[0].codInstanceConnection,
            codStructure: 0,
            codMaster: structuresArray.length,
            desInstanceConnection: structuresArray[0].desInstanceConnection,
            desName: "",
            desOutputDetails: "",
            indPosition: structuresArray.length + 1,
            structureDetails: [],
            structureExtras: []

        }

        structuresArray.push(structureAux);
        wizardState.event.structures.push(structureAux)
        this.props.setWizardState(wizardState);
        treeData = []

        this.props.setTreeData(treeData)
        this.setState({structuresArray, treeData, wizardState})
        
        this.handleDidMount(selectedTab)

    }

    renderContent = selectedTab => {
        const {
            attributes,
            attributeGroups,
            stepStructure,
            stepProperties,
            stepAttributes,
            wizardState,
            structureInInput,
            structureInputOptions,
            selectedInputFields,
            stepExtras,
            tabs,
            detailTabs,
            structuresArray,
            attributeGroupsDetails,
            treeData,
            newEvent
        } = this.state

        if (tabs[selectedTab].id === "main") {
            return (
                <Fragment>
                        <Grid container>
                            <WizardStructureTabs
                                stepAttributes={stepAttributes}
                                detailTabs={detailTabs}
                                structures={structuresArray}
                                structureName={tabs[selectedTab].label}
                                attributes={attributes}
                                stepExtras={stepExtras}
                                detailsExtrasAttributes={stepAttributes.detailsExtrasAttributes}
                                wizardState={wizardState}
                                stepStructure={stepStructure}
                                stepProperties={stepProperties}
                                attributeGroups={attributeGroups}
                                structureInInput={structureInInput}
                                structureInputOptions={structureInputOptions}
                                selectedInputFields={selectedInputFields}
                                // update={this.updateFromTabs.bind(this)}
                                setChangedField={this.setChangedField.bind(this)}
                                attributeGroupsDetails={attributeGroupsDetails}
                                treeData={treeData}
                                newEvent={newEvent}
                                renderEvent={this.render.bind(this)}
                            />
                        </Grid>
                </Fragment>
            )
        } else if (tabs[selectedTab].id === "aux") {
            return (
                <Fragment>
                        <Grid container>
                            <WizardStructureTabs
                                stepAttributes={stepAttributes}
                                detailTabs={detailTabs}
                                structures={structuresArray}
                                structureName={tabs[selectedTab].label}
                                attributes={attributes}
                                stepExtras={stepExtras}
                                detailsExtrasAttributes={stepAttributes.detailsExtrasAttributes}
                                wizardState={wizardState}
                                stepStructure={stepStructure}
                                stepProperties={stepProperties}
                                attributeGroups={attributeGroups}
                                structureInInput={structureInInput}
                                structureInputOptions={structureInputOptions}
                                selectedInputFields={selectedInputFields}
                                // update={this.updateFromTabs.bind(this)}
                                setChangedField={this.setChangedField.bind(this)}
                                attributeGroupsDetails={attributeGroupsDetails}
                                treeData={treeData}
                                newEvent={newEvent}
                                renderEvent={this.render.bind(this)}
                            />
                        </Grid>
                </Fragment>
            )
        } 
        else if(tabs[selectedTab].id === "add"){
            this.addStructure()
        }
    }
        
    disabledStep = _ => {
        const { stepProperties } = this.state
        let {wizardState} = this.state;
        let disableStep = false

        if(wizardState.event.codConnectionType !== 2 || wizardState.event.codConnectionType === 2 && wizardState.event.codConnectionAction === 3){
            if(stepProperties.codProperty == 3){
                if(this.state.stepStructure.structureDetails.length == 0 || this.state.stepStructure.structureDetails.length == undefined ){
                    disableStep = true
                }
    
                if(this.state.stepStructure.desOutputDetails == "" || this.state.stepStructure.desOutputDetails == undefined ){
                    disableStep = true
                }
            }
        }

        return disableStep;
    }

    removeStructure = () => {
        this.setState({
            dialog: {
                type: "danger",
                open: true,
                message: "remove_select_structure",
                title: "exclusion_confirmation",
                onClose: () => {
                    let { selectedTab, tabs, structuresArray, stepStructure, wizardState, stepProperties } = this.state

                    if(!stepStructure.isMain){

                        var indexTabs = tabs.findIndex(e => e.indPosition === selectedTab)
                        tabs.splice(indexTabs, 1)

                        structuresArray.splice(selectedTab, 1)

                        wizardState.event.deleteStructure = wizardState.event.deleteStructure.concat(stepStructure.codStructure)

                        if(stepStructure.structureDetails.length) {
                            wizardState.event.deletedDetails = stepStructure.structureDetails.map(e => {
                                return e.idStructureDetail
                            })
                        }

                        var idx = wizardState.event.structures.findIndex(struc =>
                            struc.codEventProperty === stepProperties.codEventProperty && struc.codMaster === selectedTab
                        )
                        wizardState.event.structures.splice(idx, 1);
                        this.state.removeStructore = true;
                        this.handleChangeTab("", 0)
                        this.state.removeStructore = false;
            
                        tabs.map((tab, index)=>{
                            return tab.indPosition = index
                        })
                    }

                    this.setState({
                        dialog: {
                            open: false
                        }
                    })
                },
                onDismiss: () => {
                    this.setState({
                        dialog: {
                            open: false
                        }
                    })
                }
            }
        })
    }

    render() {
        const { selectedTab, isLoading, wizardState, stepProperties } = this.state

        if (isLoading) {
            return (
                <Loading />
            )
        }

         const disabledStep = this.disabledStep();
         if (disabledStep !== wizardState.disabledStep) {
             this.props.setWizardState({ disabledStep })
         }

        return (
            <Fragment>
                <StyledTabs
                    value={selectedTab}
                    onChange={this.handleChangeTab.bind(this)}
                    variant="scrollable"
                    scrollButtons="auto"
                >
                    {this.renderTabs()}
                </StyledTabs>
                <StyledGrid container>
                    {this.renderContent(selectedTab)}
                    {
                        stepProperties.desEventProperty === 'structure_in' || selectedTab === 0
                        ? null 
                        : <StyledButtonGrid>
                             <StyledButton variant="contained" disabled={false} onClick={this.removeStructure.bind(this)}>{Translate("remove")}</StyledButton>        
                          </StyledButtonGrid>
                    }
                </StyledGrid>

                <ConectorDialogConfirmation dialogOptions={this.state.dialog} open={this.state.dialog.open} />
            </Fragment>
        )
    }

}

const mapDispatchToProps = dispatch => {
    return {
        setWizardState: wizardState => dispatch(
            setWizardState(wizardState)
        ),
        setStepStatus: (step, complete) => dispatch(setStepStatus(step, complete)),
        setWizardSelectedStructure: selectedStructure => dispatch(setWizardSelectedStructure(selectedStructure)),
        setWizardStructureArray: structuresArray => dispatch(setWizardStructureArray(structuresArray)),
        setStepAuxiliary: isStepAuxiliary => dispatch(setStepAuxiliary(isStepAuxiliary)),
        setIsStructureChangeTab: isStructureChangeTab => dispatch(setIsStructureChangeTab(isStructureChangeTab)),
        setStepStructure: stepStructure => dispatch(setStepStructure(stepStructure)),
        setTreeData: treeData => dispatch(setTreeData(treeData))
    }
}

const mapStateToProps = store => {
    const [inputs] = inputSelector.getInputs(store);

    return {
        inputs,
        wizardState: wizardStore.getWizardState(store),
        attributes: wizardStore.getAttributes(store),
        treeData: wizardStore.getTreeData(store),
        isStepAuxiliary: wizardStore.getStepAuxiliary(store),
        isStructureChangeTab: wizardStore.getIsStructureChangeTab(store),
        stepStructure: wizardStore.getStepStructure(store)
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(WizardStructure);
