import React from "react";
import styled from "styled-components";
import Typography from "@material-ui/core/Typography";
import Translate from "../../i18n/translate";

const EventNameStyle = styled(Typography)`
	&& {
		width: 100%;
		text-align: left;
		font-size: 1.5rem;
		display: inline-block;
		color: ${props => props.theme.default.content.internalPageTitle.textColor};

		&:not(.without-margin) {
			padding: 3px 0;
			margin-bottom: 20px;
		}
	}`

const EventTypeStyle = styled(Typography)`
	&& {
		width: 100%;
		text-align: left;
		font-size: 1.2rem;
		display: inline-block;
		color: ${props => props.theme.default.content.internalPageTitle.textColor};

		&:not(.without-margin) {
			padding: 3px 0;
			margin-bottom: 20px;
		}
	}`

export function EventName({title, className}) {
	return (<EventNameStyle className={className}>{Translate(title)}</EventNameStyle>)
}

export function EventType({title, className}) {
	return (<EventTypeStyle className={className}>{Translate(title)}</EventTypeStyle>)
}