import React, { Component, Fragment } from "react";
import { connect } from "react-redux";
import { Grid } from "@material-ui/core";
import { CSVLink } from 'react-csv';

import { setBreadcrumb } from "../../store/app-state/actions";
import Session from "../../utils/session-info"
import * as appStateReducer from "../../store/app-state/reducer";
import Translate from '../../i18n/translate'
import ConectorGrid from "../../components/conector-ui/conector-grid/conector-grid";
import ConectorSelect from "../../components/conector-ui/conector-select";
import Loading from "../../components/conector-ui/conector-loading";
import permissionsPanelService from "./permissions-panel-service";
import InternalPageTitle from "../../components/conector-ui/conector-internal-page-title"
import StyledButton from "../../components/conector-ui/conector-styled-components/styled-button";
import StyledPaper from "../../components/conector-ui/conector-styled-components/styled-paper";

class PermissionsPanel extends Component {
    constructor(props) {
        super(props);

        props.setBreadcrumb([{
			label: "administration"
		}, {
			label: "permissions_panel"
		}]);

        this.state = {
            loading: true,
            selectedCompanyID: Session().codCompany,
            companiesOptions: [],
            dataSourceAccess: [],
            gridOptions: {
				multiSelect: false,
                hideBtnNew: true,
                hideBtnEdit: true,
                hideBtnDelete: true,
                hideBtnCopy: true,
                sortField: "company",
				columns: [{
					title: "company",
					field: "des_company"
				},
                {
					title: "instance",
					field: "des_instance"
				},
                {
					title: "profile",
					field: "des_profile"
				},
                {
					title: "name",
					field: "des_name"
				},
                {
					title: "email",
					field: "des_email"
				},
                {
					title: "active",
					field: "is_active",
                    cellFilter: "boolean"
				}]
			}
        }
    }

    componentWillMount = () => {
        const { selectedCompanyID } = this.state

        permissionsPanelService
        .GetAllSystemCompanies()
        .then((dataSource) => {
            dataSource.unshift({ "id": 0, "label": "all" })
            this.setState({companiesOptions: dataSource})
        })

		this.getAccesses(selectedCompanyID);
	}

    getAccesses = codCompany => {
		permissionsPanelService
        .GetAccesses(codCompany)
        .then((dataSource) => {
            this.setState({
                dataSourceAccess: dataSource,
                loading: false
            });
        });
	}

    changeCompanyFilter = selectedObj => {
        this.setState({selectedCompanyID: selectedObj.id})
        this.getAccesses(selectedObj.id);
    }

    render = () => {
        const {
			loading,
            companiesOptions,
            selectedCompanyID,
            dataSourceAccess,
            gridOptions
        } = this.state

        if (loading) { return <Loading /> }

        return (<Fragment>
                    <InternalPageTitle title={Translate("permissions_panel")} />

                    <StyledPaper>
                        <Grid container={true} spacing={24} alignItems="center">
                            <Grid item xs={3}>
                                <ConectorSelect
                                    label={'company'}
                                    help={'company_select'}
                                    onChange={this.changeCompanyFilter}
                                    options={companiesOptions}
                                    value={companiesOptions.find(company => company.id === selectedCompanyID) || null}/>
                            </Grid>

                            <Grid item>
                                <CSVLink data={dataSourceAccess} filename={"permissions.csv"}>
                                    <StyledButton variant="contained">{Translate("downloadReport")}</StyledButton>
                                </CSVLink>
                            </Grid>
                        </Grid>

                        <Grid item xs={12}>
                            <ConectorGrid
                                gridOptions={gridOptions}
                                dataSource={dataSourceAccess}/>
                        </Grid>
                    </StyledPaper>
                </Fragment>)
    }
}

const mapStateToProps = store => {
	return {
		permission: appStateReducer.getUserPermissionByView(store)
	}
}

const mapDispatchToProps = (dispatch) => {
	return {
		setBreadcrumb: (steps) => dispatch(setBreadcrumb(steps))
	}
}

export default connect(mapStateToProps, mapDispatchToProps)(PermissionsPanel);