import React, { Component, Fragment } from 'react';
import styled from 'styled-components';
import StyledSelect from './conector-styled-components/styled-select';
import StyledInputLabel from './conector-styled-components/styled-input-label';
import StyledFormHelpText from './conector-styled-components/styled-form-help-text';
import FormControl from '@material-ui/core/FormControl';
import Translate from '../../i18n/translate';

const StyledFormControl = styled(FormControl)`
  && { 
    width: ${props => props.width || '100%'};
    
    .error {
      border-color: ${props => props.theme.default.content.input.borderColorError};
    }
  }
`;

class ConectorSelect extends Component {
  constructor(props) {
    super(props);
    this.state = {
      ...props
    };
  }

  componentWillReceiveProps(props) {
    this.setState({ ...props });
  }

  handleChange = e => {
    if (this.state.onChange) {
      let changeParam = {
        ...e,
        name: this.state.name
      };

      this.state.onChange(changeParam);
    }
  };

  render() {
    var {
      required,
      className,
      options,
      invisible,
      label,
      name,
      placeholder,
      disabled,
      value,
      help,
      errorCondition,
      width // Adiciona a prop width
    } = this.state;

    invisible = Boolean(invisible);
    errorCondition = Boolean(errorCondition);
    required = Boolean(required);

    if (invisible) {
      return <Fragment></Fragment>;
    }

    if (options.length) {
      options.forEach(option => option.label = Translate(option.label));
    }

    if (errorCondition) {
      className = `${className || ''} error`;
    }

    if (required) {
      className = `${className || ''} required`;
    }

    return (
      <Fragment>
        <StyledFormControl width={width}>
          <StyledInputLabel className={className}>{Translate(label)}</StyledInputLabel>
          <StyledSelect
            select
            required={required}
            id={`conector-input-select-${name}`}
            classNamePrefix="select-default"
            name={name}
            placeholder={Translate(placeholder)}
            onChange={this.handleChange.bind(this)}
            isDisabled={disabled}
            options={options}
            value={value}
            className={className}
          >
          </StyledSelect>
          <StyledFormHelpText style={!help ? { display: 'none' } : {}}>
            {Translate(help)}
          </StyledFormHelpText>
        </StyledFormControl>
      </Fragment>
    );
  }
}

export default ConectorSelect;
