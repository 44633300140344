import React, {
    Component,
    Fragment
} from "react";
import { connect } from "react-redux";

import Toast from "../../components/toast/toast";
import Loading from "../../components/conector-ui/conector-loading";
import ConectorGrid from "../../components/conector-ui/conector-grid/conector-grid";
import ConectorDialogConfirmation from "../../components/conector-ui/conector-dialog-confirmation";

import { setBreadcrumb } from "../../store/app-state/actions";
import * as appStateReducer from "../../store/app-state/reducer";

import AccessReleasesService from "./access-releases-service";
import { setDayWithOptions } from "date-fns/fp";

class Permissions extends Component {

	constructor(props) {
		super(props);

		props.setBreadcrumb([{
            label: "registrations"
        }, {
            label: "accessrelease"
        }]);

		this.state = {
			loading: true,
			dataSource: [],
			profilesList: [],
			dialog: {
				open: false
			},
			gridOptions: {
				multiSelect: true,
				hideBtnCopy: true,
				columns: [{
                    title: "name",
                    field: "User.desName"
                }, {
                    title: "profile",
                    field: "profileDescription"
                }, {
                    title: "default",
                    field: "IsDefault",
                    cellFilter: "boolean"
                }]
			},
			accessReleasesServiceRequestCompleted: false
		}
	}

	componentWillMount() {
		this.getData();
		this.getProfiles();
	}

	async componentDidMount() {
        await this.getData();
        await this.getProfiles();
        await this.updateButtonVisibility();
    }

	async updateButtonVisibility() {
        const res = await AccessReleasesService.GetUserPermissionAcessRelease();
        const { gridOptions } = this.state;
        const updatedGridOptions = {
            ...gridOptions,
            hideBtnNew: !res,
            hideBtnEdit: !res,
            hideBtnDelete: !res
        };

        this.setState({
            gridOptions: updatedGridOptions,
            accessReleasesServiceRequestCompleted: true,
        });
    }
	

	getData() {
		AccessReleasesService.GetAccessReleases().then(dataSource => {
            this.setState({
                dataSource,
                loading: false
            });
        });
	}

	getProfiles() {
		AccessReleasesService.GetProfiles().then(profilesList => {
            this.setState({ profilesList });
        });
	}

	gridNewItem() {
		window.location.replace('#/accessrelease/add')
	}

	gridEditItem([row]) {
		if (!row) {
			return;
		} else if (row.IsDefault) {
			Toast.error("not_possible_edit_company");
			return;
        }

		window.location.replace(`#/accessrelease/${row.codUser}&${row.codProfile}`)
	}

	gridDeleteItem(permission) {
		this.setState({
			dialog: {
				type: "danger",
				open: true,
				message: "remove_records",
				title: "exclusion_confirmation",
				onClose: () => {
					this.confirmDelete(permission)
				}
			}
		})
	}

	confirmDelete(permission) {
		const listToDelete = permission.map(item => ({
			CodProfile: item.codProfile,
			CodUser: item.codUser
		}))

		AccessReleasesService
			.DeleteAccessReleases(listToDelete)
			.then(res => res.json())
			.then(({ msg }) => {
				msg ? Toast.error(msg) : Toast.success("records_success_removed");
				this.getData();
			});

		this.setState({
			dialog: { open: false }
		});
	}

	render() {
		var {
			loading,
			gridOptions,
			dialog,
            dataSource,
			profilesList,
			accessReleasesServiceRequestCompleted 
		} = this.state;

		if (loading && !accessReleasesServiceRequestCompleted) return <Loading />;

		if (profilesList.length) {
			dataSource.forEach(item => {
				let profile = profilesList.find(pf => pf.codProfile === item.codProfile)
				if(profile != undefined) item.profileDescription = profile.description;
			});
		}

		return (
			<Fragment>
				<ConectorGrid
					gridTitle={"accessrelease"}
					gridBtn={"set_up_new_access"}
					gridNewItem={this.gridNewItem.bind(this)}
					gridEditItem={this.gridEditItem.bind(this)}
					gridDeleteItem={this.gridDeleteItem.bind(this)}
					gridOptions={gridOptions}
					dataSource={dataSource}>
				</ConectorGrid>
				<ConectorDialogConfirmation dialogOptions={dialog} />
			</Fragment>
		);
    }

}

const mapStateToProps = store => {
	return {
		permission: appStateReducer.getUserPermissionByView(store)
	}
}

const mapDispatchToProps = (dispatch) => {
	return {
		setBreadcrumb: (steps) => dispatch(setBreadcrumb(steps))
	}
}

export default connect(mapStateToProps, mapDispatchToProps)(Permissions);