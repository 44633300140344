import React, { Component, Fragment } from 'react'
import ModalVersion from '../generic/modalVersion'
import Typography from '@material-ui/core/Typography'
import styled from 'styled-components'
import Link from '@material-ui/core/Link';
import version from "../generic/version";

const ColoredBold = styled.strong`
    && {
        color: #ffffff !important;
    }
`

const StyledVersion = styled.label `

	& {
        font-size: 20px;
        font-family: "Roboto", "Helvetica", "Arial", sans-serif;
        padding-bottom: 10px;
        color: #6f8296;
	}

`;

class Button extends Component {

    constructor(props) {
        super(props)  

        this.state = {
            ...props.appState,
            openVersionModal: false,
            versions: "",
            vInfos: []
        }
    }
    async componentWillMount() {
        const { system } = this.props;
        const lastVersion = await version.getVersion(system)
        this.setState({ versions: lastVersion });
        
    }
    handleOpenModal = () => {
        this.setState({ openVersionModal: true });
    }

    handleDismissModal = () => {
        this.setState({ openVersionModal: false });
    }

    componentWillReceiveProps({ appState }) {
        this.setState({ ...appState });
    }
    getSystemName = (system) => {
        switch (system) {
            case "Portal":
                return "Portal"
            case "Worker":
                return "Worker"
            case "MicroBack":
                return "Microserviço de Backend"
            case "MicroFront":
                return "Microserviço de Frontend"
            case "MicroDash":
                return "Microserviço de Dash"
            default:
                break;
        }
    }

    render() {

        const { openVersionModal } = this.state;
        const { system } = this.props;
        const {versions} = this.state;
        var vatual = versions

        return (
            <Fragment>
                <div className={'btnVersion'} onClick={this.handleOpenModal}>
                    <div>
                        <Typography><StyledVersion><Link color="inherit">
                            {this.getSystemName(system) +": "+ vatual}
                        </Link></StyledVersion></Typography>
                    </div>
                </div>

                {
                    (() => {
                        if (openVersionModal) {
                            return <ModalVersion  system={system} onDismiss={this.handleDismissModal.bind(this)} />
                        }
                    })()
                }
            </Fragment>
        )
    }
}

export default Button