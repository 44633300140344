import React, { Fragment, Component } from 'react'
import ConectorGridTable from './conector-grid-table'
import ConectorGridToolBar from './conector-grid-toolbar'

/*

Default Values
-----------------------

headerOptions: 

hideFilter    = false
hideBtnNew    = false
hideBtnEdit   = false
hideBtnDelete = false
hideBtnCopy   = false
hideTitle     = false

----

gridOptions:

sortable      = true,
multiSelect   = true,

*/

class ConectorGrid extends Component {
    
    constructor(props) {
        super(props);
        
        this.state = {
            headerOptions: {
                gridTitle       : props.gridTitle,
                hideTopDiv      : props.hideTopDiv,
                hideToolbar     : props.hideToolbar,
                gridBtn         : props.gridBtn,
                gridNewItem     : props.gridNewItem,
                gridEditItem    : props.gridEditItem,
                gridDeleteItem  : props.gridDeleteItem,
                gridCopyItem    : props.gridCopyItem,
                hideFilter      : Boolean(props.gridOptions.hideFilter),
                hideBtnNew      : Boolean(props.gridOptions.hideBtnNew),
                hideBtnEdit     : Boolean(props.gridOptions.hideBtnEdit),
                hideBtnDelete   : Boolean(props.gridOptions.hideBtnDelete),
                hideBtnCopy     : Boolean(props.gridOptions.hideBtnCopy),
                hideTitle       : Boolean(props.gridOptions.hideTitle)
            },
            gridOptions: {
                sortable    : Boolean(props.gridOptions.sortable),
                multiSelect : Boolean(props.gridOptions.multiSelect),
                ...props.gridOptions,
                filter: {}
            },
            dataSource  : props.dataSource
        }
    }

    componentWillReceiveProps(props) {
        let state = {...this.state};
        state.dataSource = props.dataSource;
        state.headerOptions = {
            gridBtn       : props.gridBtn,
            gridDeleteItem: props.gridDeleteItem,
            gridEditItem  : props.gridEditItem,
            gridTitle     : props.gridTitle,
            gridNewItem   : props.gridNewItem,
            gridCopyItem  : props.gridCopyItem,
            hideTopDiv    : props.hideTopDiv,
            hideFilter    : Boolean(props.gridOptions.hideFilter),
            hideBtnNew    : Boolean(props.gridOptions.hideBtnNew),
            hideBtnEdit   : Boolean(props.gridOptions.hideBtnEdit),
            hideBtnDelete : Boolean(props.gridOptions.hideBtnDelete),
            hideBtnCopy   : Boolean(props.gridOptions.hideBtnCopy),
            hideTitle     : Boolean(props.gridOptions.hideTitle)
        }
        if (props.gridOptions && props.gridOptions.selectedRow) {
            state.gridOptions.selectedRow = props.gridOptions.selectedRow
        }

        this.setState(state);
    }

    refresh = (gridState) => {
        this.setState({...this.state, ...gridState}, _ => {
            if (this.props.connectSelectedRows) {
                const { gridOptions } = this.state;
                const data = gridOptions.multiSelect
                    ? gridOptions.selectedRows
                    : gridOptions.selectedRow
                if (data) {
                    this.props.connectSelectedRows(data);
                }
            }
        })
    }

    getGridOptions = () => {
        return {
            ...this.state.gridOptions, 
            refresh: (stateGridOptions) => {
                this.refresh(stateGridOptions)
            }
        }
    }

    render() {
        const { headerOptions, dataSource } = this.state;

        return (
            <Fragment>
                {
                    headerOptions.hideToolbar 
                        ? ( null )
                        : ( 
                            <ConectorGridToolBar 
                                headerOptions={headerOptions}
                                gridOptions={this.getGridOptions()}>
                            </ConectorGridToolBar>
                        )
                }
                
                <ConectorGridTable
                    gridOptions={this.getGridOptions()} 
                    dataSource={dataSource}>
                </ConectorGridTable>
                
            </Fragment>
        )
    }
}

export default ConectorGrid