import styled from 'styled-components'
import TextField from '@material-ui/core/TextField'

const StyledTextField = styled(TextField)`
    && {
        width: 100%;

        .MuiOutlinedInput-root:hover:not(.MuiOutlinedInput-disabled):not(.MuiOutlinedInput-focused):not(.MuiOutlinedInput-error) .MuiOutlinedInput-notchedOutline { 
            border-color: ${props => props.theme.default.content.input.borderColor};
        }
        
        .MuiOutlinedInput-disabled { 
            background-color: ${props => props.theme.default.content.input.bgColorDisabled};
            /* opacity: 0.8; */ 
        }
        
        fieldset { 
            border-color: ${props => props.theme.default.content.input.borderColor};
            border-width: 1px;
            .MuiOutlinedInput-notchedOutline { 
                border-color: ${props => props.theme.default.content.input.borderColor};
                border-width: 1px; 
            }
        }

        .MuiInputBase-error {
            fieldset { border-color: ${props => props.theme.default.content.input.borderColorError}; }
        }

        svg, span.material-icons { 
            color: ${props => props.theme.default.content.input.svg.color};
            font-size: 20px; 

            &.sentiment_dissatisfied {
                font-size: 22px;
                color: #CD5C5C;
            }

            &.sentiment_satisfied {
                font-size: 22px;
                color: #FFC34B;
            }

            &.sentiment_very_satisfied {
                font-size: 22px;
                color: #52B4B7;
            }
        }
        
        input { 
            padding: 11.5px 14px;
            color: ${props => props.theme.default.content.input.textColor};
            background-color: ${props => props.theme.default.content.input.bgColor};
            &:-webkit-autofill, 
            &:-webkit-autofill:hover,
            &:-webkit-autofill:focus,
            &:-webkit-autofill:active {
                box-shadow: 0 0 0px;
                -webkit-text-fill-color: ${props => props.theme.default.content.input.textColorWebkitAutofill} !important;
                transition: background-color 5000s ease-in-out 0s !important;
            }
        }
        
        p {
            &.MuiFormHelperText-root {
                font-weight: normal;
                color: ${props => props.theme.default.content.input.helperText.textColor};
                text-align: left;
                font-size: 0.8rem;
                margin-bottom: 5px;
                margin-left: 0;
                .MuiFormHelperText-error { 
                    color: ${props => props.theme.default.content.input.helperText.textColorError};
                }
            }
        }

        .MuiInputAdornment-positionStart { 
            margin-right: 0;
            &.noBtn ~ .MuiOutlinedInput-inputAdornedStart {
                padding: 11.5px 14px;
            }
        }

        .MuiOutlinedInput-adornedEnd { padding-right: 1px; }
        .MuiInputAdornment-positionEnd { margin-left: 0; }
        
        .MuiInputAdornment-root {
            .separator {
                background-color: #B8C6D6;
                width: 1px;
                height: 40px;
                box-sizing: border-box;
            }

            .divButton {
                min-height: 40px;
                display: flex;
                justify-content: center;
                align-items: center;
                cursor: default;
            }
            
            button {
                border-radius: 0;   
                padding: 0;
                min-height: 40px;
                min-width: 60px;
                svg { color: ${props => props.theme.default.content.input.button.textColor}; }
                &:hover { background-color: ${props => props.theme.default.content.input.button.bgColorHover}; }
            }
        }

        &.error {
            fieldset { border-color: ${props => props.theme.default.content.input.borderColorError}; }
            p { &.MuiFormHelperText-root { color: ${props => props.theme.default.content.input.textColorError}; }}
        }
        
        &.input-condition-success {
            fieldset { border-color: ${props => props.theme.default.content.input.borderColorSuccess}; }
            p { &.MuiFormHelperText-root { color: ${props => props.theme.default.content.input.textColorSuccess}; }}
        }
        
        &.input-condition-warning {
            fieldset { border-color: ${props => props.theme.default.content.input.borderColorWarning}; }
            p { &.MuiFormHelperText-root { color: ${props => props.theme.default.content.input.textColorWarning}; }}
        }

        &.hidden { display: none; }
    }
`

export default StyledTextField