export default {
    getColors: (ColorsTheme) => {
        return {
            header : {
                bgColor : ColorsTheme.white,
                borderColor : ColorsTheme.pastelBlue,
                menuButton : ColorsTheme.slateGray,
                iconButton : { svgColor : ColorsTheme.slateGray },
                button : {
                    bgColor : ColorsTheme.whiteSmoke,
                    bgColorHover : ColorsTheme.isabelline,
                    borderColor : ColorsTheme.pastelBlue,
                    textColor : ColorsTheme.pastelBlue,
                    textColorStrong : ColorsTheme.slateGray,
                }
            },
            sidebar : {
                bgColor : ColorsTheme.paynesGrey,
                borderColor : ColorsTheme.pastelBlue,
                logo : {
                    bgColor : ColorsTheme.deepKoamaru,
                    borderColor : ColorsTheme.pastelBlue,
                    textColor : ColorsTheme.white,
                    button : {
                        color : ColorsTheme.pastelBlue,
                        bgColor : ColorsTheme.slateGray
                    }
                },
                menuList : {
                    borderColor : ColorsTheme.pastelBlue,
                    svgColor : ColorsTheme.pastelBlue,
                    bgButtonActiveHover : ColorsTheme.slateGray,
                    subMenuList : {
                        bgColor : ColorsTheme.uclaBlue,
                        activeBgColor : ColorsTheme.vividCerulean,
                        activeSvgColor : ColorsTheme.white
                    }
                }
            },
            breadcrumb : {
                bgColor : ColorsTheme.whiteSmoke,
                borderColor : ColorsTheme.pastelBlue,
                textColor : ColorsTheme.pastelBlue,
                textColorHover : ColorsTheme.vividCerulean,
                textColorLastChild : ColorsTheme.slateGray
            },
            content : {
                pageTitle : { textColor : ColorsTheme.slateGray },
                internalPageTitle : { textColor : ColorsTheme.slateGray },
                loading : { color : ColorsTheme.vividCerulean },
                paper : {
                    bgColor : ColorsTheme.white,
                    borderColor : ColorsTheme.pastelBlue
                },
                button : {
                    bgColor : ColorsTheme.isabelline,
                    borderColor : ColorsTheme.pastelBlue,
                    textColor : ColorsTheme.slateGray,
                    disabledBorderColor : ColorsTheme.pastelBlue
                },
                iconButton : {
                    iconColor : ColorsTheme.vividCerulean,
                    iconColorHover : ColorsTheme.vividCerulean,
                    bgColorHover : ColorsTheme.pastelBlue,
                    iconColorDefault : ColorsTheme.slateGray,
                    iconColorSave : ColorsTheme.vividCerulean,
                    iconColorCancel : ColorsTheme.paynesGrey,
                },
                highlightButton : {
                    bgColor : ColorsTheme.vividCerulean,
                    borderColor : ColorsTheme.vividCerulean,
                    textColor : ColorsTheme.white
                },
                input : {
                    borderColor : ColorsTheme.pastelBlue,
                    borderColorSuccess : ColorsTheme.verdigris,
                    borderColorWarning : ColorsTheme.pastelOrange,
                    borderColorError : ColorsTheme.indianRed,
                    textColor : ColorsTheme.slateGray,
                    textColorSuccess : ColorsTheme.verdigris,
                    textColorWarning : ColorsTheme.pastelOrange,
                    textColorError : ColorsTheme.indianRed,
                    textColorWebkitAutofill : ColorsTheme.vividCerulean,
                    bgColor : ColorsTheme.white,
                    bgColorDisabled : ColorsTheme.isabelline,
                    svg : { color : ColorsTheme.pastelBlue },
                    label : { textColor : ColorsTheme.slateGray },
                    button : { 
                        textColor : ColorsTheme.pastelBlue,
                        bgColorHover : ColorsTheme.isabelline,
                    },
                    helperText : { 
                        textColor : ColorsTheme.pastelBlue,
                        textColorError : ColorsTheme.indianRed
                    }
                },
                inputDatePicker : {
                    textColor : ColorsTheme.slateGray,
                    borderColor : ColorsTheme.pastelBlue,
                    fieldset : { borderColor : ColorsTheme.pastelBlue },
                    button : {
                        textColor : ColorsTheme.slateGray,
                        bgColorHover : ColorsTheme.isabelline,
                    }
                },
                select : {
                    bgColor : ColorsTheme.isabelline,
                    borderColor : ColorsTheme.pastelBlue,
                    borderColorHover : ColorsTheme.pastelBlue,
                    borderColorError : ColorsTheme.indianRed,
                    borderColorWarning : ColorsTheme.pastelOrange,
                    borderColorSuccess : ColorsTheme.verdigris,
                    textColorError : ColorsTheme.indianRed,
                    textColorWarning : ColorsTheme.pastelOrange,
                    textColorSuccess : ColorsTheme.verdigris,
                    
                    /* Multi Value */
                    bgColorMultiValue : ColorsTheme.whiteSmoke,
                    textColorMultiValue : ColorsTheme.slateGray,
                    bgColorMultiValueRemoveHover : ColorsTheme.vividCerulean,
                    svgMultiValueRemoveHover : ColorsTheme.white,
                    
                    /* Option */
                    textColorOption : ColorsTheme.slateGray,
                    textColorOptionHover : ColorsTheme.white,
                    textColorOptionSelected : ColorsTheme.slateGray,
                    bgColorOptionHover : ColorsTheme.vividCerulean,
                    bgColorOptionFocused : ColorsTheme.white,
                    bgColorOptionSelected : ColorsTheme.isabelline
                },
                stepper : {
                    svg : { 
                        color : ColorsTheme.slateGray,
                        activeColor : ColorsTheme.vividCerulean,
                        textColor : ColorsTheme.white
                    },
                    stepConnector : { 
                        bgColor : ColorsTheme.pastelBlue,
                        borderColor : ColorsTheme.pastelBlue,
                        activeBgColor : ColorsTheme.vividCerulean,
                        activeBorderColor : ColorsTheme.vividCerulean,
                        completedBgColor : ColorsTheme.vividCerulean,
                        completedBorderColor : ColorsTheme.vividCerulean
                    },
                    stepLabel : {
                        textColor : ColorsTheme.slateGray,
                        disableTextColor : ColorsTheme.slateGray,
                        activeTextColor : ColorsTheme.vividCerulean,
                        completedTextColor : ColorsTheme.vividCerulean
                    },
                    button : {
                        svgColor : ColorsTheme.paynesGrey,
                        disableSvgColor : ColorsTheme.slateGray,
                        activeSvgColor : ColorsTheme.vividCerulean,
                        completedSvgColor : ColorsTheme.vividCerulean
                    }
                },
                chipInterface : {
                    textColor : ColorsTheme.slateGray,
                    borderColor : ColorsTheme.pastelBlue,
                    bgColor : ColorsTheme.whiteSmoke,
                    bgColorHover : ColorsTheme.isabelline
                }
            },
            dialogConfirmation : {
                title : {
                    bgColorWarning : ColorsTheme.pastelOrange,
                    bgColorSuccess : ColorsTheme.verdigris,
                    bgColorError : ColorsTheme.indianRed,
                    bgColorInfo: ColorsTheme.infoBlue,
                    textColor : ColorsTheme.white
                }
            },
            modal : {
                bgColor : ColorsTheme.white,
                title : {
                    bgColor : ColorsTheme.paynesGrey,
                    textColor : ColorsTheme.pastelBlue
                }
            },
            toast : {
                textColor : ColorsTheme.white,
                bgColor : ColorsTheme.vividCerulean,
                bgColorError : ColorsTheme.indianRed,
                bgColorSuccess : ColorsTheme.verdigris,
                bgColorWarning : ColorsTheme.pastelOrange
            }
        }
    }
}