import { connect } from "react-redux";
import './../../themes/react-tagsinput.css';
import styled from "styled-components";
import React, {
    Component,
    Fragment
} from "react";
import TagsInput from 'react-tagsinput'
import './../../themes/react-tagsinput.css'

import Translate from "../../i18n/translate";

import Grid from "@material-ui/core/Grid";

import moment from "moment";

import Session from "../../utils/session-info";

import { setBreadcrumb } from "../../store/app-state/actions";

import Toast from "../../components/toast/toast";
import Loading from "../../components/conector-ui/conector-breadcrumb";
import ConectorSelect from "../../components/conector-ui/conector-select";
import ConectorInputText from "../../components/conector-ui/conector-input-text";
import ConectorDatePicker from "../../components/conector-ui/conector-date-picker";
import ConectorInputNumber from "../../components/conector-ui/conector-input-number";
import ConectorCheckbox from "../../components/conector-ui/conector-checkbox";
import InternalPageTitle from "../../components/conector-ui/conector-internal-page-title";
import StyledPaper from "../../components/conector-ui/conector-styled-components/styled-paper";
import StyledButton from "../../components/conector-ui/conector-styled-components/styled-button";
import StyledFormHelpText from "../../components/conector-ui/conector-styled-components/styled-form-help-text";

import InterfacesService from "./interfaces-service";
import TemplateHistory from "./template-history/template-history-interface"
import InterfaceHistory from "./interface-history/interface-history"
import { bool } from "prop-types";
import UserService from "../../routes/users/user-service";

const ConectorIntervalLabel = styled(StyledFormHelpText)`
    
    && {
        margin-top: -40px;
    }
    
`;

class InterfacesDetails extends Component {

    constructor(props) {
        super(props);

        let interfaceID = window.location.hash.substring(23)

        props.setBreadcrumb([{
            label: "registrations"
        }, {
            label: "interfaces",
            url: "#/interfaces"
        }, {
            label: Translate(interfaceID === "add" ? "add_interface" : "edit_interface")
        }]);

        this.state = {
            dialogOpenTemplateHistory: false,
            dialogOpeninterfaceHistory:false,
            interfaceID: interfaceID,
            object: {
                description: "",
                indDelay: 1,
                codInterfaceMode: 2,
                datLastExecution: new Date(),
                datSync: new Date(),
                indMaxInterval: 180,
                datStartMaxInterval: new Date(),
                datEndMaxInterval: new Date(),
                email: "",
                warningEmail: true,
                datSchedule: new Date(),
                timeOffset: "0",
                stoppedBy: Session().codUser
            },
            isReady: false,
            editPermission:false,
            schedules: [],
            timeOffsets: [{
                value: "-12",
                label: "−12:00"
            }, {
                value: "-11,45",
                label: "−11:45"
            }, {
                value: "-11,30",
                label: "−11:30"
            }, {
                value: "-11,15",
                label: "−11:15"
            }, {
                value: "-11",
                label: "−11:00"
            }, {
                value: "-10,45",
                label: "−10:45"
            }, {
                value: "-10,30",
                label: "−10:30"
            }, {
                value: "-10,15",
                label: "−10:15"
            }, {
                value: "-10",
                label: "−10:00"
            }, {
                value: "-9,45",
                label: "−09:45"
            }, {
                value: "-9,30",
                label: "−09:30"
            }, {
                value: "-9,15",
                label: "−09:15"
            }, {
                value: "-9",
                label: "−09:00"
            }, {
                value: "-8,45",
                label: "−08:45"
            }, {
                value: "-8,30",
                label: "−08:30"
            }, {
                value: "-8,15",
                label: "−08:15"
            }, {
                value: "-8",
                label: "−08:00"
            }, {
                value: "-7,45",
                label: "−07:45"
            }, {
                value: "-7,30",
                label: "−07:30"
            }, {
                value: "-7,15",
                label: "−07:15"
            }, {
                value: "-7",
                label: "−07:00"
            }, {
                value: "-6,45",
                label: "−06:45"
            }, {
                value: "-6,30",
                label: "−06:30"
            }, {
                value: "-6,15",
                label: "−06:15"
            }, {
                value: "-6",
                label: "−06:00"
            }, {
                value: "-5,45",
                label: "−05:45"
            }, {
                value: "-5,30",
                label: "−05:30"
            }, {
                value: "-5,15",
                label: "−05:15"
            }, {
                value: "-5",
                label: "−05:00"
            }, {
                value: "-4,45",
                label: "−04:45"
            }, {
                value: "-4,30",
                label: "−04:30"
            }, {
                value: "-4,15",
                label: "−04:15"
            }, {
                value: "-4",
                label: "−04:00"
            }, {
                value: "-3,45",
                label: "−03:45"
            }, {
                value: "-3,30",
                label: "−03:30"
            }, {
                value: "-3,15",
                label: "−03:15"
            }, {
                value: "-3",
                label: "−03:00"
            }, {
                value: "-2,45",
                label: "−02:45"
            }, {
                value: "-2,30",
                label: "−02:30"
            }, {
                value: "-2,15",
                label: "−02:15"
            }, {
                value: "-2",
                label: "−02:00"
            }, {
                value: "-1,45",
                label: "−01:45"
            }, {
                value: "-1,30",
                label: "−01:30"
            }, {
                value: "-1,15",
                label: "−01:15"
            }, {
                value: "-1",
                label: "−01:00"
            }, {
                value: "-0,45",
                label: "-00:45"
            }, {
                value: "-0,30",
                label: "-00:30"
            }, {
                value: "-0,15",
                label: "-00:15"
            }, {
                value: "0",
                label: "00:00"
            }, {
                value: "+0,15",
                label: "+00:15"
            }, {
                value: "+0,30",
                label: "+00:30"
            }, {
                value: "+0,45",
                label: "+00:45"
            }, {
                value: "+1",
                label: "+01:00"
            }, {
                value: "+1,15",
                label: "+01:15"
            }, {
                value: "+1,30",
                label: "+01:30"
            }, {
                value: "+1,45",
                label: "+01:45"
            }, {
                value: "+2",
                label: "+02:00"
            }, {
                value: "+2,15",
                label: "+02:15"
            }, {
                value: "+2,30",
                label: "+02:30"
            }, {
                value: "+2,45",
                label: "+02:45"
            }, {
                value: "+3",
                label: "+03:00"
            }, {
                value: "+3,15",
                label: "+03:15"
            }, {
                value: "+3,30",
                label: "+03:30"
            }, {
                value: "+3,45",
                label: "+03:45"
            }, {
                value: "+4",
                label: "+04:00"
            }, {
                value: "+4,15",
                label: "+04:15"
            }, {
                value: "+4,30",
                label: "+04:30"
            }, {
                value: "+4,45",
                label: "+04:45"
            }, {
                value: "+5",
                label: "+05:00"
            }, {
                value: "+5,15",
                label: "+05:15"
            }, {
                value: "+5,30",
                label: "+05:30"
            }, {
                value: "+5,45",
                label: "+05:45"
            }, {
                value: "+6",
                label: "+06:00"
            }, {
                value: "+6,15",
                label: "+06:15"
            }, {
                value: "+6,30",
                label: "+06:30"
            }, {
                value: "+6,45",
                label: "+06:45"
            }, {
                value: "+7",
                label: "+07:00"
            }, {
                value: "+7,15",
                label: "+07:15"
            }, {
                value: "+7,30",
                label: "+07:30"
            }, {
                value: "+7,45",
                label: "+07:45"
            }, {
                value: "+8",
                label: "+08:00"
            }, {
                value: "+8,15",
                label: "+08:15"
            }, {
                value: "+8,30",
                label: "+08:30"
            }, {
                value: "+8,45",
                label: "+08:45"
            }, {
                value: "+9",
                label: "+09:00"
            }, {
                value: "+9,15",
                label: "+09:15"
            }, {
                value: "+9,30",
                label: "+09:30"
            }, {
                value: "+9,45",
                label: "+09:45"
            }, {
                value: "+10",
                label: "+10:00"
            }, {
                value: "+10,15",
                label: "+10:15"
            }, {
                value: "+10,30",
                label: "+10:30"
            }, {
                value: "+10,45",
                label: "+10:45"
            }, {
                value: "+11",
                label: "+11:00"
            }, {
                value: "+11,15",
                label: "+11:15"
            }, {
                value: "+11,30",
                label: "+11:30"
            }, {
                value: "+11,45",
                label: "+11:45"
            }, {
                value: "+12",
                label: "+12:00"
            }]
        }
    }

    componentDidMount = async () => {
        const hasPrivilege = await UserService.FindPrivilege(Session().codUser, 1);
        this.setState({ hasPrivilege });

        const resEdit = await InterfacesService.GetUserPermissionEditInterface();
        const permissionEdit = resEdit
        this.setState({
            editPermission: permissionEdit,
        });
    }

    componentWillMount = _ => {
        let { object, interfaceID } = this.state;

        if (interfaceID !== "add") {
            InterfacesService.GetInterface(interfaceID).then((object) => {
                object = {
                    ...object,
                    timeOffset: object.timeOffset || "0",
                    ...this.initializeMaxIntervalDates(object.indMaxInterval)
                };

                this.setState({
                    object,
                    isReady: true,
                    title: Translate("edit_interface")
                }, () => {
                    this.getSchedules();
                    this.buildIntervalLabel();
                });
            }).catch(() => {
                Toast.error(Translate("error_fetching_interface"));
            });
        } else {
            object = {
                ...object,
                ...this.initializeMaxIntervalDates(object.indMaxInterval)
            };

            this.setState({
                object,
                isReady: true,
                title: Translate("add_interface")
            }, () => {
                this.getSchedules();
                this.buildIntervalLabel();
            })
        }
    }

    initializeMaxIntervalDates = (indMaxInterval) => {
        let currentDate = new Date();
        currentDate.setHours(0, 0, 0, 0);

        return {
            datStartMaxInterval: currentDate,
            datEndMaxInterval: moment(currentDate).add(indMaxInterval, "seconds").toDate()
        }
    }

    getSchedules = _ => {
        var { object } = this.state;

        InterfacesService.GetSchedules().then(schedules => {
            schedules = schedules.map(schedule => {
                return {
                    value: schedule.codSchedule,
                    label: schedule.desSchedule
                }
            });

            if (!object.codSchedule && schedules.length) {
                object.codSchedule = schedules[0].value;
            }

            this.setState({
                schedules,
                object
            })
        });
    }

    handleChange = e => {
        let object = { ...this.state.object };
        object[e.target.name] = e.target.value;

        this.setState({ object });
    }

    handleChangeDate = e => {
        let object = { ...this.state.object };
        object[e.target.name] = e.target.value.replace("Z", "999Z");

        if (e.target.name === "datStartMaxInterval" || e.target.name === "datEndMaxInterval") {
            if (object.datStartMaxInterval && object.datEndMaxInterval) {
                const startMaxInterval = moment(object.datStartMaxInterval);
                const endMaxInterval = moment(object.datEndMaxInterval);
                const maxInterval = moment.duration(endMaxInterval.diff(startMaxInterval));
                object.indMaxInterval = Math.floor(maxInterval.asSeconds());
            }
        }

        this.setState({ object }, this.buildIntervalLabel);
    }

    buildIntervalLabel = () => {
        const {
            datStartMaxInterval,
            datEndMaxInterval,
            indMaxInterval
        } = this.state.object;

        let intervalLabel = [];

        if (indMaxInterval > 0) {
            const maxInterval = moment.duration(moment(datEndMaxInterval).diff(moment(datStartMaxInterval)));

            ["years", "months", "days", "hours", "minutes", "seconds"].map((fragment, index) => {
                const value = Math.floor(maxInterval[fragment]());

                if (value > 0) {
                    intervalLabel.push(`${value} ${Translate(`timeFlag_${index+1}`)}`)
                }
            });
        } else {
            intervalLabel.push(Translate("invalid_range"));
        }

        this.setState({
            intervalLabel: intervalLabel.join(", ")
        });
    }

    handleChangeInt = e => {
        let object = { ...this.state.object };
        object[e.target.name] = e.target.valueAsNumber;

        this.setState({ object });
    }

    handleChangeInterfaceMode = selectedObj => {
        let object = { ...this.state.object };
        object.codInterfaceMode = selectedObj.value;

        this.setState({ object });
    }

    handleChangeTimeOffset = selectedObj => {
        let object = { ...this.state.object };
        object.timeOffset = selectedObj.value;

        this.setState({ object });
    }

    handleChangeSchedule = selectedObj => {
        let object = { ...this.state.object }
        object.codSchedule = selectedObj.value;

        this.setState({ object });
    }

    handleChangeActive = e => {
        let object = { ...this.state.object }
        object.isActive = !(e.target.value === "true");

        this.setState({ object });
    }

    cancel = _ => {
        window.location.replace("#/interfaces")
    }

    save = _ => {
        let object = { ...this.state.object }

        if (object.codInstanceInterface) {
            InterfacesService.PutInterface(object).then(_ => {
                Toast.success(Translate("success_updated_interface"))
                window.location.replace("#/interfaces")
            }).catch(_ => {
                Toast.error(Translate("error_updating_interface"))
            })
        } else {
            object.codInstance = Session().codInstance;

            InterfacesService.PostInterface(object).then(_ => {
                Toast.success(Translate("interface_success_saved"))
                window.location.replace("#/interfaces")
            }).catch(_ => {
                Toast.error(Translate("error_saving_interface"))
            })
        }
    }

    saveDisabled = () => {
        const {
            description,
            datLastExecution,
            indDelay,
            codSchedule,
            datSync,
            indMaxInterval,
            datSchedule
        } = this.state.object;

        if (!description) {
            return true;
        }

        if (!datLastExecution || !datSync) {
            return true;
        }

        if (typeof indDelay !== "number") {
            return true;
        }

        if (!codSchedule) {
            return true;
        }

        if (indMaxInterval <= 0) {
            return true;
        }

        return false;
    }

    handleOpenTemplateHistory = () => {
        this.setState({ dialogOpenTemplateHistory: true });
    }

    handleDismissTemplateHistory = () => {
        this.setState({dialogOpenTemplateHistory: false});
    }
    handleOpenInterfaceHistory = () => {
        this.setState({ dialogOpenInterfaceHistory: true });
    }

    handleDismissInterfaceHistory = () => {
        this.setState({dialogOpenInterfaceHistory: false});
    }

    onChangeCheckBox  = ({target: {value, name}}) => {       
        let { object } = this.state;
        object[name] = !(value === "true");
        this.setState({ object });
    }

    setInputInterfaceEmails(emailArray){
        const { object } = this.state

        let emailRegex = new RegExp('[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\\.[a-zA-Z]{2,}');
        let enteredEmail = emailArray[emailArray.length - 1]

        if(emailArray.length == 0){
            object.email = ''
            this.setState({object})
        } else {
            if(emailRegex.test(enteredEmail)){
                object.email = emailArray.join(';')
                this.setState({object})
            } else Toast.error('invalid_addresss')
        }
    }

    generateTagsInputValue(){
        const { object } = this.state

        if(object.email === '' || object.email === undefined) return new Array

        return object.email.split(";")
    }

        render() {
            const {
                object,
                isReady,
                title,
                schedules,
                timeOffsets,
                excludeTimes,
                intervalLabel,
                dialogOpenTemplateHistory,
                dialogOpenInterfaceHistory,
                interfaceID,
                hasPrivilege,
                editPermission
            } = this.state

            const interfaceModes = InterfacesService.InterfaceModes;

            if (!isReady) {
                return <Loading />
            }
            
            return (
                <Fragment>
                    <InternalPageTitle title={title} />
                    <StyledPaper>
                        <Grid spacing={24} container>
                            <Grid item xs={6} md={4}>
                                <ConectorSelect
                                    name="codInterfaceMode"
                                    placeholder="choose"
                                    onChange={this.handleChangeInterfaceMode}
                                    options={interfaceModes}
                                    value={interfaceModes.find(interfaceMode =>
                                        interfaceMode.value === object.codInterfaceMode
                                    )}
                                    label="interface_mode"
                                    help="select_mode_interface"
                                />
                            </Grid>
                            <Grid item xs={12} md={4}>
                                <ConectorInputText label="interface_name"
                                    required={true}
                                    placeholder="interface_name"
                                    help={"fill_name_interface"}
                                    onChange={this.handleChange}
                                    name="description"
                                    value={object.description}
                                />
                            </Grid>
                            <Grid item xs={6} md={4}>
                                <ConectorSelect
                                    name="codSchedule"
                                    placeholder="choose"
                                    onChange={this.handleChangeSchedule}
                                    required={true}
                                    options={schedules}
                                    value={schedules.find(schedule =>
                                        schedule.value === object.codSchedule
                                    )}
                                    disabled={editPermission}
                                    label="schedule"
                                    help="select_schedule"
                                />
                            </Grid>
                            <Grid item xs={6} md={4}>
                                <ConectorDatePicker
                                    value={object.datSync}
                                    required={true}
                                    onChange={this.handleChangeDate.bind(this)}
                                    showTime={true}
                                    name="datSync"
                                    help="select_start_date_time"
                                    label="filter_start"
                                />
                            </Grid>
                            <Grid item xs={6} md={4}>
                                <ConectorInputNumber label="delay"
                                    onChange={this.handleChangeInt}
                                    required={true}
                                    value={object.indDelay}
                                    name="indDelay"
                                    help="time_interval_seconds"
                                />
                            </Grid>
                            <Grid item xs={6} md={4}>
                                <ConectorSelect
                                    name="timeoffset"
                                    placeholder="choose"
                                    onChange={this.handleChangeTimeOffset}
                                    options={timeOffsets}
                                    value={timeOffsets.find(timeOffset =>
                                        timeOffset.value === object.timeOffset
                                    )}
                                    label="time_offset"
                                    help="select_filter_start_date"
                                />
                            </Grid>
                        </Grid>
                        <Grid spacing={24} container>
                            <Grid item xs={6} md={4}>
                                <ConectorDatePicker
                                    value={object.datStartMaxInterval}
                                    required={true}
                                    onChange={this.handleChangeDate.bind(this)}
                                    showTime={true}
                                    excludeTimes={excludeTimes}
                                    name="datStartMaxInterval"
                                    label="Intervalo Inicial"
                                />
                            </Grid>
                            <Grid item xs={6} md={4}>
                                <ConectorDatePicker
                                    value={object.datEndMaxInterval}
                                    minDate={object.datStartMaxInterval}
                                    required={true}
                                    onChange={this.handleChangeDate.bind(this)}
                                    showTime={true}
                                    name="datEndMaxInterval"
                                    label="Intervalo Final"
                                />
                            </Grid>
                            <Grid item xs={6} md={4}>
                                <ConectorDatePicker
                                    value={object.datSchedule}
                                    required={true}
                                    onChange={this.handleChangeDate.bind(this)}
                                    showTime={true}
                                    name="datSchedule"
                                    label="Data de Agendamento"
                                />
                            </Grid>
                            <Grid item xs={12}>
                                <ConectorIntervalLabel>
                                    {intervalLabel}
                                </ConectorIntervalLabel>
                            </Grid>
                        </Grid>
                        <Grid spacing={24} container>
                            <Grid item xs={6} md={4}>
                                <TagsInput value={this.generateTagsInputValue()} inputProps={{placeholder: undefined}} onChange={emailArray => this.setInputInterfaceEmails(emailArray)}/>
                                <p class="tagsInputLabel">{Translate('enterEmails')}</p>
                            </Grid>
                        </Grid>
                        <Grid spacing={24} container>
                            <Grid item xs={6} md={4}>
                                <ConectorCheckbox 
                                    label={"receive_email_warning"}
                                    name="warningEmail"
                                    color="default"
                                    value={object.warningEmail}
                                    onChange={this.onChangeCheckBox}
                                    checked={object.warningEmail}
                                />
                            </Grid>
                        </Grid>
                        <Grid spacing={24} container justify="flex-end">
                        <Grid item>
                    {hasPrivilege &&
                    <Fragment>
                        <StyledButton
                            variant="contained"
                            onClick={this.handleOpenInterfaceHistory}
                            color="primary"
                        >
                            {Translate("interface_history")}
                        </StyledButton>
                        <StyledButton
                            variant="contained"
                            onClick={this.handleOpenTemplateHistory}
                            color="primary"
                            >
                            {Translate("template_history")}
                        </StyledButton>
                    </Fragment>
                    }
                    <StyledButton
                        variant="contained"
                        onClick={this.cancel}
                        color="primary">
                        {Translate("cancel")}
                    </StyledButton>
                    <StyledButton
                        className="no-margin"
                        variant="contained"
                        disabled={this.saveDisabled()}
                        onClick={this.save}>
                        {Translate("save")}
                    </StyledButton>
                </Grid>
                        </Grid>
                    </StyledPaper>
                    {
                        (() => {
                            if (dialogOpenTemplateHistory) {
                                return <TemplateHistory id={interfaceID} onDismiss={this.handleDismissTemplateHistory.bind(this)} />
                            }
                        })()
                    }
                    {
                        (() => {
                            if (dialogOpenInterfaceHistory) {
                                return <InterfaceHistory id={interfaceID} onDismiss={this.handleDismissInterfaceHistory.bind(this)} />
                            }
                        })()
                    }
                </Fragment>
            );
        }

    }


const mapDispatchToProps = (dispatch) => {
    return {
        setBreadcrumb: (steps) => dispatch(setBreadcrumb(steps)),
    }
}

export default connect(null, mapDispatchToProps)(InterfacesDetails)