import React, { Component } from "react";

import {
    Grid,
    Checkbox,
    FormControl,
    FormHelperText,
    FormControlLabel,
    Radio
} from "@material-ui/core";
import {
    Edit,
    Delete,
    AddCircle,
    ArrowDropUp,
    ArrowDropDown
} from "@material-ui/icons";

import ConectorSelect from "../../../../components/conector-ui/conector-select";
import ConectorInputText from "../../../../components/conector-ui/conector-input-text";
import ConectorInputNumber from "../../../../components/conector-ui/conector-input-number";
import ConectorGridTable from "../../../../components/conector-ui/conector-grid/conector-grid-table";
import StyledPaper from "../../../../components/conector-ui/conector-styled-components/styled-paper";
import StyledButton from "../../../../components/conector-ui/conector-styled-components/styled-button";

import Translate from "../../../../i18n/translate";

import { StyledGridButton } from "../transform-style";
import TransformUtil from "../transform-util";
import color from "@material-ui/core/colors/amber";

class TransformConcat extends Component {

    constructor(props) {
        super(props);

        this.state = {
            ...props,
            gridOptions: {
                sortField: "indPosition",
                multiSelect: false,
                fullSize: true,
                columns: [{
                    title: "position",
                    field: "indPosition"
                }, {
                    title: "name",
                    field: "name"
                }, {
                    title: "type",
                    field: "fieldType"
                },],
                refresh: this.gridRefresh.bind(this)
            },
            fieldTypeList: [{
                value: "text",
                label: Translate("string")
            }, {
                value: "field",
                label: Translate("field")
            }],
            mode: "grid",
            formData: {}
        };
    }

    componentWillMount = () => {
        const { fields } = this.state;

        this.setState({
            fieldList: fields.map(field => {
                return {
                    value: field.path,
                    label: field.title
                }
            })
        })
    }

    componentWillReceiveProps = props => {
        let state = {...this.state, ...props};
        state.gridOptions.selectedRow = null;

        this.setState(state)
    }

    gridRefresh = (gridState) => {
        this.setState({
            gridOptions: gridState.gridOptions
        })
    }

    handleMoveUp = _ => {
        let {
            fieldTransform,
            gridOptions
        } = this.state;
        const { indPosition } = gridOptions.selectedRow;

        if (indPosition > 0) {
            fieldTransform.concats[(indPosition - 1)].indPosition += 1;
            fieldTransform.concats[indPosition].indPosition -= 1;
        } else {
            fieldTransform.concats.map((concat, index) => {
                if (index == 0) {
                    concat.indPosition = (fieldTransform.concats.length - 1);
                } else {
                    concat.indPosition -= 1;
                }
            });
        }

        this.setState({
            fieldTransform: this.sortConcats(fieldTransform)
        }, this.state.updateEvent);
    }

    handleMoveDown = _ => {
        let {
            fieldTransform,
            gridOptions
        } = this.state;
        const { indPosition } = gridOptions.selectedRow;

        if (indPosition < (fieldTransform.concats.length - 1)) {
            fieldTransform.concats[(indPosition + 1)].indPosition -= 1;
            fieldTransform.concats[indPosition].indPosition += 1;
        } else {
            fieldTransform.concats.map((concat, index) => {
                if (index == (fieldTransform.concats.length - 1)) {
                    concat.indPosition = 0;
                } else {
                    concat.indPosition += 1;
                }
            });
        }

        this.setState({
            fieldTransform: this.sortConcats(fieldTransform)
        }, this.state.updateEvent);
    }

    sortConcats = (fieldTransform) => {
        fieldTransform.concats.sort((concat1, concat2) => concat1.indPosition - concat2.indPosition);

        return fieldTransform;
    }
    handleCancel =() =>{
        this.setState({ mode: "grid" }, () => {
            this.props.updateEvent(false);
        });
    }
    handleSave = () => {
        let concat;
        const {
            attributes,
            fieldTransform,
            formData,
            fields,
        } = this.state;

        if (formData.indPosition == null) {
            concat = {
                ...this.getInitialConcat()
            };
        } else {
            concat = fieldTransform.concats[formData.indPosition];
        }

        if (!formData.isSubstring) {
            formData.initPosition = "0";
            formData.endPosition = "0";
        }
        if (!formData.isTrim) {
            formData.trim_type = "";
        }

        concat = {
            ...concat,
            ...formData,
            name: (formData.fieldType == "text" ? formData.textInput : TransformUtil.getItemByPath(fields, formData.fieldPath).title)
        };
        var validationTrim = TransformUtil.getAttributeByCode(concat.fieldTransformAttributes, attributes.is_trim);
        if(validationTrim == null){
            concat.fieldTransformAttributes = [
                ...concat.fieldTransformAttributes,
                {
                    codAttribute: attributes.is_trim,
                    desLabel: "is_trim",
                    indPosition: 6
                }, {
                    codAttribute: attributes.trim_type,
                    desLabel: "trim_type",
                    indPosition: 7
                }
            ]
        }

        TransformUtil.getAttributeByCode(concat.fieldTransformAttributes, attributes.field_type).desValue = formData.fieldType;
        TransformUtil.getAttributeByCode(concat.fieldTransformAttributes, attributes.from).desValue = (formData.fieldType == "text" ? formData.textInput : formData.fieldPath);
        TransformUtil.getAttributeByCode(concat.fieldTransformAttributes, attributes.is_substring).desValue = JSON.stringify(formData.isSubstring);
        TransformUtil.getAttributeByCode(concat.fieldTransformAttributes, attributes.is_concatall).desValue = JSON.stringify(formData.isConcatAll); 
        TransformUtil.getAttributeByCode(concat.fieldTransformAttributes, attributes.ini_substring).desValue = formData.initPosition; 
        TransformUtil.getAttributeByCode(concat.fieldTransformAttributes, attributes.end_substring).desValue = formData.endPosition; 
        TransformUtil.getAttributeByCode(concat.fieldTransformAttributes, attributes.field_name).desValue = concat.name;
        TransformUtil.getAttributeByCode(concat.fieldTransformAttributes, attributes.is_trim).desValue = JSON.stringify(formData.isTrim);
        TransformUtil.getAttributeByCode(concat.fieldTransformAttributes, attributes.trim_type).desValue = formData.trimType;

        if (formData.indPosition == null) {
            concat.indPosition = fieldTransform.concats.length;
            fieldTransform.concats.push(concat);
        } else {
            fieldTransform.concats[formData.indPosition] = concat;
        }

        this.setState({
            fieldTransform: fieldTransform,
            mode: "grid"
        }, () => {
            this.props.updateEvent(false);
        });
    }

    getInitialConcat = () => {
        const {
            attributes,
            formData,
            fields
        } = this.state;

        return {
            name: formData.fieldType == "text" ? formData.textInput : TransformUtil.getItemByPath(fields, formData.fieldPath).title,
            desField: "concat",
            fieldTransformAttributes: [{
                codAttribute: attributes.field_type,
                desLabel: "field_type",
                indPosition: 0
            }, {
                codAttribute: attributes.from,
                desLabel: "from",
                indPosition: 1
            }, {
                codAttribute: attributes.structure_in,
                desLabel: "structure_in",
                indPosition: 2
            }, {
                codAttribute: attributes.is_substring,
                desLabel: "is_substring",
                indPosition: 3
            }, {
                codAttribute: attributes.ini_substring,
                desLabel: "ini_substring",
                indPosition: 4
            }, {
                codAttribute: attributes.end_substring,
                desLabel: "end_substring",
                indPosition: 5
            }, {
                codAttribute: attributes.is_concatall,
                desLabel: "is_concatall",
                indPosition: 6
            }, {
                codAttribute: attributes.field_name,
                desLabel: "field_name",
                indPosition: 7
            },{
                codAttribute: attributes.is_trim,
                desLabel: "is_trim",
                indPosition: 8
            }, {
                codAttribute: attributes.trim_type,
                desLabel: "trim_type",
                indPosition: 9
            }]
        }
    }

    handleEdit = (selectedRow) => {
        this.setState({
            formData: {
                fieldType: selectedRow ? selectedRow.fieldType : "text",
                textInput: selectedRow ? (selectedRow.fieldType == "text" ? selectedRow.textInput : "") : "",
                fieldPath: selectedRow ? selectedRow.fieldPath : "",
                indPosition: selectedRow ? selectedRow.indPosition : null,
                isSubstring: selectedRow ? JSON.parse(selectedRow.isSubstring) : false,
                initPosition: selectedRow ? selectedRow.initPosition.toString() : "0",
                endPosition: selectedRow ? selectedRow.endPosition.toString() : "0",
                isConcatAll: selectedRow ? JSON.parse(selectedRow.isConcatAll) : false,
                isTrim: selectedRow ? selectedRow.isTrim ? JSON.parse(selectedRow.isTrim):false : false,
                trimType: selectedRow ? selectedRow.trimType ? selectedRow.trimType.toString() :"" : ""
            },
            mode: "form",
            disableSave: !selectedRow
        }, () => {
            this.props.updateEvent(true);
        });
    }

    handleRemove = () => {
        let {
            gridOptions,
            fieldTransform
        } = this.state;

        const indexToRemove = fieldTransform.concats.indexOf(gridOptions.selectedRow);

        for (let d = (indexToRemove + 1); d < fieldTransform.concats.length; d++) {
            fieldTransform.concats[d].indPosition -= 1;
        }

        this.state.onRemove(gridOptions.selectedRow);

        fieldTransform.concats.splice(indexToRemove, 1);
        gridOptions.selectedRow = null;

        this.setState({
            fieldTransform: fieldTransform,
            gridOptions: gridOptions
        }, this.state.updateEvent);
    }

    handleChangeFormComponent = (event) => {
        let formData = { ...this.state.formData };

        if (event.hasOwnProperty("target")) {
            const property = event.target.name;

            if(property == "isSubstring") 
                formData[property] = event.target.checked;
            else if(property == "isConcatAll")
                formData[property] = event.target[property == "isConcatAll" ? "checked" : "value"]; 
            else
                formData[property] = event.target[property == "isTrim" ? "checked" : "value"];

        } else {
            formData[event.name] = event.value;
        }

        this.setState({
            formData: formData,
            disableSave: !this.isFormValid(formData)
        });
    }

    isFormValid = (formData) => {
        if (formData.fieldType == "text") {
            return formData.textInput.length > 0;
        } else if (formData.fieldPath != "") {
            if (formData.isSubstring) {
                const initPosition = Number(formData.initPosition);
                const endPosition = Number(formData.endPosition);

                if (initPosition >= 0 && endPosition >= 0 &&
                    endPosition >= initPosition) {
                    return true;
                }
            } else {
                return true;
            }
        }

        return false;
    }

    getFieldName(path){
        let name = [];
        name = path.split('.');
        return name[name.length-1].toString();
    }

    render() {
        const { mode } = this.state;

        return (
            <StyledPaper>
                {mode == "grid" ? this.renderGrid() : this.renderForm()}
            </StyledPaper>
        )
    }

    renderForm = () => {
        const {
            disableSave,
            formData,
            fieldList,
            fieldTypeList
        } = this.state;

        return (
            <Grid item xs={12}>
                <FormControl style={{ width: "100%", marginBottom: "20px" }}>
                    <ConectorSelect name={"fieldType"} label={"type"} placeholder={"choose"} options={fieldTypeList}
                        value={fieldTypeList.find(fieldType => fieldType.value == formData.fieldType)}
                        onChange={this.handleChangeFormComponent.bind(this)} />
                </FormControl>

                {
                    formData.fieldType == "text" ? (
                        <ConectorInputText label={"string"} name="textInput" value={formData.textInput} onChange={this.handleChangeFormComponent.bind(this)} />
                    ) : (
                            <FormControl style={{ width: "100%" }}>
                                <ConectorSelect name={"fieldPath"} label={"field"} placeholder={"choose"} options={fieldList}
                                    value={fieldList.find(field => field.value == formData.fieldPath)}
                                    onChange={this.handleChangeFormComponent.bind(this)} />
                                <FormHelperText>{Translate("select_field")}</FormHelperText>
                            </FormControl>
                        )
                }

                {
                    formData.fieldType == "field" ? (
                        <FormControlLabel label="Substring"
                            control={
                                <Checkbox style={{"color": "#6f8296"}} checked={formData.isSubstring} name="isSubstring" onChange={this.handleChangeFormComponent.bind(this)} />
                            } />
                    ) : ""
                }
                                                
                {
                    formData.fieldType == "field" ? (
                        <FormControlLabel label="Concat All"
                            control={
                                <Checkbox style={{"color": "#6f8296"}} checked={formData.isConcatAll} name="isConcatAll" onChange={this.handleChangeFormComponent.bind(this)} />
                            } />
                    ) : ""
                }

                {
                    formData.fieldType == "field" && formData.isSubstring ? (
                        <Grid container style={{ marginBottom: "15px" }}>
                            <Grid item xs={6} style={{ paddingRight: "7.5px", boxSizing: "border-box" }}>
                                <ConectorInputNumber name="initPosition" required={true} label={"init_position"}
                                    value={formData.initPosition} onChange={this.handleChangeFormComponent.bind(this)} />
                            </Grid>

                            <Grid item xs={6} style={{ paddingLeft: "7.5px", boxSizing: "border-box" }}>
                                <ConectorInputNumber name="endPosition" required={true} label={"end_position"}
                                    value={formData.endPosition} onChange={this.handleChangeFormComponent.bind(this)} />
                            </Grid>
                        </Grid>
                    ) : ""
                }
                {
                    formData.fieldType == "field" ? (
                    <Grid>
                    <FormControlLabel label={Translate("trim")}
                    control={
                        <Checkbox style={{"color": "#6f8296"}} checked={formData.isTrim} name="isTrim" onChange={this.handleChangeFormComponent.bind(this)} />
                    } />
                    </Grid>
                    ):""
                }
                {
                    formData.fieldType == "field" && formData.isTrim ? (
                    <Grid style={{ 
                        fontFamily: '"Roboto", "Helvetica", "Arial", sans-serif',
                        fontSize: '0.9rem',
                        color: 'default'
                    }}>
                    <Grid container>
                        <Grid item>
                            <Radio
                                name="trimType"
                                color="default"
                                onChange={this.handleChangeFormComponent}
                                checked={formData.trimType === 'trim_start'}
                                value="trim_start"
                            />
                            {Translate("trim_start")}
                        </Grid>
                        <Grid item>
                            <Radio
                                name="trimType"
                                color="default"
                                onChange={this.handleChangeFormComponent}
                                checked={formData.trimType === 'trim_end'}
                                value="trim_end"
                                title="Remove"
                            />
                            {Translate("trim_end")}                        
                        </Grid>
                        <Grid item>
                            <Radio
                                name="trimType"
                                color="default"
                                onChange={this.handleChangeFormComponent}
                                checked={formData.trimType === 'trim_all'}
                                value="trim_all"
                            />
                            {Translate("trim_all")}
                        </Grid>
                        <Grid item>
                            <Radio
                                name="trimType"
                                color="default"
                                onChange={this.handleChangeFormComponent}
                                checked={formData.trimType === 'all'}
                                value="all"
                            />
                            {Translate("all")}
                        </Grid>
                    </Grid>
                    </Grid>
                    ) : ""
                }
                <div style={{ width: "100%", marginTop: "15px" }}>
                    <StyledButton variant="contained" color="primary" onClick={this.handleCancel}>{Translate("cancel")}</StyledButton>
                    <StyledButton variant="contained" disabled={disableSave} onClick={this.handleSave}>{Translate("save")}</StyledButton>
                </div>
            </Grid>
        );
    }

    renderGrid = () => {
        const {
            gridOptions,
            fieldTransform
        } = this.state;

        const disabledButton = (gridOptions.selectedRow == null || Object.keys(gridOptions.selectedRow).length == 0);

        fieldTransform.concats.map(field => {
            if (field.fieldTransformAttributes && field.fieldTransformAttributes.length >= 8) {
                  const filteredAttributes = field.fieldTransformAttributes.filter(
                    (record) => record.desLabel === 'field_name'
                  );
                field.name = filteredAttributes[0].desValue       
            }
            else if( !field.name && field.fieldPath){
                field.name = this.getFieldName(field.fieldPath);
            }
        })

        return (
            <Grid item xs={12}>
                <div>
                    <StyledGridButton mini aria-label="Move" variant="contained" className={"iconNoMargin"}
                        onClick={this.handleMoveUp.bind(this)} disabled={disabledButton}>
                        <ArrowDropUp />
                    </StyledGridButton>
                    <StyledGridButton mini aria-label="Move" variant="contained" className={"iconNoMargin"}
                        onClick={this.handleMoveDown.bind(this)} disabled={disabledButton}>
                        <ArrowDropDown />
                    </StyledGridButton>
                    <StyledGridButton size="small" name={"edit-field"} variant="contained" className={"iconNoMargin"}
                        onClick={this.handleEdit.bind(this, gridOptions.selectedRow)} disabled={disabledButton}>
                        <Edit />
                    </StyledGridButton>
                    <StyledGridButton size="small" name={"delete-field"} variant="contained" className={"iconNoMargin"}
                        onClick={this.handleRemove.bind(this)} disabled={disabledButton}>
                        <Delete />
                    </StyledGridButton>
                    <StyledGridButton size="small" name={"add-field"} variant="contained" className={"iconNoMargin"}
                        onClick={this.handleEdit.bind(this, null)}>
                        <AddCircle />
                    </StyledGridButton>
                </div>

                <ConectorGridTable dataSource={fieldTransform.concats} gridOptions={gridOptions} />
            </Grid>
        );
    }

}

export default TransformConcat;