import React, { Component } from "react";

import {
    DialogActions,
    DialogContent,
    DialogContentText
} from "@material-ui/core/";

import {
    StyledDialog,
    StyledDialogTitle
} from "../../../components/conector-ui/conector-styled-components/styled-dialog";
import ConectorInputText from "../../../components/conector-ui/conector-input-text";
import StyledButton from "../../../components/conector-ui/conector-styled-components/styled-button";

import Translate from "../../../i18n/translate";

class DialogComponent extends Component {

    render() {
        return (
            <StyledDialog maxWidth="lg"
                fullWidth={true}
                open={this.props.open}
            >
                <StyledDialogTitle className="confirmation">
                    {Translate("stack_trace_detailing")}
                </StyledDialogTitle>
                <DialogContent>
                    <DialogContentText>
                        <ConectorInputText
                            value={this.props.stackTraceToExpand}
                            disabled={true}
                            rows={15}
                            multiline={true}
                        />
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <StyledButton
                        variant="contained"
                        onClick={this.props.onClose}>
                        {Translate("back")}
                    </StyledButton>
                </DialogActions>
            </StyledDialog>
        )
    }

}

export default DialogComponent