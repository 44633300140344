import { Dialog, DialogTitle } from "@material-ui/core";
import styled from "styled-components";

const StyledDialog = styled(Dialog)`
    && {
        z-index: 999 !important;
    }
`;

const StyledDialogTitle = styled(DialogTitle)`
    && {
        margin-bottom: 20px;
        &.confirmation {
            background-color: #009cdf;
        }
        &.danger {
            background-color: ${props =>
                props.theme.default.dialogConfirmation.title.bgColorError};
        }
        &.info {
            background-color: ${props =>
                props.theme.default.dialogConfirmation.title.bgColorInfo};
        }
        > h2 {
            color: ${props =>
                props.theme.default.dialogConfirmation.title.textColor};
            font-weight: 700;
        }
    }
`;

export { StyledDialog, StyledDialogTitle };
