import * as moment from 'moment';

const randomValGen = (limit, min) => {
    if (min) {
        return Math.floor(Math.random() * (limit - min) + min);
    }
    return Math.floor(Math.random() * limit);
}

const getLineValue = () => {
    return {
        successes: randomValGen(50),
        failures : randomValGen(20),
        warnings : randomValGen(40),
        aborted  : randomValGen(30)
    }
}

const getBarValue = () => {
    const average = randomValGen(80, 1);
    const min = average + Math.floor(average * 0.25);
    return {
        average: average,
        max    : randomValGen(80, min)
    }
}

const cardProps = {
    'Interfaces executadas hoje': {
        value: randomValGen(50, 30),
        icon: 'sync'
    },
    'Interfaces pendentes': {
        value: randomValGen(30, 1),
        icon: 'warning'
    },
    'Interfaces com erro': {
        value: randomValGen(10, 1),
        icon: 'error'
    },
    'Execuções de sucesso': {
        icon: 'check_circle'
    },
    'Data de último login': {
        value: moment(new Date()).calendar(),
        icon: 'calendar_today'
    },
    'Usuários da empresa conectados hoje': {
        value: randomValGen(20, 1),
        icon: 'person'
    }
}

export default {
    LineGraphData: () => {
        const weekDays = ['Segunda-Feira', 'Terça-Feira', 'Quarta-Feira', 'Quinta-Feira', 'Sexta-Feira', 'Sábado', 'Domingo'];
        return weekDays.map(weekDay => ({ weekDay, ...getLineValue() }));
    },
    BarGraphData: () => {
        const timeSpans = ['Últimas 24h', 'Últimas 48h', 'Últimas 72h', 'Últimas 96h', 'Última Semana'];
        return timeSpans.map(timeSpan => ({ timeSpan, ...getBarValue()}));
    },
    CardsData: () => {
        let cardLabels = [
            'Interfaces executadas hoje', 
            'Interfaces pendentes', 
            'Interfaces com erro', 
            'Execuções de sucesso', 
            'Data de último login', 
            'Usuários da empresa conectados hoje'
        ];
        cardLabels = cardLabels.map(label => ({ label, ...cardProps[label] }));
        cardLabels[3].value = `${100 - (Math.floor((100 * cardLabels[2].value) / cardLabels[0].value))}%`;
        return cardLabels;
    }
} 