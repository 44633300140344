import React, {
  Component,
  Fragment
} from "react";
import styled from "styled-components";

import {
	Tooltip,
	IconButton,
	InputAdornment
} from "@material-ui/core/";
import { AttachFile } from '@material-ui/icons/';

import Translate from "../../i18n/translate";

import StyledTextField from "./conector-styled-components/styled-text-field";
import StyledInputLabel from "./conector-styled-components/styled-input-label";

const StyledDiv = styled.div`

	&.hidden {
		display: none;
	}

`;

class ConectorInputFile extends Component {

	constructor(props) {
		super(props)

		this.state = { 
			...props
		}
	}

	componentWillReceiveProps(props) {
		this.setState({...props})
	}

	handleChange = e => {
		if (this.state.onChange) {
			this.state.onChange(e)
		}
	}

	handleBlur = e => {
		if (this.state.onBlur) {
			this.state.onBlur(e)
		}
	}

	render() {
		const props = { ...this.state }

		if (props.invisible) {
			return <Fragment></Fragment>
		}

		var labelClass = props.classNameLabel;
		if (props.required) {
			labelClass += " required";
		}

		return (
			<StyledDiv className={props.classNameForm}>
				<StyledInputLabel className={labelClass}>{Translate(props.label)}</StyledInputLabel>
				<StyledTextField id={props.id} helperText={props.help} name={props.name} onChange={this.handleChange.bind(this)} onBlur={this.handleBlur.bind(this)}
					autoComplete="false" required={props.required} type="file" variant="outlined" disabled={!!props.disabled ? props.disabled : false}
					value={props.value} className={props.classNameInput} inputProps={{accept: props.accept, id: props.id}} InputProps={{
						endAdornment: (
							<InputAdornment position="end">
								<IconButton>
									<label htmlFor={props.id}>
										<AttachFile />
									</label>
								</IconButton>
							</InputAdornment>
						)
					}} />
			</StyledDiv>
		)
	}

}

export default ConectorInputFile;