import React, { Component, Fragment } from 'react'
import styled from 'styled-components'
import Translate from '../../i18n/translate'
import StyledTextField from './conector-styled-components/styled-text-field'
import StyledInputLabel from './conector-styled-components/styled-input-label'

const StyledDiv = styled.div`
    &.hidden { display: none; }
`

class ConectorInputText extends Component {
  constructor(props) {
    super(props)

    this.state = { 
      ...props
    }
  }

  componentWillReceiveProps(props) {
    this.setState({...props})
  }

  handleChange = e => {
    if (this.state.onChange) {
      this.state.onChange(e)
    }
  }

  handleBlur = e => {
    if (this.state.onBlur) {
      this.state.onBlur(e)
    }
  }

  render() { 
    var {
      required,
      className,
      errorCondition,
      invisible,
      help,
      name,
      label,
      disabled,
      value
    } = this.state;
    
    if (invisible) {
      return <Fragment></Fragment>
    }

    required       = Boolean(required);
    errorCondition = Boolean(errorCondition);
    disabled       = Boolean(disabled);

    if (errorCondition) {
      className = `${className || ''} error`
    }

    if (required) {
      className = `${className || ''} required`;
    }

    return (
      <StyledDiv>
        <StyledInputLabel className={className}>{
          Translate(label)
        }</StyledInputLabel>
        <StyledTextField
          inputProps={{ style: {textAlign: 'right'} }}
          id={`conector-input-number-${name}`}
          helperText={Translate(help)}
          name={name}
          onChange={this.handleChange.bind(this)}
          onBlur={this.handleBlur.bind(this)}
          autoComplete='false'
          required={required}
          type='number'
          variant='outlined'
          disabled={disabled}
          value={value}
          className={className}
        />
      </StyledDiv>
    )
  }
}

export default ConectorInputText