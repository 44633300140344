import styled from 'styled-components'

export const StyledBreadcrumb = styled.div`
  && {
    background-color: ${props => props.theme.default.breadcrumb.bgColor};
    border-bottom: 1px solid ${props => props.theme.default.breadcrumb.borderColor};
    padding: 10px 20px 10px;

    p {
      display: inline-block;
      font-size: 0.7rem;
      color: ${props => props.theme.default.breadcrumb.textColor};
      &.pointer { cursor: pointer; }
      &.pointer:hover { color: ${props => props.theme.default.breadcrumb.textColorHover}; }
      &:last-child { color: ${props => props.theme.default.breadcrumb.textColorLastChild}; }
      &.divider { padding: 0 10px 0 10px; }
      
    }
  }
`

export const BreadcrumbDiv = styled.div`&& { &.open { margin-left: 240px; } }`