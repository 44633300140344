import React, {
    Component,
    Fragment
} from "react";
import { connect } from "react-redux";

import { Grid } from "@material-ui/core/";

import CompaniesService from "./companies-service";

import Translate from "../../i18n/translate";

import Toast from "../../components/toast/toast";
import Loading from "../../components/conector-ui/conector-loading";
import ConectorInputText from "../../components/conector-ui/conector-input-text";
import InternalPageTitle from "../../components/conector-ui/conector-internal-page-title";
import StyledPaper from "../../components/conector-ui/conector-styled-components/styled-paper";
import StyledButton from "../../components/conector-ui/conector-styled-components/styled-button";

import { setBreadcrumb } from "../../store/app-state/actions";
import { postChangedAuthentication } from "../../store/login/actions";

import Session from "../../utils/session-info";
import SessionUtils from "../../utils/session-utils";

class CompanyDetails extends Component {

    constructor(props) {
        super(props);

        let companyID = window.location.hash.substring(12)

        if (typeof (parseInt(companyID)) !== "number" && companyID !== "add") {
            window.location.replace('#/instances')
        }

        props.setBreadcrumb([{
            label: "registrations"
        }, {
            label: "companies",
            url: "#/companies"
        }, {
            label: companyID !== "add" ? "edit_company" : "add_company"
        }]);

        this.state = {
            companyID: companyID,
            loading: true,
            validations: [],
            company: {}
        };
    }

    componentWillMount() {
        const { companyID } = this.state;

        if (companyID === "add") {
            this.setState({ loading: false });
        } else {
            this.getCompany(companyID);
        }
    }

    getCompany(id) {
        CompaniesService.GetCompanyById(id).then((company) => {
            if (!company.id) {
                Toast.error(Translate("company_not_found"));

                this.goBack();
            } else {
                this.setState({
                    company,
                    loading: false
                });
            }
        });
    }

    goBack() {
        window.location.replace('#/companies')
    }

    save() {
        this.setState({ validations: [] }, _ => {
            var validations = [],
                { company } = this.state;

            if (!company.description) {
                validations.push("description");
            }

            if (validations.length) {
                this.setState({ validations });
                return;
            } else {
                if (!company.id) {
                    CompaniesService
                        .PostCompany(company)
                        .then(onCallback.bind(this));
                } else {
                    CompaniesService
                        .PutCompany(company)
                        .then(onCallback.bind(this));
                }
            }

            function onCallback(res) {
                
                
                if (res === 201) {
                    Toast.success("record_success_saved");
                    const { codCompany } = Session();
                    if (!codCompany) {
                        postChangedAuthentication(res, 0)
                            .then(SessionUtils.changeUserScenario);
                    }
                    this.goBack();
                } else {
                    if(res === "Token does not have access to this instance") Toast.error("userWithoutPermissionInstance")
                    else Toast.error("error_saving_record")
                }
            }
        })
    }

    handleChange({ target: { name, value } }) {
        var { company, validations } = this.state;

        company[name] = value;

        this.setState({
            company,
            validations: validations.filter(v => v !== name)
        });
    }

    copyHash() {
        const el = document.createElement("textarea");
        el.value = this.state.company.hash;
        document.body.appendChild(el);
        el.select();
        document.execCommand("copy");
        document.body.removeChild(el);
    }

    render() {
        const {
            company,
            validations,
            loading,
            companyID
        } = this.state;

        if (loading) {
            return (<Loading />);
        }

        return (
            <Fragment>
                <InternalPageTitle title={
                    companyID !== "add"
                        ? "edit_company"
                        : "new_company"
                }
                />

                <StyledPaper>
                    <Grid container={true} spacing={24}>
                        <Grid item xs={12} md={6}>
                            <ConectorInputText
                                label={"description"}
                                help={"enter_description"}
                                name={"description"}
                                required={true}
                                value={company.description}
                                onChange={this.handleChange.bind(this)}
                                errorCondition={validations.find(v => v === "description")}
                            />
                        </Grid>

                        <Grid item={true} xs={12} md={6}>
                            <ConectorInputText
                                label={"hash"}
                                name={"hash"}
                                help={"company_hash"}
                                type={"text"}
                                disabled={true}
                                value={company.hash}
                                endIcon={"file_copy"}
                                endTooltip={"copy"}
                                endActionButton={this.copyHash.bind(this)}
                            />
                        </Grid>
                    </Grid>
                    <Grid container={true} spacing={24} justify="flex-end">
                        <Grid item>
                            <StyledButton
                                color="primary"
                                variant="contained"
                                onClick={this.goBack.bind(this)}
                            >
                                {Translate("cancel")}
                            </StyledButton>
                            <StyledButton
                                className="no-margin"
                                variant="contained"
                                onClick={this.save.bind(this)}
                            >
                                {Translate("save")}
                            </StyledButton>
                        </Grid>
                    </Grid>
                </StyledPaper>
            </Fragment>
        )
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        setBreadcrumb: (steps) => dispatch(setBreadcrumb(steps))
    }
}

export default connect(null, mapDispatchToProps)(CompanyDetails)