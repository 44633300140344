import React, {
    Component,
    Fragment
} from "react";

import { Grid } from "@material-ui/core";

import CompaniesService from "../../../../routes/companies/companies-service";
import InterfacesService from "../../interfaces-service";
import ConectorSelect from "../../../../components/conector-ui/conector-select";
import ConectorInputText from "../../../../components/conector-ui/conector-input-text";

class DefinitionsStep extends Component {

    constructor(props) {
        super(props);

        this.state = {
            ...props
        };
    }

    componentWillMount() {
        const {
            instanceOptions = []
        } = this.state;

        if (instanceOptions.length == 0) {
            this.getInstances();
        }

        const {
            description
        } = this.state.targetInterface;

        this.setState({
            copyName: description,
            ...this.state
        }, this.updateParent);
    }

    getInstances() {
        InterfacesService.GetCompanyInstancesWithPermissionToCopy()
        .then(companiesInstances => {
            let instanceOptions = [];

            companiesInstances.map(companyInstance => {
                instanceOptions.push({
                        value: companyInstance.CodInstance,
                        label: companyInstance.DesCompany + " -> " + companyInstance.DesInstance
                });
            })

            this.setState({
                instanceOptions
            }, this.updateParent);
        })
    }

    onChange({ target: { value, name } }) {
        this.setState({
            [name]: value
        }, this.updateParent);
    }

    onChangeInstance({ value }) {
        this.setState({
            targetInstance: value
        }, this.updateParent);
    }

    stepDisabled() {
        const {
            targetInstance,
            copyName
        } = this.state;

        if (!targetInstance || !copyName || copyName.trim() === "") {
            return true;
        }

        return false;
    }

    updateParent() {
        this.props.updateParent({
            ...this.state,
            stepDisabled: this.stepDisabled()
        });
    }

    render() {
        const {
            instanceOptions = [],
            copyName,
            targetInstance
        } = this.state;

        return (
            <Fragment>
                <Grid container={true} spacing={24}>
                    <Grid item={true} sm={12} lg={12}>
                        <ConectorSelect
                            required={true}
                            onChange={this.onChangeInstance.bind(this)}
                            name="targetInstance"
                            label="dest_instance"
                            help="select_target_instance"
                            options={instanceOptions}
                            value={instanceOptions.find(i => i.value === targetInstance) || null}
                        />
                    </Grid>
                </Grid>
                <Grid>
                    <Grid item={true} sm={12} lg={12}>
                        <ConectorInputText
                            required={true}
                            onChange={this.onChange.bind(this)}
                            name="copyName"
                            value={copyName}
                            label="name"
                            help="enter_name_interface"
                        />
                    </Grid>
                </Grid>
            </Fragment>
        );
    }

}

export default DefinitionsStep;