import styled from 'styled-components'
import Chip from '@material-ui/core/Chip'

const StyledChipInterface = styled(Chip)`
  && {
    cursor: grab; 
    margin: 5px 3%;
    border-radius: 5px;
    min-width: 120px;
    justify-content: flex-start;
    padding: 20px 10px;
    border: 1px solid ${props => props.theme.default.content.chipInterface.borderColor};
    background-color: ${props => props.theme.default.content.chipInterface.bgColor};
    color: ${props => props.theme.default.content.chipInterface.textColor};
    flex-basis: 38%;
    flex-grow: 1;
    &:hover { background-color: ${props => props.theme.default.content.chipInterface.bgColorHover}; }
    &:active { cursor: grabbing; }
    &.disabled { cursor: no-drop; opacity: 0.5; }
    .MuiChip { white-space: normal; }
    @media screen and (min-width: 960px) and (max-width: 1030px) { flex-basis: 100%; }
    @media screen and (min-width: 1400px) { flex-basis: 20%; }
  }
`

export default StyledChipInterface