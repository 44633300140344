import React, {
	Component
} from "react";

import {
	Grid,
	Avatar,
	Tooltip,
	Typography
} from "@material-ui/core/";

import StyledChipInterface from "../../../../components/conector-ui/conector-styled-components/styled-chip-interface";
import Translate from "../../../../i18n/translate";

class EventsItems extends Component {
	constructor(props) {
		super(props);
		this.state = {...props}
	}

	componentWillReceiveProps = ({ disableEventComponent, title, events }) => {
		this.setState({title, events, disableEventComponent})
	}

	onDrag = (eventInfo, event) => event.dataTransfer.setData("eventInfo", JSON.stringify(eventInfo));

	render() {
		const { title, events, position, disableEventComponent } = this.state

		if(disableEventComponent) return

		return (
			<Grid container spacing={24} style={title === "modifiers" || title === "banks" ? {"marginTop": "20px"} : {}}>
				<Grid item xs={12}>
					<Typography gutterBottom style={{"fontSize": "1rem", "color": "#6F8296"}}>{Translate(title)}</Typography>
				</Grid>

				<Grid item xs={12}>
					<Grid container direction="column" justify="center" alignItems="stretch" spacing={24} 
					style={{"backgroundColor": "#fff", "border": "1px solid #b8c6d6", "borderRadius": "5px"}}>
						{
							events.map(event => (
								<Grid item key={`event-item-${event.description}`} xs={12}>
									<Tooltip placement={position === "side" ? "top" : "right"} title={ <h3>{event.label}</h3> }>
										<StyledChipInterface style={{"margin": "0", "padding": "20px 0", "minWidth": "0", "width": "100%"}} avatar={(() => {
											switch (title) {
												case "modifiers":
													return <Avatar 
														alt={event.label} 
														src={`/static/images/events/${event.description}.png`} 
														style={{"borderRadius": "0", "marginLeft": "18px", "marginRight": "-4px"}} />
												case "banks":
													return <Avatar 
														alt={event.label} 
														src={`/static/images/banksLogos/${event.description}.png`} 
														style={{"borderRadius": "8px", "marginLeft": "18px", "marginRight": "-4px"}} />
												default:
													return <Avatar 
														alt={event.label} 
														src="/static/images/conector-web-img.png"
														style={{"borderRadius": "0", "marginLeft": "18px", "marginRight": "-4px"}} />
											}
										})()} label={event.label} draggable={true} onDragStart={this.onDrag.bind(this, {
											codBank: event.codBank,
											codConnectionType: event.codConnectionType,
											codEvent: event.codEvent,
											codConnectionMode: event.codConnectionMode,
											label: event.label
										})} key={event.description} />
									</Tooltip>
								</Grid>
							))
						}
					</Grid>
				</Grid>
			</Grid>
		)
	}
}

export default EventsItems;
