import * as types from './wizard-store-action-types';
import request from '../../utils/request'
import { MergeTypeSharp } from '@material-ui/icons';

const initialState = {
    connectionActions: [],
    //todos os event properties
    eventProperties: [],
    eventTypes: [],
    postEvent: (event, deletedExtras, deletedDetails, deletedFields, deletedFieldTransforms,deletedFieldTransformsCase, deleteStructure, changedDetails, changedFields, desCompany, desInstance, desName,codInterface, codInstance, desInterface) => {
        return request.executeRequest(
            `${request.baseUrl}interfaces/instanceinterfaces/events`,
            {
                ...request.postOptions(),
                body: JSON.stringify({ event, deletedExtras, deletedDetails, deletedFields, deletedFieldTransforms, deletedFieldTransformsCase, deleteStructure, changedDetails, changedFields, desCompany, desInstance, desName,codInterface , codInstance, desInterface})
            }
        ).then(res => {
            if (!res.ok) {
                return Promise.reject(res.statusText);
            }
            return res.json();
        });
    },
    connectionActions: [],
    wizardState: {
        isEdit: false,
        disabledStep: true,
        //filtrados para o action selecionado
        eventProperties: [],
        event: {}
    },
    isStepAuxiliary: false,
    isStructureChangeTab: false,
    treeData: []
};

export default function reduce(store = initialState, action = {}) {
    switch (action.type) {
        case types.WIZARD_SET_SELECTED_CONNECTION_ACTION:
            return {
                ...store,
                codConnectionAction: action
            }
        case types.WIZARD_DEFINE_ATTRIBUTES:
            return {
                ...store,
                attributes: action.attributes
            }
        case types.WIZARD_DEFINE_EVENT_PROPERTIES:
            return {
                ...store,
                eventProperties: action.eventProperties
            }
        case types.WIZARD_DEFINE_CONNECTION_ACTIONS:
            return {
                ...store,
                connectionActions: action.connectionActions
            }
        case types.WIZARD_SET_WIZARD_STATE:
            return {
                ...store,
                wizardState: {
                    ...store.wizardState,
                    ...action.newStateProps
                }
            }
        case types.WIZARD_SET_DELETED_FIELDS:
            return {
                ...store,
                wizardState: {
                    ...store.wizardState,
                    event: {
                        ...store.wizardState.event,
                        deletedFields: action.deletedFields
                    }
                }

            }
        case types.WIZARD_CLEAR_WIZARD_STORE:
            return {
                ...initialState
                //store: initialState
            }
        case types.WIZARD_SET_EVENT_TYPES:
            return {
                ...store,
                eventTypes: action.eventTypes
            }
        case types.WIZARD_SET_SELECTED_STRUCTURE:
            return{
                ...store,
                selectedStructure: action.selectedStructure
            }
        case types.WIZARD_SET_STRUCTURE_ARRAY:
            return{
                ...store,
                structureArray: action.structureArray
            }
        case types.WIZARD_SET_STEP_AUXILIARY:
            return{
                ...store,
                isStepAuxiliary: action.isStepAuxiliary
            }
        case types.WIZARD_SET_STEP_STRUCTURE:
            return{
                ...store,
                stepStructure: action.stepStructure
            }
        case types.WIZARD_SET_STRUCTURE_CHANGE_TAB:
            return{
                ...store,
                isStructureChangeTab: action.isStructureChangeTab
            }
        case types.WIZARD_SET_TREE_DATA:
            return{
                ...store,
                treeData: action.treeData
            }
        default:
            return store;
    }
}

export function getConnectionActions(store) {
    return store.wizard.connectionActions;
}

export function getEventProperties(store) {
    return store.wizard.eventProperties;
}

export function getAttributes(store) {
    return store.wizard.attributes;
}

export function getWizardState(store) {
    return store.wizard.wizardState;
}

export function postEvent(store) {
    return store.wizard.postEvent;
}

export function getEventTypes(store) {
    return store.wizard.eventTypes;
}

export function getSelectedStructure(store) {
    return store.wizard.selectedStructure;
}

export function getStructureArray(store) {
    return store.wizard.structureArray;
}

export function getStepAuxiliary(store) {
    return store.wizard.isStepAuxiliary;
}

export function getStepStructure(store) {
    return store.wizard.stepStructure;
}

export function getIsStructureChangeTab(store) {
    return store.wizard.isStructureChangeTab;
}

export function getTreeData(store) {
    return store.wizard.treeData;
}
