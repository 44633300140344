import styled from 'styled-components'
import IconButton from '@material-ui/core/IconButton'

const StyledIconButton = styled(IconButton)`
  && {
    margin-right: 10px;
    padding: 7px;
    color: ${props => props.theme.default.content.iconButton.iconColor};

    :hover {
      background-color: ${props => props.theme.default.content.iconButton.bgColorHover};
      color: ${props => props.theme.default.content.iconButton.iconColorHover};
    }
    
    .default { color: ${props => props.theme.default.content.iconButton.iconColorDefault}; }
    .save { color: ${props => props.theme.default.content.iconButton.iconColorSave}; }
    .cancel { color: ${props => props.theme.default.content.iconButton.iconColorCancel}; }
  }
`

export default StyledIconButton