import styled from 'styled-components';

export const StyledAvatar = styled.div`
    img {
        width: auto;
        max-width: 100%;
        max-height: 100%;
        object-fit: cover;
        background-color: ${props => props.theme.default.breadcrumb.bgColor};
    }
`;
