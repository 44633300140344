import React, { Component, Fragment } from 'react'
import { connect } from 'react-redux'
import Session from '../../utils/session-info'
import Toast from '../../components/toast/toast'
import Translate from '../../i18n/translate'
import Loading from '../../components/conector-ui/conector-loading'
import InternalPageTitle from '../../components/conector-ui/conector-internal-page-title'
import StyledPaper from '../../components/conector-ui/conector-styled-components/styled-paper'
import StyledButton from '../../components/conector-ui/conector-styled-components/styled-button'
import ConectorSelect from '../../components/conector-ui/conector-select'
import ConectorCheckbox from '../../components/conector-ui/conector-checkbox'
import ConectorInputText from '../../components/conector-ui/conector-input-text'
import Grid from '@material-ui/core/Grid'
import ConectorInputNumber from '../../components/conector-ui/conector-input-number'
import TextField from '@material-ui/core/TextField';
import { setBreadcrumb } from '../../store/app-state/actions'
import SchedulesService from './schedules-service'
import ConectorDatePicker from '../../components/conector-ui/conector-date-picker'

class ScheduleDetails extends Component {
    constructor(props) {
        super(props);
        
        let scheduleID = window.location.hash.substring(12)

        if (typeof (parseInt(scheduleID)) !== 'number' && scheduleID !== 'add') {
            window.location.replace('#/schedules')
        }

        props.setBreadcrumb([
            { label: 'integrations' },
            { label: 'schedules', url: '#/schedules' },
            { label: scheduleID !== 'add' ? 'edit_schedule' : 'add_schedule' }
        ]);

        this.state = {
            scheduleID: scheduleID,
            schedule: {
                datSchedule: new    Date(),
                customDays: 0,
                customHours: 0,
                customMinutes: 0,
                isActive: true
            },
            loading: true,
            validations: [],
            scheduleTypesList: [],
            pageTitle: ''
        }
    }

    componentWillMount() {
        let { scheduleID } = this.state

        this.getScheduleTypes()

        if (scheduleID !== "add") {
            this.getScheduleById(scheduleID)
        } else {
            this.setState({ loading: false, pageTitle: 'new_schedule' })
        }
    }

    getScheduleTypes() {
        SchedulesService.GetTypes().then(scheduleTypesList => {
            this.setState({
                scheduleTypesList: scheduleTypesList.map(type => ({
                    label: type.des_label,
                    value: type.id
                }))
            });
        });
    }

    getScheduleById(scheduleId) {
        SchedulesService
            .GetScheduleById(scheduleId)
            .then(schedule => {
                if (!schedule.codSchedule) {
                    Toast.error('Agendamento não encontrado');
                    this.goBack();
                } else {
                    this.setState({
                        schedule,
                        loading: false,
                        pageTitle: 'edit_schedule'
                    })
                }

            });
    }

    handleChange = e => {
 
        var { schedule, validations } = this.state,
            name = e.target.name,
            value = e.target.value

        switch (name) {
            case 'isActive':
                schedule.isActive = !schedule.isActive
                break
            case 'indContinuousVal':
                schedule.indContinuousVal = parseInt(value, 10)
                break
            default:
                schedule[name] = value
        }

        this.setState({
            schedule,
            validations: validations.filter(v => v !== name)
        })
    }

    handleChangeSelect(scheduleTypeValue) {
        var { schedule, validations } = this.state;

        schedule.codScheduleType = scheduleTypeValue.value

        if (scheduleTypeValue.value === 1) {
            schedule.indContinuousVal = 180;
        }
        else if(scheduleTypeValue.value === 7){
            schedule.indContinuousVal = 60;
        } 
        else {
            delete schedule.indContinuousVal;
        }

        this.setState({
            schedule,
            validations: validations.filter(v => v !== 'scheduleType')
        });
    }

    goBack() {
        window.location.replace('#/schedules')
    }

    save() {
        this.setState({ validations: [] }, _ => {
            var schedule = { ...this.state.schedule },
            validations = []

            if (!schedule.desSchedule) {
                validations.push('desSchedule')
            }

            if (!schedule.codScheduleType) {
                validations.push('scheduleType')
            }

            if (validations.length) {
                this.setState({ validations });
                return;
            }
            
            if (schedule.codScheduleType === 8) {
                if ((schedule.customDays === '' || schedule.customDays === 0) && (schedule.customHours === '' || schedule.customHours === 0)) {
                    if (schedule.customMinutes === '' || schedule.customMinutes === 0) {
                        Toast.info(Translate("fill_at_least_1_minute!"));
                        return; // Impede a continuação se a validação falhar
                    }
                }
            }            

            schedule = {
                ...schedule,
                codInstance: Session().codInstance
            };

            if (!schedule.codSchedule) {
                SchedulesService
                    .PostSchedule(schedule)
                    .then(onSuccess.bind(this));
            } else {
                SchedulesService
                    .PutSchedule(schedule)
                    .then(onSuccess.bind(this));
            }

            function onSuccess(res) {
                if (res.ok) {
                    Toast.success('record_success_saved');
                    setTimeout(this.goBack)
                } else {
                    Toast.error('error_saving_record');
                }
            }
        });
    }

    CustomDaysChange = (e) => {
        const value = parseInt(e.target.value, 10) || 0
    
        if (/^\d{0,3}$/.test(value) && (value === '' || (parseInt(value) >= 0 && parseInt(value) <= 365))) {
            this.setState({
                schedule: {
                    ...this.state.schedule,
                    customDays: value,
                },
            });
        }
    };

    CustomHoursChange = (e) => {
        const value = parseInt(e.target.value, 10) || 0

        if (/^\d{0,2}$/.test(value) && (value === '' || (parseInt(value) >= 0 && parseInt(value) <= 23))) {
            this.setState({
                schedule: {
                    ...this.state.schedule,
                    customHours: value,
                },
            });
        }
    };    
    
    CustomMinutesChange = (e) => {
        const value = parseInt(e.target.value, 10) || 0

        if (/^\d{0,2}$/.test(value)&& (value === '' || (parseInt(value) >= 0 && parseInt(value) <= 59))) {
            this.setState({
                schedule: {
                    ...this.state.schedule,
                    customMinutes: value,
                },
            });
        }
    };   

    render() {
        const {
            loading,
            schedule,
            scheduleTypesList,
            validations,
            pageTitle
        } = this.state;

        if (loading) {
            return (<Loading />)
        }

        const renderPersonalizados = schedule.codScheduleType === 8;

        return (
            <Fragment>
                <InternalPageTitle title={pageTitle} />
                <StyledPaper>
                    <Grid container={true} spacing={24}>
                        <Grid item={true} lg={6} md={8} xs={12} sm={12}>
                            <ConectorInputText
                                label={'description'}
                                help={'enter_description'}
                                name={"desSchedule"}
                                value={schedule.desSchedule}
                                onChange={this.handleChange.bind(this)}
                                required={true}
                                errorCondition={Boolean(validations.includes('desSchedule'))}
                            />
                        </Grid>
                    </Grid>
                    <Grid container={true} spacing={24}>
                        <Grid item={true} sm={6}>
                            <ConectorDatePicker
                                value={schedule.datSchedule}
                                onChange={this.handleChange.bind(this)}
                                showTime={true}
                                name='datSchedule'
                                help='enter_date'
                                label='date'
                            />
                        </Grid>
                        <Grid item xs={6}>
                            <ConectorSelect
                                label={'type'}
                                help={'enter_type'}
                                name="type"
                                placeholder={' '}
                                onChange={this.handleChangeSelect.bind(this)}
                                value={scheduleTypesList.find(option => option.value === schedule.codScheduleType)}
                                options={scheduleTypesList}
                                required={true}
                                errorCondition={Boolean(validations.includes('scheduleType'))}
                            />
                        </Grid>
                    </Grid>
                    <br></br>
                    <Grid container={true} spacing={24}>
                        {renderPersonalizados && (
                            <Grid item={true}  sm={2}>
                                <ConectorInputNumber 
                                label={'day(s)'}
                                required={false}
                                help={'maximum_limit_days'}
                                value={schedule.customDays}
                                onChange={this.CustomDaysChange.bind(this)}
                                name='days'
                                >
                                </ConectorInputNumber>
                            </Grid>
                        )}
                        {renderPersonalizados && (
                            <Grid item={true} sm={2}>
                                <ConectorInputNumber 
                                label={'hour(s)'}
                                required={false}
                                help={'maximum_limit_hours'}
                                value={schedule.customHours}
                                onChange={this.CustomHoursChange.bind(this)}
                                name='hours'
                                >
                                </ConectorInputNumber>
                            </Grid>
                        )}
                        {renderPersonalizados && (
                            <Grid item={true} sm={2}>
                                <ConectorInputNumber 
                                label={'minut(s)'}
                                required={false}
                                help={'maximum_limit_minutes'}
                                value={schedule.customMinutes}
                                onChange={this.CustomMinutesChange.bind(this)}
                                name='minutes'
                                >
                                </ConectorInputNumber>
                            </Grid>
                        )}
                    </Grid>
                    <br></br>
                    <Grid spacing={24} container={true}>
                        <Grid item xs={12} md={6}>
                            <ConectorCheckbox
                                name="isActive"
                                color="default"
                                value={schedule.isActive}
                                onChange={this.handleChange.bind(this)}
                                checked={schedule.isActive}
                                label={'active_schedule'}
                            />
                        </Grid>
                    </Grid>
                    <Grid spacing={24} container={true} justify="flex-end">
                        <Grid item>
                            <StyledButton
                                variant="contained"
                                onClick={this.goBack}
                                color="primary">
                                {Translate('cancel')}
                            </StyledButton>
                            <StyledButton
                                className="no-margin"
                                variant="contained"
                                onClick={this.save.bind(this)}>
                                {Translate('save')}
                            </StyledButton>
                        </Grid>
                    </Grid>
                </StyledPaper>
            </Fragment>
        )
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        setBreadcrumb: (steps) => dispatch(setBreadcrumb(steps))
    }
}

export default connect(null, mapDispatchToProps)(ScheduleDetails)