import styled from 'styled-components'
import { 
    Typography, 
    Grid,
    Card, 
    CardActions, 
    Button,
    CardActionArea
} from '@material-ui/core'
import ColorsTheme from '../../themes/colors/default-theme-colors';
import {
    StyledDialog
} from "../../components/conector-ui/conector-styled-components/styled-dialog";

export const IconContainer = styled(Button)`
    && {
        min-width     : 0;
        padding-top   : 0;
        padding-bottom: 0;
        color         : rgba(0,0,0,0.54);

        svg {
            &.play-button {
                color: #52B4B7;
            }

            &.pause-button {
                color: indianred;
            }
        }
    }
`

export const CardTitle = styled(Typography)`
    && {
        color      : rgba(0,0,0,0.54);
        font-family: "Roboto", "Helvetica", "Arial", sans-serif;
        font-weight: 600;
        white-space: nowrap;
        font-size  : 1.2rem;

        &.new-interface {
            font-size  : 1.5rem;
            white-space: normal;
        }
    }
`

export const CardBody = styled(Typography)`
    && {
        font-family: "Roboto", "Helvetica", "Arial", sans-serif;

        strong {
            color: rgba(0,0,0,0.54);
        }
    }
`

export const StyledCardActionArea = styled(CardActionArea)`
    && {
        min-height: 252px;
        display   : flex;
        text-align: center;

        > h2 {
            > svg {
                font-size: 50px;
            }
        }
    }
`

export const StyledCard = styled(Card)`
    && {
        overflow: visible;
        position: relative;
        border-left: 24px solid;
        
        &.without-border {
            border-left: 0;
        }

        &.running {
            border-color: ${ColorsTheme.green};
        }

        &.running-with-warning {
            border-color: ${ColorsTheme.pastelOrange};
        }

        &.stopped-by-user {
            border-color: ${ColorsTheme.slateGray};
        }

        &.stopped-by-critical-error {
            border-color: ${ColorsTheme.indianRed};
        }

        &.awaiting-execution{
            border-color: #454E9E;
        }

        &.new-interface {
            border-left: 0;
            opacity    : 0.5;
        }

        &.new-interface:hover {
            transition: 0.9s;
            opacity   : 1;
        }

        .MuiCardMedia-root {
            height: 0;
            padding-top: 56.25%;
        }

        .content-status-message {
            writing-mode: vertical-rl;
            position: absolute;
            top: 5px;
            left: -19px;
            color: #fff;
            width: 14px;
            font-family: Arial;
            font-size: 9pt;
            text-transform: uppercase;
        }
    }
`

export const StyledCardActions = styled(CardActions)`
    && {
        border-top    : 1px solid rgba(0,0,0,0.08);
        flex-direction: row-reverse;
        background    : #f7f7f7;
    }
`

export const StyledCardContainer = styled(Grid)`
    && {
        animation: fadein 0.8s;
        @keyframes fadein {
            from { opacity: 0; }
            to   { opacity: 1; }
        }
    }
`;

export const StyledDialogZIndex = styled(StyledDialog)`

    && {
        .MuiPaper-root {
            overflow-y: unset;
        }

        .MuiDialogContent-root {
            padding-top: 0;
            overflow-y: unset;
        }
    }

`;

export const StyledLoading = styled.div`

    && > div {
        height: 256px;
    }

`;