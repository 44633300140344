import React, { Component, Fragment } from "react";
import { connect } from "react-redux";
import * as appStateReducer from "../../../store/app-state/reducer";
import { setBreadcrumb } from "../../../store/app-state/actions";
import Translate from "../../../i18n/translate";

import Service from "./maintenance-service";
import MaintenanceHistory from "./maintenance-history";
import { Grid } from "@material-ui/core";
import StyledPaper from "../../../components/conector-ui/conector-styled-components/styled-paper";
import InternalPageTitle from "../../../components/conector-ui/conector-internal-page-title"

import Session from "../../../utils/session-info";
import ConectorDatePicker from "../../../components/conector-ui/conector-date-picker";
import StyledButton from "../../../components/conector-ui/conector-styled-components/styled-button";
import StyledInputLabel from "../../../components/conector-ui/conector-styled-components/styled-input-label";
import ConectorDialogConfirmation from "../../../components/conector-ui/conector-dialog-confirmation";
import ConectorInputText from "../../../components/conector-ui/conector-input-text";
import UserService from "../../users/user-service";
import Toast from "../../../components/toast/toast";
import moment from "moment";


class Maintenance extends Component {
    constructor(props) {
        super(props);

        props.setBreadcrumb([{
            label: "administration"
        }, {
            label: "maintenance"
        }]);
        
        this.state = {
            ...props,
            dialogOpenMaintenanceHistory:false,
            object: {
                datStartMaxInterval:new Date().toISOString(),
                datEndMaxInterval:new Date().toISOString(),
                justify:"",
                user: Session().codUser
            },

        };
    }

    async componentWillMount() {

        const hasPrivilege = await UserService.FindPrivilege(Session().codUser, 1);
        this.setState({ hasPrivilege });
        
    }

    handleChangeSelectOption = (connectionTypeValue) => {
        let { optionSelect } = this.state;

        optionSelect = connectionTypeValue.value

        this.setState({ optionSelect }, () => {
            this.hendleSeach()
        });

    }

    handleChangeDate = e => {
        let object = { ...this.state.object };
        object[e.target.name] = e.target.value.replace("Z", "999Z");

        this.setState({ object });
    }
    handleChange = e => {
        let object = { ...this.state.object };
        object[e.target.name] = e.target.value;

        this.setState({ object });
    }
    dismissModal() {
		this.setState({ confirmationModalOptions: { open: false } });
	}
    start = _ => {
        let object = { ...this.state.object }

        Service.PostCount(object).then(count => {
            if(count === 0 ){
                Toast.error("Nenhum templates encontrado dentro do filtro")
                return;
            }

            const msg = "Foram encontrados "+ count +" templates dentro do filtro, Deseja realmente iniciar?"
            this.setState({
                confirmationModalOptions: {
                    type: "danger",
                    message: msg,
                    title: "confirmExecute",
                    open: true,
                    onDismiss: this.dismissModal.bind(this),
                    onClose: this.startConfirmation.bind(this)
                }
            });
        }).catch(_ => {
            Toast.error(Translate(_))
        })

    }
    startConfirmation = _ => {
        let object = { ...this.state.object }
        this.setState({ confirmationModalOptions: { loading: true } });
        Service.PostExecute(object).then(_ => {
            this.setState({ 
                confirmationModalOptions:{ open: false,loading: false},
                object: {
                    datStartMaxInterval:new Date().toISOString(),
                    datEndMaxInterval:new Date().toISOString(),
                    justify:"",
                    user: Session().codUser
                }, });
            Toast.success(Translate("success_maintenance"))
        }).catch(_ => {
            Toast.error(Translate("error_maintenance"))
            this.setState({ confirmationModalOptions: { loading: false } });
        })
    }

    handleOpenMaintenanceHistory = () => {
        this.setState({ dialogOpenMaintenanceHistory: true });
    }

    handleDismissMaintenanceHistory = () => {
        this.setState({dialogOpenMaintenanceHistory: false});
    }
    saveDisabled = () => {
        const {
            datStartMaxInterval,
            datEndMaxInterval,
            justify
        } = this.state.object;

        if (datStartMaxInterval && datEndMaxInterval) {
            const startMaxInterval = moment(datStartMaxInterval);
            const endMaxInterval = moment(datEndMaxInterval);
            const maxInterval = moment.duration(endMaxInterval.diff(startMaxInterval));
            const diferenca = Math.floor(maxInterval.asSeconds());
            if(diferenca <= 0)
                return true;
        }

        if(justify == "" || justify.length <= 5)
            return true;

        return false;
    }
    render() {
        const { object,confirmationModalOptions,hasPrivilege,dialogOpenMaintenanceHistory } = this.state;
        return (
            <Fragment>
                <InternalPageTitle title={Translate("maintenance")} />
                <StyledPaper>
                    <InternalPageTitle title={Translate("start_interfaces")} />
                    <StyledInputLabel >{Translate("maintenanceStart")}</StyledInputLabel>
                    <InternalPageTitle />
                        <Grid spacing={24} container>
                            <Grid item xs={6} md={4}>
                                <ConectorDatePicker
                                    value={object.datStartMaxInterval}
                                    required={true}
                                    onChange={this.handleChangeDate.bind(this)}
                                    showTime={true}
                                    name="datStartMaxInterval"
                                    label="Intervalo Inicial"
                                />
                            </Grid>
                            <Grid item xs={6} md={4}>
                                <ConectorDatePicker
                                    value={object.datEndMaxInterval}
                                    minDate={object.datStartMaxInterval}
                                    required={true}
                                    onChange={this.handleChangeDate.bind(this)}
                                    showTime={true}
                                    name="datEndMaxInterval"
                                    label="Intervalo Final"
                                />
                            </Grid>
                        </Grid>
                        <Grid item xs={6} md={4}>
                                <ConectorInputText label="justify"
                                    required={true}
                                    placeholder="justify"
                                    help={"Informe o motivo para a manutenção"}
                                    onChange={this.handleChange}
                                    name="justify"
                                    value={object.justify}
                                />
                        </Grid>
                        <Grid container style={{ marginTop: 24, display: 'flex', justifyContent: 'space-between' }}>
                            <StyledButton
                                className="no-margin"
                                variant="contained"
                                disabled={this.saveDisabled()}
                                onClick={this.start}>
                                {Translate("execute")}
                            </StyledButton>
                            {hasPrivilege &&
                                    <StyledButton 
                                        variant="contained"
                                        onClick={this.handleOpenMaintenanceHistory}
                                        color="primary"
                                        >
                                        {Translate("maintenance_history")}
                                    </StyledButton>
                            }
                        </Grid>
                        
                </StyledPaper>
                    {
                        (() => {
                            if (dialogOpenMaintenanceHistory) {
                               return <MaintenanceHistory name={"start_interfaces"} onDismiss={this.handleDismissMaintenanceHistory.bind(this)} />
                            }
                        })()
                    }
                <ConectorDialogConfirmation
					dialogOptions={confirmationModalOptions}
				/>
            </Fragment>
        );
    }
}

const mapStateToProps = store => {
	return {
		permission: appStateReducer.getUserPermissionByView(store)
	}
}

const mapDispatchToProps = dispatch => {
    return {
        setBreadcrumb: (steps) => dispatch(setBreadcrumb(steps))
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(Maintenance);
