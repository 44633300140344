import styled from "styled-components";
import SortableTree from "react-sortable-tree";

import {
    Tabs,
    Dialog,
    IconButton
} from "@material-ui/core";
import { darken } from "@material-ui/core/styles/colorManipulator";

import StyledButton from "../../../components/conector-ui/conector-styled-components/styled-button";

export const StyledDialog = styled(Dialog)`

    && {
        z-index: 1300;

        &>.MuiDialog-container {
            &>.MuiPaper-root {
                overflow: visible;
                
                &>.MuiDialogContent-root {
                    overflow: visible;
                }

                & > .MuiDialogActions-root {
                    margin: 8px 24px 24px;

                    .MuiButtonBase-root:last-child {
                        margin-right: 0;
                    }
                }
            }
        }
    }

`;

export const StyledSortableTree = styled(SortableTree)`

    && {
        overflow: auto;
        background-color: #fff;
        border: 1px solid #B8C6D6;
        border-radius: 4px;
        padding-right: 8px;

        > div { height: auto !important; }
        &.rst__tree > div > div.ReactVirtualized__Grid.ReactVirtualized__List { 
            height: auto !important;
            min-height: calc(100vh - 690px);
            ::-webkit-scrollbar { height: 8px; }
            ::-webkit-scrollbar-track { 
                -webkit-border-radius: 4px; 
                background: #f5f5f5; 
                border-radius: 4px; 
            }
            ::-webkit-scrollbar-thumb { 
                -webkit-border-radius: 4px; 
                border-radius: 4px; 
                background: #009cdf;
            }
            ::-webkit-scrollbar-thumb:window-inactive { background-color: #009cdf; }
        }

        .rst__moveHandle { 
            border: 0;
            box-shadow: 0 0;
            background-color: #B8C6D6;
            border-radius: 2px 0 0 2px;
            &:hover { background-color: ${darken("#B8C6D6", 0.2)}; }
        }

        .rst__rowContents { 
            min-width: 30px;
            box-shadow: 0 0;
            border: 1px solid #B8C6D6;
            border-radius: 0 2px 2px 0;
            cursor: pointer;
            &:hover {
                background-color: #f5f5f5;
            }

            &:hover .content-transform-label {
                background-color: #f5f5f5;
            }
        }

        .rst__node { height: 59px !important; }
        .rst__collapseButton, .rst__expandButton { box-shadow: 0 0 0 1px #009CDF; }
        .rst__rowLabel { padding-right: 10px; color: #4C637B; }
        .rst__lineBlock, .rst__lineChildren { &::after, &::before { background-color: #B8C6D6; } }
        .rst__toolbarButton {
            button { 
                color: #6F8296;
                &:hover {
                    color: #009CDF;
                    background-color: transparent;    
                }
            }
        }

        .rst__rowTitle {
            font-size: 12px;
            font-weight: normal;
            font-family: "Roboto", "Helvetica", "Arial", sans-serif;
        }

        .rst__row {
            &.content-field-fixed-value {
                min-width: 97px;
            }

            &.content-field-case {
                min-width: 62px;
            }

            &.content-field-concat {
                min-width: 124px;
            }

            & .content-transform-label {
                width: auto;
                padding: 0 5px;
                height: 16px;
                font-size: 8pt;
                position: absolute;
                top: -8px;
                right: -1px;
                font-weight: bold;
                line-height: 16px;
                margin: 0 auto;
                border-top-left-radius: 4px;
                border-top-right-radius: 4px;
                border-right: 1px solid #b8c6d6;
                text-transform: uppercase;
            }

            &:not(.selected):not(:hover) {
                & .content-transform-label {
                    background-color: #FFF;
                }
            }

            &:not(.selected) {
                & .content-transform-label {
                    color: #4C637B;
                    border-top: 1px solid #b8c6d6;
                    border-left: 1px solid #b8c6d6;

                    &:before {
                        content: "";
                        position: absolute;
                        width: 1px;
                        height: 9px;
                        background-color: #FFF;
                        left: -1px;
                        bottom: 0;
                    }
                }
            }

            &.selected {
                & .content-transform-label {
                    color: #FFF;
                    background-color: #4c637b;
                }

                .rst__rowContents {
                    cursor: default;
                    background-color: #4C637B;
                    .rst__rowLabel { color: #fff; }
                    .rst__toolbarButton {
                        button { 
                            color: #fff;
                            &:hover { color: #fff; }
                        }
                    }
                }
            }
        }

        &.bg_darken_04 {
          background-color: ${props => darken(props.theme.default.content.paper.bgColor, 0.04)};
        } 

        ::-webkit-scrollbar-corner { background-color: #eee; }
        ::-webkit-scrollbar {
            width: 6px;
            height: 6px;
            background-color: #eee;
            cursor: pointer;
        }

        ::-webkit-scrollbar-thumb {
            border-radius: 10px;
            background-color: rgba(0, 0, 0, 0.2);
            cursor: pointer;
        }
    }

`;

export const StyledIconButton = styled(IconButton)`

    && {
        padding: 6px 6px 6px 3px;

        > span[class^="MuiIconButton-label-"] {
            font-size: 12px;

            > svg {
                margin-right: 3px;
            }
        }
    }

`;

export const StyledGridButton = styled(StyledButton)`

    && { 
        min-width: 50px;
        margin: 0 20px 20px 0;

        &:last-child {
            margin-right: 0;
        }

        @media screen and (max-width:500px) {
            min-width: calc(50% - 13px);
            &:last-child { min-width: calc(100% - 15px); }
        }
    }

`;

export const StyledTabs = styled(Tabs)`

    && {
        width: 100%;
        overflow: visible;

        button {
            overflow: visible;

            color: #6F8296;
            font-size: 0.75rem;

            border-top: 1px solid ${props => darken(props.theme.default.content.paper.bgColor, 0.04)};
            border-left: 1px solid ${props => darken(props.theme.default.content.paper.bgColor, 0.04)};
            border-right: 1px solid ${props => darken(props.theme.default.content.paper.bgColor, 0.04)};

            &:hover, &.MuiTab-selected {
                background-color: #fff;
                border-top: 1px solid #B8C6D6;
                border-left: 1px solid #B8C6D6;
                border-right: 1px solid #B8C6D6;
                border-top-left-radius: 4px;
                border-top-right-radius: 4px;

                &:after {
                    content: "";
                    width: 100%;
                    height: 3px;
                    position: absolute;
                    bottom: -3px;
                    background-color: #fff;
                    z-index: 1;
                }

                &:first-child:after {
                    left: -1px;
                    border-left: 1px solid #B8C6D6;
                }
            }
        }

        .MuiTabs-fixed {
            overflow-x: visible;
        }

        .MuiTabs-indicator {
            height: 0;
            background-color: #B8C6D6;
        }
    }

`;

export const StyledContainer = styled.div `

    &&.content-fixed {
        position: sticky;
        top: 0;
    }

`;
