import React, { Component, Fragment } from 'react'
import { Grid, Card, CardContent } from '@material-ui/core'
import styled from 'styled-components'
import StyledPaper from '../../components/conector-ui/conector-styled-components/styled-paper'
import MonitoramentoService from './monitoring-service'
import Translate from '../../i18n/translate'
import ConectorSelect from "../../components/conector-ui/conector-select"
import { connect } from 'react-redux'
import { setBreadcrumb } from '../../store/app-state/actions'
import ConectorGrid from '../../components/conector-ui/conector-grid/conector-grid'
import Toast from '../../components/toast/toast'
import Session from "../../utils/session-info";

class Monitoramento extends Component {
    constructor(props) {    
        super(props)
        props.setBreadcrumb([
            { label: 'Monitoramento' },
            { label: 'Dashboard' }
        ])
        this.state = {
            gridOptions: {
                multiSelect: false,
                hideBtnNew: true,
                hideBtnEdit: true,
                hideBtnDelete: true,
                hideBtnCopy: true,
                hideFilter: true,
                columns: [
                {
                    title: Translate("company"),
                    field: "DesCompany"
                }, 
                {
                    title: Translate("instance"),
                    field: "DesInstance"
                }, 
                {
                    title: Translate("template"),
                    field: "DesInstanceInterface"
                },
                {
                    title: Translate("last_execution_date"),
                    field: "DatLastExecution",
                    cellFilter: "ddMMyyyy-hh:mm"
                }]
            },
            interfacesStatus: {
                success: new Array,
                warning: new Array,
                error: new Array
            },
            timerOptions: [
            {
                id: 1,
                label: "3 Minutos"
            },
            {
                id: 2,
                label: "5 Minutos"
            },
            {
                id: 3,
                label: "10 Minutos"
            },
            {
                id: 4,
                label: "15 Minutos"
            },
            {
                id: 5,
                label: "30 Minutos"
            }],
            dateOptions: [
            {
                id: 0,
                label: "Sem Filtro"
            }, 
            {
                id: 1,
                label: "1 dia"
            }, 
            {
                id: 2,
                label: "7 dias"
            }, 
            {
                id: 3,
                label: "30 dias"
            }],
            dateOptionSelected: 0,
            timerOptionSelected: 1,
            instanceCompanyOptionSelected: 0,
            instancesByCompaniesOptions : [],
            instanceCompanyDataSource: null,
            intervalTimerInstance: null
        }
    }

    componentWillMount(){
        MonitoramentoService.GetInstanceBlockById(Session().codInstance)
        .then(res => {
            if(res.id !== 0){
                this.updateSession(true)
                Toast.error(Translate("instanceBlockedByAdministrative"), true)
            }
            else{
                this.updateSession(false)
            }
        })

        let { instanceCompanyOptionSelected, 
              dateOptionSelected } = this.state
        
        MonitoramentoService.GetTemplateStatusByDate(dateOptionSelected)
        .then(res => res.json())
        .then(returnedData => {
            this.separateStatusInterfaces(instanceCompanyOptionSelected, returnedData)
        }).catch(() => {})

        this.timerToUpdate()
    }

    updateSession(isBlock) {
        const session = Session();
        session.instanceBlock = isBlock;
        localStorage.setItem("user", JSON.stringify(session));
    }

    separateStatusInterfaces = (codInstance, dataSource) => {
        let { interfacesStatus } = this.state

        interfacesStatus.success = []
        interfacesStatus.warning = []
        interfacesStatus.error = []

        dataSource.forEach((instanceObject) => {
            instanceObject.InstanceInterface.forEach((interfaceObject) => {
                if(codInstance == 0 || codInstance == interfaceObject.CodInstance)
                    switch(interfaceObject.CodInterfaceStatus) {
                        case 1:
                            interfaceObject.DesCompany = instanceObject.DesCompany
                            interfaceObject.DesInstance = instanceObject.DesInstance
                            interfacesStatus.success.push(interfaceObject)
                        break;

                        case 2:
                            interfaceObject.DesCompany = instanceObject.DesCompany
                            interfaceObject.DesInstance = instanceObject.DesInstance
                            interfacesStatus.warning.push(interfaceObject)
                        break;

                        case 3:
                            interfaceObject.DesCompany = instanceObject.DesCompany
                            interfaceObject.DesInstance = instanceObject.DesInstance
                            interfacesStatus.error.push(interfaceObject)
                        break;
                    }
            })
        })

        this.createInstancesByCompaniesOptions(dataSource)

        this.setState({ interfacesStatus, 
                        instanceCompanyDataSource: dataSource })
    }

    createInstancesByCompaniesOptions = (dataSource) => {
        let Options = [{id: 0, label: "Sem Filtro"}]

        dataSource.forEach((instanceObject) => {
            Options.push({
                id: instanceObject.CodInstance, 
                label: `${instanceObject.DesCompany} - ${instanceObject.DesInstance}` 
            })
        })

        this.setState({ instancesByCompaniesOptions: Options })
    }

    changeOptionFilterDate = (optionSelect) => {
        let { instanceCompanyOptionSelected, timerOptionSelected } = this.state

        MonitoramentoService.GetTemplateStatusByDate(optionSelect.id)
        .then(res => res.json())
        .then(returnedData => {
            this.separateStatusInterfaces(instanceCompanyOptionSelected, returnedData)
        }).catch(() => Toast.error(Translate("errorMonitoringData")))

        this.timerToUpdate(timerOptionSelected, instanceCompanyOptionSelected, optionSelect.id)
    }

    changeOptionInstanceCompany = (optionSelect) => {
        let { instanceCompanyDataSource, timerOptionSelected } = this.state

        this.separateStatusInterfaces(optionSelect.id, instanceCompanyDataSource)
        this.timerToUpdate(timerOptionSelected, optionSelect.id)
    }

    timerToUpdate(optionSelect = this.state.timerOptionSelected, 
                  codInstance = this.state.instanceCompanyOptionSelected, 
                  dateOption = this.state.dateOptionSelected){

        let millisecondsTimer
        let { intervalTimerInstance } = this.state

        clearInterval(intervalTimerInstance)

        switch(optionSelect) {
            case 1:
                millisecondsTimer = 180000 // 3 minutes
                break;
            case 2:
                millisecondsTimer = 300000 // 5 minutes
                break;
            case 3:
                millisecondsTimer = 600000 // 10 minutes
                break;
            case 4:
                millisecondsTimer = 900000 // 15 minutes
                break;
            case 5:
                millisecondsTimer = 1800000 // 30 minutes
                break;
        }

        let intervalTimer = setInterval(() => {
            MonitoramentoService.GetTemplateStatusByDate(dateOption)
            .then(res => res.json())
            .then(returnedData => {
                this.separateStatusInterfaces(codInstance, returnedData)
            }).catch(() => Toast.error(Translate("errorMonitoringData")))
        }, millisecondsTimer)

        this.setState({ intervalTimerInstance: intervalTimer, 
                        timerOptionSelected: optionSelect,
                        instanceCompanyOptionSelected: codInstance,
                        dateOptionSelected: dateOption })
    }

    render() {
        const { interfacesStatus, 
                timerOptions, 
                dateOptions,
                instancesByCompaniesOptions,
                dateOptionSelected,
                timerOptionSelected,
                instanceCompanyOptionSelected,
                gridOptions
            } = this.state;
        
        const CardTitle = styled.span`
            font-size: 11rem;
            font-weight: bold;
            margin-bottom: 10px;
        `
        
        const CardDescription = styled.span`
        && {
            font-size: 18px;
            bottom: 15px;
            position: absolute;
        }
        `
        
        const DashCard = styled(Card)`
            && {
                height: 200px;
                > div {
                    position: relative;
                    justify-content: center;
                    padding: 0 !important;
                    display: flex;
                    flex-direction: column;
                    align-items: center;
                    font-family: "Roboto", "Helvetica", "Arial", sans-serif;
                    text-align: center;
                    border-left: 6px solid;
                    height: 200px;
                }
            }
        `

        const StyledGrid = styled(Grid)`
        && {
                fontSize: 10%;
                font-weight: bold;
                position: relative;
                height: 100%;
                > div:nth-child(3) {
                    width: calc(100% - 24px);
                    height: calc(100% - 24px);
                    top: 12px;
                    position: absolute;
                    z-index: 3;
                    background-color: #FFF;
                }
            }
        `;

        return (
            <Fragment>
                <StyledPaper>
                    <Grid spacing={24} container alignItems={"center"}>
                        <Grid item xs={12} md={6} xl={4}>
                            <ConectorSelect
                                label="instance_by_company"
                                name="codInstanceInterface"
                                placeholder="choose"
                                options={instancesByCompaniesOptions}
                                value={instancesByCompaniesOptions.find(e => e.id === instanceCompanyOptionSelected) || null}
                                onChange={this.changeOptionInstanceCompany}
                                
                            />
                        </Grid>
                        <Grid item xs={2} md={2} xl={2}>
                            <ConectorSelect
                                label="timer"
                                name="timers"
                                options={timerOptions}
                                value={timerOptions.find(e => e.id === timerOptionSelected) || null}
                                onChange={(e) => this.timerToUpdate(e.id)}
                            /> 
                        </Grid>
                        <Grid item xs={2} md={2} xl={2}>
                            <ConectorSelect
                                label="filter_date"
                                name="timers"
                                options={dateOptions}
                                value={dateOptions.find(e => e.id === dateOptionSelected) || null}
                                onChange={this.changeOptionFilterDate}
                            /> 
                        </Grid>
                    </Grid>
                    <Grid spacing={24} container={true}>
                        <Grid container={true} spacing={24}>
                            <Grid item={true} lg={12} md={12} sm={12}>
                                <div className="card-row">
                                    <div className="card-item">
                                        <DashCard className="dash-card interface-executions">
                                            <CardContent>
                                                <CardTitle>{interfacesStatus.success.length}</CardTitle>
                                                <CardDescription>{Translate("successful_runs")}</CardDescription>
                                            </CardContent>
                                        </DashCard>
                                    </div>
                                    <div className="card-item">
                                        <DashCard className="dash-card interface-pending">
                                            <CardContent>
                                                <CardTitle>{interfacesStatus.warning.length}</CardTitle>
                                                <CardDescription>{Translate("running_reservations")}</CardDescription>
                                            </CardContent>
                                        </DashCard>
                                    </div>
                                    <div className="card-item">
                                        <DashCard className="dash-card interface-errors">
                                            <CardContent>
                                                <CardTitle>{interfacesStatus.error.length}</CardTitle>
                                                <CardDescription>{Translate("running_error")}</CardDescription> 
                                            </CardContent>
                                        </DashCard>
                                    </div> 
                                </div> 
                            </Grid>
                        </Grid>
                    </Grid>
                    <Grid container={true} spacing={24}>
                        <StyledGrid item={true} xl={6} lg={6} md={6} sm={6}>
                            <ConectorGrid
                                gridTitle={Translate("listWarning")}
                                gridOptions={gridOptions}
                                dataSource={interfacesStatus.warning}>
                            </ConectorGrid>
                        </StyledGrid>
                        <StyledGrid item={true} xl={6} lg={6} md={6} sm={6}>
                            <ConectorGrid
                                gridTitle={Translate("listError")}
                                gridOptions={gridOptions}
                                dataSource={interfacesStatus.error}>
                            </ConectorGrid>
                        </StyledGrid>
                    </Grid>
                </StyledPaper>
            </Fragment>
        )
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        setBreadcrumb: (steps) => dispatch(setBreadcrumb(steps)),
    }
}

export default connect(null, mapDispatchToProps)(Monitoramento);