import ConectorSelect from '../../components/conector-ui/conector-select'
import ConectorCheckbox from '../../components/conector-ui/conector-checkbox'
import ConectorInputText from '../../components/conector-ui/conector-input-text'
import ConectorInputNumber from '../../components/conector-ui/conector-input-number'
import ConectorDatePicker from '../../components/conector-ui/conector-date-picker'

export default (codAttributeType) => {
    switch(codAttributeType) {
        case 1 : return ConectorInputText
        case 2 : return ConectorInputNumber
        case 3 : return ConectorInputNumber
        case 4 : return ConectorDatePicker
        case 5 : return ConectorCheckbox
        case 7 : return ConectorSelect
        case 'type_from' : return ConectorInputText
        default: return ConectorInputText
    }
}