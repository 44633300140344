import React, { Component, Fragment } from 'react';
import DatePicker, { registerLocale } from 'react-datepicker'
import { FormControl } from '@material-ui/core';
import Translate from '../../i18n/translate';
import ptBR from 'date-fns/locale/pt-BR';
import StyledInputLabel from './conector-styled-components/styled-input-label'
import StyledFormHelpText from './conector-styled-components/styled-form-help-text'
import styled from 'styled-components'
import 'react-datepicker/dist/react-datepicker.css';

const StyledFormControl = styled(FormControl)`
    && {
        width: 80%;

        .react-datepicker__input-container {
            display: grid;

            input {
                border: 1px solid #B8C6D6;
                color: #6F8296;
                border-radius: 5px;
                padding: 11.5px 14px;
                font-size: inherit;

                &.react-datepicker-ignore-onclickoutside {
                    outline: none;
                    border: 2px solid #3f51b5;
                    padding: 10.5px 13px;
                }
            }
        }
        .react-datepicker-popper{
            z-index:5;
        }
    }
` 

registerLocale('pt-BR', ptBR);

class ConectorDatePicker extends Component {
    constructor(p) {
        super(p);
        this.state = {};
    }

    onChange(e) {
        if (this.props.onChange) {
            this.props.onChange({
                target: {
                    value: e ? e.toISOString() : null,
                    name: this.props.name
                }
            });
        }
    }

    onBlur(e) {
        if (this.props.onBlur) {
            this.props.onBlur(e);
        }
    }

    renderPicker() {
        const {
            name,
            placeholder,
            showTime,
            value,
            minDate,
            disabled,
            required,
            excludeTimes
        } = this.props;

        let className = 'conector-date-picker';

        if (showTime) {
            return (
                <DatePicker className={className}
                    showTimeSelect
                    excludeTimes={excludeTimes}
                    timeFormat="HH:mm:ss"
                    name={name}
                    onChange={this.onChange.bind(this)}
                    onBlur={this.onBlur.bind(this)}
                    placeholder={placeholder}
                    dateFormat="dd/MM/yyyy HH:mm:ss"
                    selected={value ? new Date(value) : null}
                    minDate={minDate ? new Date(minDate) : null}
                    disabled={disabled}
                    required={required}
                    locale="pt-BR"
                    todayButton="Hoje"
                    timeCaption="Hora"
                />
            )
        }

        return (
            <DatePicker className="conector-date-picker"
                name={name}
                onChange={this.onChange.bind(this)}
                onBlur={this.onBlur.bind(this)}
                placeholder={placeholder}
                dateFormat="dd/MM/yyyy"
                selected={value ? new Date(value) : null}
                minDate={minDate ? new Date(minDate) : null}
                required={required}
                disabled={disabled}
                locale="pt-BR"
                todayButton="Hoje"
            />
        )
    }

    render() { 
        const { 
            label,
            help,
            required
        } = this.props;

        let className = 'conector-date-picker';
        if (required) {
            className = `${className} required`;
        }

        return (    
            <Fragment>
                <StyledFormControl>
                    <StyledInputLabel className={className}>{Translate(label)}</StyledInputLabel>
                    { this.renderPicker() }
                    <StyledFormHelpText>{Translate(help)}</StyledFormHelpText>      
                </StyledFormControl>
            </Fragment>
        );
    }
}

export default ConectorDatePicker;