import React, {
    Component,
    Fragment
} from "react";
import { connect } from "react-redux";

import Translate from "../../i18n/translate";
import Session from "../../utils/session-info";

import Toast from "../../components/toast/toast";
import Loading from "../../components/conector-ui/conector-loading";
import ConectorGrid from "../../components/conector-ui/conector-grid/conector-grid";
import ConectorDialogConfirmation from "../../components/conector-ui/conector-dialog-confirmation";

import { setBreadcrumb } from "../../store/app-state/actions";
import * as appStateReducer from "../../store/app-state/reducer";

import SchedulesService from "./schedules-service";

import ScheduleDetails from "./schedule-details"//"./schedules/schedule-details";

class Schedules extends Component {

	constructor(props) {
        super(props);

		props.setBreadcrumb([{
            label: "integrations"
        }, {
            label: "schedules"
        }]);

		this.state = {
			dialogOptions: {},
			open: false,
			loading: true,
			schedulesList: [],
			scheduleTypesList: [],
			gridOptions: {
				multiSelect: true,
				hideBtnCopy: true,
				sortField: "desSchedule",
				columns: [{
                    title: "description",
                    field: "desSchedule"
                }, {
                    title: "type",
                    field: "scheduleTypesDesc"
                }, {
                    title: "initial_date",
                    field: "datSchedule",
                    cellFilter: "ddMMyyyy-hh:mm"
                }, {
                    title: "active",
                    field: "isActive",
                    cellFilter: "boolean",
                    hideFilter: true
                }]
			},
			scheduleServiceRequestCompleted: false
		}
	}

	componentWillMount() {
		this.getSchedules();
		this.getTypes();
	}

	async componentDidMount() {
        await this.updateButtonVisibility();
    }

	async updateButtonVisibility() {
        const res = await SchedulesService.GetUserPermissionSchedule();
        const { gridOptions } = this.state;
        const updatedGridOptions = {
            ...gridOptions,
            hideBtnNew: !res,
            hideBtnEdit: !res,
            hideBtnDelete: !res
        };

        this.setState({
            gridOptions: updatedGridOptions,
            scheduleServiceRequestCompleted: true,
        });
    }

	getSchedules() {
		SchedulesService.GetSchedules().then(
			schedules => {
				this.setState({ schedulesList: schedules }, this.setSchedulesTypeDescriptions);
			});
	}

	getTypes = _ => {
		SchedulesService.GetTypes().then(scheduleTypesList => {
			this.setState({ scheduleTypesList }, this.setSchedulesTypeDescriptions);
		});
	}

	setSchedulesTypeDescriptions() {
		var { schedulesList, scheduleTypesList } = this.state;

		if (!scheduleTypesList.length) {
			return;
		}

		schedulesList.forEach(schedule => {
			const scheduleType = scheduleTypesList.find(type =>
				type.id === schedule.codScheduleType
			);

			schedule.scheduleTypesDesc = scheduleType.des_label;
		});

		this.setState({ schedulesList, loading: false });

	}

	onDelete(schedules) {
		this.setState({
			dialogOptions: {
				type: "danger",
				open: true,
				message: Translate("remove_records"),
				title: Translate("exclusion_confirmation"),
				onClose: _ => {
					this.onDeleteConfirmation(schedules)
				}
			}
		})
	}

	onDeleteConfirmation(selectedSchedules) {
		const schedulesToDelete = selectedSchedules.map(item => {
			return {
				CodSchedule: item.codSchedule,
				CodInstance: Session().codInstance,
				DesSchedule: item.desSchedule
			}
		})

		SchedulesService
			.DeleteScheduleArray(schedulesToDelete)
			.then(res => {
				res.json().then(
					({ msg }) => {
						if (res.ok) {
							Toast.success("records_success_removed");
						} else {
							if(res === "Token does not have access to this instance"){
								msg = "userWithoutPermissionInstance"
							} else {
								if (!msg) {
									msg = "error_remove_records"
								}
							}

							Toast.error(msg);
						}

						this.getSchedules();
					}
				)
			});

		this.setState({
			dialogOptions: { open: false }
		});
	}

	newOrEditSchedule = (selectedRow = "add") => {

		if (selectedRow instanceof Array && selectedRow.length) {
			selectedRow = selectedRow[0].codSchedule
		} else if (selectedRow instanceof Object) {
			selectedRow = selectedRow.codSchedule
		}
		
		window.location.replace(`#/schedules/${selectedRow}`)
	}

	render() {
		const { schedulesList, gridOptions, dialogOptions, loading,scheduleServiceRequestCompleted } = this.state;

		if (!scheduleServiceRequestCompleted) {
			return (<Loading />)
		}

		return (
			<Fragment>
				<ConectorGrid
					gridTitle={Translate("schedule_list")}
					gridBtn={Translate("new_schedule")}
					gridNewItem={this.newOrEditSchedule}
					gridEditItem={this.newOrEditSchedule}
					gridDeleteItem={this.onDelete.bind(this)}
					gridOptions={gridOptions}
					dataSource={schedulesList}>
				</ConectorGrid>
				<ConectorDialogConfirmation dialogOptions={dialogOptions} />
			</Fragment>
		)
	}
}

const mapStateToProps = store => {
	return {
		permission: appStateReducer.getUserPermissionByView(store)
	}
}

const mapDispatchToProps = (dispatch) => {
	return {
		setBreadcrumb: (steps) => dispatch(setBreadcrumb(steps))
	}
}

export default connect(mapStateToProps, mapDispatchToProps)(Schedules);