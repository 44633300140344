import React, { Component, Fragment } from "react";
import Translate from "../../../i18n/translate";
import StyledButton from "../../../components/conector-ui/conector-styled-components/styled-button";
import { DialogContent, Grid, DialogActions } from "@material-ui/core";
import { StyledDialog, StyledDialogTitle } from "../../../components/conector-ui/conector-styled-components/styled-dialog";
import ConectorGrid from "../../../components/conector-ui/conector-grid/conector-grid";
import { CSVLink } from 'react-csv';
import Session from '../../../utils/session-info'

import LogService from "./template-history-service";

class TemplateHistoryInterface extends Component {
    constructor(props) {
        super(props);
        
        this.state = {
            ...props,
            dataSource: [],
            id: props.id,
            gridOptions: {
				multiSelect: false,
                hideBtnNew: true,
                hideBtnEdit: true,
                hideBtnDelete: true,
                hideBtnCopy: true,
                hideFilter: true,
				columns: [{
                    title: "company",
                    field: "companyLog"
                }, {
                    title: "instance",
                    field: "instanceLog"
                }, {
                    title: "desinterface",
                    field: "desinterface"
                }, {
                    title: "event",
                    field: "event"
                }, {
                    title: "status",
                    field: "statusLog"
                }, {
                    title: "user",
                    field: "userresponsable"
                }, {
                    title: "Modificado Em",
                    field: "datechange",
                    cellFilter: "ddMMyyyy-hh:mm"
                }]
			}
        };
    }

    componentWillMount = () => {
        const { id  } = this.state;
        LogService.GetAllByTemplateHistory(id).then(dataSource => {
            if(dataSource){
                dataSource = dataSource.filter(item => item.event = item.event.deseventname)
                dataSource.map(item => {
                    delete item.id
                    delete item.codinstance
                    delete item.interfaceId
                })
                this.setState({ dataSource });
            }
        });
        
    };

    handleTable = () => {
        
        const {
            dataSource,
            gridOptions
        } = this.state;
        if (dataSource){
            return(
                <Fragment>
                    <ConectorGrid 
                        gridOptions={gridOptions} 
                        dataSource={dataSource}
                    ></ConectorGrid>
                </Fragment>
            )
        }
    }

    render() {
        const { dataSource } = this.state;

        return (
            <StyledDialog
                open={true}
                disableEscapeKeyDown={false}
                disableBackdropClock={false}
                fullWidth={false}
                maxWidth={"lg"}
            >
                <StyledDialogTitle className="info">
                    {Translate("template_history")}
                </StyledDialogTitle>
                <DialogContent>
                <Grid  alignItems={"center"} lg={12} md={12} >
                    {this.handleTable()}
                </Grid>
                </DialogContent>
                <DialogActions>
                    <StyledButton
                        color="primary"
                        variant="contained"
                        onClick={this.props.onDismiss}
                    >
                        {Translate("cancel")}
                    </StyledButton>
                    <CSVLink data={dataSource} filename={"templateHistory.csv"} style={{ textDecoration: 'none' }}>
                        <StyledButton
                            color="primary"
                            variant="contained"
                        >
                            {Translate("download")}
                        </StyledButton>
                    </CSVLink>
                </DialogActions>
            </StyledDialog>
        );
    }
}

export default TemplateHistoryInterface;
