import styled from 'styled-components'
import {Card} from "@material-ui/core/";

export const StatusItemError = styled(Card)`
&& {
    height: 50px;
    margin-bottom: 10px;
    > div {
        border-left: 8px solid #CD5C5C;
        color: #6f8296;
        height: 50px;
        justify-content: center;
        display: flex;
        flex-direction: column;
        text-align: left;
        padding-left: 15px;
    }
}`

export const StatusItemSuccess = styled(Card)`
&& {
    height: 50px;
    margin-bottom: 10px;
    > div {
        border-left: 8px solid #4CAF50;
        color: #6f8296;
        height: 50px;
        justify-content: center;
        display: flex;
        flex-direction: column;
        text-align: left;
        padding-left: 15px;
    }
}`