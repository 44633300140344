import React, { Component, Fragment } from 'react'
import Session from "../../utils/session-info"
import PageTitle from '../conector-ui/conector-page-title'
import Breadcrumb from '../conector-ui/conector-breadcrumb'
import { BreadcrumbDiv } from '../conector-ui/conector-styled-components/styled-breadcrumb'
import StyledHeaderAppBar from '../conector-ui/conector-styled-components/styled-header-app-bar'
import SettingButton from './settings-button'
import ScenarioButton from './scenarios-button'
import { Toolbar, IconButton } from '@material-ui/core/'
import { Menu as MenuIcon } from '@material-ui/icons'

class Header extends Component {
    constructor(props) {
        super(props);
        this.state = {
            ...props
        }
    }

    componentWillReceiveProps(props) {
        this.setState({
            ...this.state,
            appState: {...props.appState}
        });
    }

    handleDrawerOpen = _ => {
        let state = {...this.state}
        state.appState.openDrawer = !state.appState.openDrawer
        this.state.appState.refresh(state.appState)
    }

    userLoggedIn = () => { return Session().hasOwnProperty("codUser") }

    render() { 
        const { appState } = this.state

        if (!appState.userLoggedIn) return (<Fragment></Fragment>)

        return (
            <StyledHeaderAppBar>
                <Toolbar style={{ borderBottom: "1px solid #B8C6D6" }} disableGutters={!appState.openDrawer}>
                    <IconButton 
                        className={'menuButton'}
                        color="inherit" 
                        aria-label="open drawer" 
                        onClick={ _ => { this.handleDrawerOpen() }}>
                        <MenuIcon />
                    </IconButton>
                    <PageTitle appState={appState}/>
                    <div className={'groupButtons'}>
                        <ScenarioButton appState={appState} />
                        <SettingButton />
                    </div>
                </Toolbar>
                <BreadcrumbDiv className={(this.userLoggedIn() && appState.openDrawer) ? 'open' : ''}>
                    <Breadcrumb appState={appState} />
                </BreadcrumbDiv>
            </StyledHeaderAppBar>
        )
    }
}

export default Header