import React, { Component, Fragment } from "react";
import Translate from "../../../i18n/translate";
import StyledButton from "../../../components/conector-ui/conector-styled-components/styled-button";
import { DialogContent, Grid, DialogActions } from "@material-ui/core";
import { StyledDialog, StyledDialogTitle } from "../../../components/conector-ui/conector-styled-components/styled-dialog";
import ConectorGrid from "../../../components/conector-ui/conector-grid/conector-grid";
import { CSVLink } from 'react-csv';
import Session from '../../../utils/session-info'

import Service from "./maintenance-service";

class MaintenanceHistory extends Component {
    constructor(props) {
        super(props);
        
        this.state = {
            ...props,
            dataSource: [],
            name: props.name,
            gridOptions: {
				multiSelect: false,
                hideBtnNew: true,
                hideBtnEdit: true,
                hideBtnDelete: true,
                hideBtnCopy: true,
                hideFilter: true,
                sortField:"datechange",
                sortType:"desc",
				columns: [{
                    title: "Templates iniciados",
                    field: "affected_records"
                },{
                    title: "Data Inicio",
                    field: "datStart",
                    cellFilter: "ddMMyyyy-hh:mm"
                }, {
                    title: "Data Fim",
                    field: "datEnd",
                    cellFilter: "ddMMyyyy-hh:mm"
                },{
                    title: "justify",
                    field: "justify"
                }, {
                    title: "user",
                    field: "userresponsable"
                }, {
                    title: "company",
                    field: "companyLog"
                }, {
                    title: "instance",
                    field: "instanceLog"
                }, {
                    title: "Executado Em",
                    field: "datechange",
                    cellFilter: "ddMMyyyy-hh:mm"
                }]
			}
        };
    }

    componentWillMount = () => {
        const { name  } = this.state;
        Service.GetAllByMaintananceHistory(name).then(dataSource => {
            if(dataSource){
                this.setState({ dataSource });
            }
        });
        
    };

    handleTable = () => {
        
        const {
            dataSource,
            gridOptions
        } = this.state;
        if (dataSource){
            return(
                <Fragment>
                    <ConectorGrid 
                        gridOptions={gridOptions} 
                        dataSource={dataSource}
                    ></ConectorGrid>
                </Fragment>
            )
        }
    }

    render() {
        const { dataSource } = this.state;

        return (
            <StyledDialog
                open={true}
                disableEscapeKeyDown={false}
                disableBackdropClock={false}
                fullWidth={false}
                maxWidth={"lg"}
            >
                <StyledDialogTitle className="info">
                    {Translate("maintenance_history")}
                </StyledDialogTitle>
                <DialogContent>
                <Grid  alignItems={"center"} lg={12} md={12} >
                    {this.handleTable()}
                </Grid>
                </DialogContent>
                <DialogActions>
                    <StyledButton
                        color="primary"
                        variant="contained"
                        onClick={this.props.onDismiss}
                    >
                        {Translate("cancel")}
                    </StyledButton>
                    <CSVLink data={dataSource} filename={"maintenanceHistory.csv"} style={{ textDecoration: 'none' }}>
                        <StyledButton
                            color="primary"
                            variant="contained"
                        >
                            {Translate("download")}
                        </StyledButton>
                    </CSVLink>
                </DialogActions>
            </StyledDialog>
        );
    }
}

export default MaintenanceHistory;
