import styled from 'styled-components'
import Button from '@material-ui/core/Button'
import { darken, lighten } from '@material-ui/core/styles/colorManipulator'

const StyledButton = styled(Button)`
  && {
    box-shadow: 0 0;
    min-width: 100px;
    font-size: 0.75rem;
    margin-right: 10px;
    padding: 7px 16px 5px;
    color: ${props => props.theme.default.content.highlightButton.textColor};
    border: 1px solid ${props => props.theme.default.content.highlightButton.borderColor};
    background-color: ${props => props.theme.default.content.highlightButton.bgColor};

    &.hidden { display: none; }
    &:hover {
      border: 1px solid ${props => darken(props.theme.default.content.highlightButton.borderColor, 0.2)};
      background-color: ${props => darken(props.theme.default.content.highlightButton.bgColor, 0.2)};
    }
    
    &.MuiButton-containedPrimary {
      color: ${props => props.theme.default.content.button.textColor};
      border: 1px solid ${props => props.theme.default.content.button.borderColor};
      background-color: ${props => props.theme.default.content.button.bgColor};

      &:hover {
        background-color: ${props => lighten(props.theme.default.content.button.bgColor, 0.8)};
      }
    }

    &.MuiButton-disabled {
      border: 1px solid ${props => props.theme.default.content.button.disabledBorderColor};
    }
    
    span.MuiButton-label {
      display: flex;
      svg { margin-right: 10px; }
      span { margin-left: 10px; }
    }

    &.iconNoMargin {
      span.MuiButton-label { svg { margin-right: 0; }}
    }

    &.no-margin {
      margin-right: 0;
    }

    @media screen and (max-width:740px) {
      min-width: 80px;
      span.MuiButton-label span { display: none; }
    }
  }
`

export default StyledButton