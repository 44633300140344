import styled from "styled-components";

export const PanelTitle = styled.div`
    && {
        font-size: 20px;
        font-family: "Roboto", "Helvetica", "Arial", sans-serif;
        padding-bottom: 10px;
        color: #6f8296;
    }
`;

export const TextDescription = styled.div`
    && {
        font-size: 16px;
        font-family: "Roboto", "Helvetica", "Arial", sans-serif;
        padding-bottom: 10px;
        color: #6f8296;
    }
`;