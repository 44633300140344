import React, { Component } from 'react'
import { components } from 'react-select'
import Translate from '../../../i18n/translate'
import styled from 'styled-components'
import StyledSelect from '../conector-styled-components/styled-select'
import StyledButton from '../conector-styled-components/styled-button'
import StyledIconButton from '../conector-styled-components/styled-icon-button'
import StyledBottomDiv from '../conector-styled-components/styled-bottom-div'
import {
  Input,
  Tooltip,
  Typography
} from '@material-ui/core/'
import {
  Edit,
  Delete,
  Search,
  FileCopy,
  AddCircle,
  ExpandMore
} from '@material-ui/icons/'

const GridToolBarDiv = styled.div`
  display: flex;
  flex-direction: column;
  min-height: 50px;
`

const TopDiv = styled.div`
  width: 100%;
  margin-bottom: 20px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  button { margin-right: 0 !important }
  
  p {  
    font-size: 1.5rem;
    color: #6F8296;
    text-align: left;
  }
`

class ConectorGridToolBar extends Component {
  constructor(props) {
    super(props)

    let filterOptions = []
    this.props.gridOptions.columns.forEach(column => {
      if (!column.hideFilter) {
        let option = { value: column.field, label: Translate(column.title) }
        filterOptions.push(option)
      }
    })

    this.state = {
      gridOptions: this.props.gridOptions,
      headerOptions: this.props.headerOptions,
      selectValue: null,
      filterOptions
    }
  }

  componentWillReceiveProps(props) {
    this.setState(props)
  }

  onChangeIconSelect = (value) => {

    const DropdownIndicator = (props) => {
      if (value) {
        return components.DropdownIndicator && (
          <components.DropdownIndicator {...props}>
            <Search />
          </components.DropdownIndicator>
        )
      } else {
        return components.DropdownIndicator && (
          <components.DropdownIndicator {...props}>
            <ExpandMore />
          </components.DropdownIndicator>
        )
      }
    }

    return { DropdownIndicator }
  }

  setFilter = (target) => {

    let state = Object.assign(this.state)
    state.selectValue = target

    if (target === null) {
      state.gridOptions.filter.field = ""
      state.gridOptions.filter.value = ""
    } else if (state.gridOptions.filter.field !== target.value) {
      state.gridOptions.filter.field = target.value
      state.gridOptions.filter.value = ""
      state.gridOptions.filter.label = target.label
    }
  }

  onChangeSelect = (target) => {

    let state = Object.assign(this.state)
    state.selectValue = target

    if (target === null) {
      state.gridOptions.filter.field = ""
      state.gridOptions.filter.value = ""
    } else if (state.gridOptions.filter.field !== target.value) {
      state.gridOptions.filter.field = target.value
      state.gridOptions.filter.value = ""
      state.gridOptions.filter.label = target.label
    }

    this.setState(state, () => {
      this.state.gridOptions.refresh(this.state)
    })
  }

  onBlurInput = () => {
    this.state.gridOptions.refresh(this.state)
  }

  renderForm = () => {
    if(this.state.gridOptions.columns.length==1){
      this.setFilter(this.state.filterOptions[0])
    }
    if (!this.state.headerOptions.hideFilter) {
      return <form autoComplete="off">
        <StyledSelect
          className={"select"}
          components={this.onChangeIconSelect(!this.state.selectValue ? true : false)}
          onChange={this.onChangeSelect}
          classNamePrefix="select-default"
          placeholder={Translate("select_column")}
          options={this.state.filterOptions}>
        </StyledSelect>
        <Input
          id="standard-search"
          className={!this.state.selectValue ? "hidden" : ""}
          placeholder={
            !this.state.selectValue ?
              "" : `${Translate("existsincolumn")} ${Translate(this.state.selectValue.label)}`
          }
          onChange={
            e => {
              let state = Object.assign(this.state);
              state.gridOptions.filter.value = e.target.value;
              state.gridOptions.filter.field = this.state.selectValue.value;
              this.setState(state)
              this.state.gridOptions.refresh(this.state);
            }
          }
          type="search"
          value={this.state.gridOptions.filter.value}
        />
      </form>
    }
  }

  isEditable = _ => {
    const {
      gridOptions,
      headerOptions
    } = { ...this.state }

    if (!headerOptions.gridEditItem) {
      return;
    }

    if (gridOptions.multiSelect) {
      return gridOptions.selectedRows && gridOptions.selectedRows.length === 1;
    } else {
      return gridOptions.selectedRow && Object.keys(gridOptions.selectedRow).length > 0;
    }
  }

  isDeletable = _ => {
    const { gridOptions, headerOptions } = { ...this.state }

    if (!headerOptions.gridDeleteItem) {
      return
    }

    if (gridOptions.multiSelect) {
      return gridOptions.selectedRows && gridOptions.selectedRows.length;
    } else {
      return gridOptions.selectedRow && Object.keys(gridOptions.selectedRow).length > 0;
    }
  }

  isCopyable = _ => {
    const { gridOptions, headerOptions } = { ...this.state }

    if (!headerOptions.gridCopyItem) {
      return
    }

    if (gridOptions.multiSelect) {
      return gridOptions.selectedRows && gridOptions.selectedRows.length === 1;
    } else {
      return gridOptions.selectedRow && Object.keys(gridOptions.selectedRow).length > 0;
    }
  }

  getSelectedRows = _ => {
    const gridOptions = { ...this.state.gridOptions }
    return gridOptions.multiSelect
      ? gridOptions.selectedRows
      : gridOptions.selectedRow
  }

  render() {

    let headerOptions = { ...this.state.headerOptions }

    return (
      <GridToolBarDiv>
        <TopDiv style={headerOptions.hideTopDiv ? { display: 'none' } : {}}>
          <Typography>{Translate(headerOptions.gridTitle)}</Typography>
          <StyledButton
            variant="contained"
            onClick={_ => {
              headerOptions.gridNewItem()
            }}
            disabled={!headerOptions.gridNewItem}
            className={headerOptions.hideBtnNew ? "hidden" : ""}
          >
            <AddCircle /> {Translate(headerOptions.gridBtn)}
          </StyledButton>
        </TopDiv>
        <StyledBottomDiv>
          <div>
            <Tooltip title={Translate("edit")} placement="top" className={headerOptions.hideBtnEdit ? "hidden" : ""}>
              <span>
                <StyledIconButton
                  disabled={!this.isEditable()}
                  onClick={_ => {
                    headerOptions.gridEditItem(this.getSelectedRows())
                  }}
                >
                  <Edit className={"default"} />
                </StyledIconButton>
              </span>
            </Tooltip>
            <Tooltip title={Translate("remove")} placement="top" className={headerOptions.hideBtnDelete ? "hidden" : ""}>
              <span>
                <StyledIconButton
                  disabled={!this.isDeletable()}
                  onClick={_ => {
                    headerOptions.gridDeleteItem(this.getSelectedRows())
                  }}
                >
                  <Delete className={"default"} />
                </StyledIconButton>
              </span>
            </Tooltip>
            <Tooltip title={Translate("copy")} placement="top" className={headerOptions.hideBtnCopy ? "hidden" : ""}>
              <span>
                <StyledIconButton
                  disabled={!this.isCopyable()}
                  onClick={_ => {
                    headerOptions.gridCopyItem(this.getSelectedRows())
                  }}
                >
                  <FileCopy className={"default"} />
                </StyledIconButton>
              </span>
            </Tooltip>
          </div>
          {this.renderForm()}
        </StyledBottomDiv>
      </GridToolBarDiv>
    )
  }

}

export default ConectorGridToolBar