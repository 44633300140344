import React from 'react'
import styled from 'styled-components'
import CircularProgress from '@material-ui/core/CircularProgress'

const Container = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: calc(100vh - 165px);
`

const LoadingDefault = styled(CircularProgress)`
  && { 
    color: ${props => props.theme.default.content.loading.color};
  }
`

const Loading = () => {
  return (
    <Container>
      <LoadingDefault />
    </Container>
  )
}

export default Loading