import React, { Component } from "react";
import styled from "styled-components";

import {
    Tabs,
    Tab,
    Grid
} from "@material-ui/core";
import { darken } from "@material-ui/core/styles/colorManipulator";

import Translate from "../../../../i18n/translate";

import WizardStructureDetailsTabsFields from "./tabs-components/wizard-structure-details-tabs-fields";
import WizardStructureDetailsTabsExtras from "./tabs-components/wizard-structure-details-tabs-extras";

const StyledGrid = styled(Grid)`
    && {
        position: relative;
        background-color: #fff;
        margin-top: 70px;
    }
`

const StyledTabs = styled(Tabs)`
    && {
        position: absolute;
        width: 100%;
        top: -47px;

        button {
            color: #6F8296;
            font-size: 0.75rem;
            border-top: 1px solid ${props => darken(props.theme.default.content.paper.bgColor, 0.04)};
            border-left: 1px solid ${props => darken(props.theme.default.content.paper.bgColor, 0.04)};
            border-right: 1px solid ${props => darken(props.theme.default.content.paper.bgColor, 0.04)};

            &:hover, &.MuiTab-selected {
                background-color: #fff;
                border-top: 1px solid #B8C6D6;
                border-left: 1px solid #B8C6D6;
                border-right: 1px solid #B8C6D6;
                border-top-left-radius: 4px;
                border-top-right-radius: 4px;
            }
        }

        .MuiTabs-fixed {
            overflow-x: initial;
        }

        .MuiTabs-indicator {
            height: 0;
            background-color: #B8C6D6;
        }
    }
`

const StyledDiv = styled.div`
    && {
        padding: 20px;
        border: 1px solid #B8C6D6;
        border-top-right-radius: 4px;
        border-bottom-left-radius: 4px;
        border-bottom-right-radius: 4px;
        min-height: calc(100vh - 714px);
    }
`

const TabsComponents = [
    WizardStructureDetailsTabsFields,
    WizardStructureDetailsTabsExtras
];

class WizardStructureDetailsTabs extends Component {
    constructor(props) {
        super(props)

        var tabs = [{ label: Translate("detail_field") }];

        if (props.detailsExtras.length) {
            tabs.push({ label: Translate("extras") });
        }

        this.state = {
            ...props,
            selectedTab: props.selectedTab || 0,
            modelOptions: [],
            tabs
        }
    }

    renderTabs = () => {
        const { tabs } = this.state
        return tabs.map(
            (tab) => {
                if (tab.label === Translate("extras")) {
                    if (!this.state.selectedNode) {
                        return (<Tab disabled key={tab.label} label={tab.label} />);
                    }
                }
                return (<Tab key={tab.label} label={tab.label} />);
            }
        );
    }

    componentWillReceiveProps = props => {
        let state = { ...this.state }

        if (props.selectedNode && props.selectedNode.node
            && (
                !state.selectedNode ||
                !(props.selectedNode.node.uuid === state.selectedNode.node.uuid)
            )
        ) {
            state.selectedNode = props.selectedNode
            state.selectedTab = 0
        } else if (!props.selectedNode) {
            state.selectedNode = null
            state.selectedTab = 0
        }

        state.selectedInputFields = props.selectedInputFields;
        state.structureInInput = props.structureInInput;
        state.wizardState = props.wizardState;

        if (props.detailsExtras) {
            state.detailsExtras = props.detailsExtras
        }

        if (props.detailsExtrasAttributes) {
            state.detailsExtrasAttributes = props.detailsExtrasAttributes
        }

        this.setState(state)
    }

    handleChangeTab(_, v) {
        if (this.state.selectedTab === v) {
            return;
        }
        this.setState({ selectedTab: v })
    }

    render() {
        const {
            attributes,
            selectedTab,
            detailsExtras,
            selectedNode,
            detailsExtrasAttributes,
            stepProperties,
            selectedInputFields,
            structureInInput,
            attributeGroups,
            stepStructure,
            wizardState
        } = this.state
        
        return (
            <Grid container>
                <StyledGrid item xs={12}>
                    <StyledTabs
                        value={selectedTab}
                        onChange={this.handleChangeTab.bind(this)}
                    >
                        {this.renderTabs()}
                    </StyledTabs>

                    <StyledDiv>
                        {
                            React.createElement(TabsComponents[selectedTab], {
                                attributes,
                                detailsExtras,
                                attributeGroups,
                                stepStructure,
                                detailsExtrasAttributes,
                                wizardState,
                                selectedNode,
                                stepProperties,
                                selectedInputFields,
                                structureInInput,
                                update: this.props.update,
                                disabled: !selectedNode,
                                setChangedField: this.props.setChangedField
                            })
                        }
                    </StyledDiv>
                </StyledGrid>
            </Grid>
        )
    }
}

export default WizardStructureDetailsTabs