import React, { Component, Fragment } from 'react'
import Session from '../../../utils/session-info'
import Translate from '../../../i18n/translate'
import Observation from '../../../utils/observation-utils.js'
import ConnectionsService from '../connections-service'
import Loading from '../../../components/conector-ui/conector-loading'
import ConectorInputText from '../../../components/conector-ui/conector-input-text'
import ConectorInputPassword from '../../../components/conector-ui/conector-input-password'
import { Grid } from '@material-ui/core'

class Step2 extends Component {
    constructor(props) {
        super(props);

        const { attributeTypes, connection, refresh } = props;

        this.state = {
            attributeTypes,
            connection,
            refresh,
            ready: false
        }
    }

    componentWillMount = _ => {
        let connection = { ...this.state.connection };

        ConnectionsService
            .GetAttributesByCodConnectionMode(connection.codConnectionMode)
            .then((attributes) => {
                let state = { ...this.state };
                state.ready = true;
                state.attributeFields = attributes;

                if (!connection.attributes) {
                    connection.attributes = [];
                }

                attributes.forEach(attr => {
                    if (!connection.attributes.find(att => att.codAttribute === attr.codAttribute)) {
                        connection.attributes.push({
                            isRequired: attr.isRequired,
                            codAttribute: attr.codAttribute,
                            codInstance: Session().codInstance
                        })
                    }
                });

                state.connection = connection;

                this.setState(state, () => {
                    state.stepDisabled = this.stepDisabled();
                    this.props.refresh(state);
                });
            })
    }

    stepDisabled = () => {
        const { attributes } = this.state.connection;
        for (let i = 0; i < attributes.length; i++) {
            const attribute = attributes[i];
            if (attribute.isRequired === "true") {
                if (!attribute.value && attribute.value !== 0) {
                    return true;
                }
            }
        }

        return false;
    }


    handleChange = e => {
        let state = { ...this.state };
        let desValue = e.target.value;

        if (e.type === "blur") {
            desValue = desValue.trim();
        }

        state.connection.attributes.find(attr =>
            attr.codAttribute == e.target.name
        ).value = desValue;

        state.stepDisabled = this.stepDisabled();

        this.state.refresh(state);
    }

    getValue = codAttribute => {
        const { connection } = this.state;
        codAttribute = parseInt(codAttribute, 10);

        const attr = connection.attributes.find(attr =>
            attr.codAttribute === codAttribute
        );

        if (attr && attr.value) {
            return attr.value;
        } else {
            return '';
        }

    }

    renderAttributes = _ => {

        const { attributeTypes, attributeFields } = { ...this.state }

        return attributeFields
            .map(field => {
                const type = attributeTypes.find(type =>
                    type.codAttributeType === field.codAttributeType
                )
                const translatedLabel = Translate(field.label);
                const obs = Observation(field.codAttribute);
                switch (type.desAttributeType) {
                    case "password":
                        return (
                            <Grid item xs={12} sm={6} key={field.codAttribute}>
                                <ConectorInputPassword
                                    label={translatedLabel}
                                    help={`${Translate('enter_field')} ${translatedLabel}`}
                                    name={String(field.codAttribute)}
                                    value={this.getValue(field.codAttribute)}
                                    required={field.isRequired === 'true'}
                                    type={false}
                                    onChange={this.handleChange.bind(this)}
                                />
                            </Grid>
                        )
                    default:
                        return (
                            <Grid item xs={12} sm={6} key={field.codAttribute}>
                                <ConectorInputText
                                    label={translatedLabel}
                                    help= {`${Translate('enter_field')} ${translatedLabel} ${obs}`}
                                    name={String(field.codAttribute)}
                                    value={this.getValue(field.codAttribute)}
                                    required={field.isRequired === 'true'}
                                    onChange={this.handleChange.bind(this)}
                                    onBlur={this.handleChange.bind(this)}
                                />
                            </Grid>
                        )
                }
            })
    }

    render() {
        const { ready } = this.state

        if (!ready) {
            return (<Loading />)
        }

        const fakeInputStyle = { opacity: 0, float: 'left', border: 'none', height: '0', width: '0' }

        return (
            <Grid container spacing={24}>
                <input type="password"
                    name='fake-password'
                    autoComplete='new-password'
                    tabIndex='-1'
                    style={fakeInputStyle}
                />
                {this.renderAttributes()}
            </Grid>
        )
    }
}

export default Step2