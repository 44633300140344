import React, { Component, Fragment } from 'react'
import { connect } from 'react-redux'
import { setBreadcrumb } from '../../store/app-state/actions'
import { Grid, Card, CardContent, Icon } from '@material-ui/core'
import styled from 'styled-components'
import StyledPaper from '../../components/conector-ui/conector-styled-components/styled-paper'
import HomeService from './home-service'
import Session from '../../utils/session-info'
import InternalPageTitle from '../../components/conector-ui/conector-internal-page-title'
import './home.css'
import Monitoramento from '../monitoring/monitoring'

const DashboardItemContainer = styled(StyledPaper)`
    && {
        height: 335px;

        animation: fadein 3s;
        @keyframes fadein {
            from { opacity: 0; }
            to   { opacity: 1; }
        }
    }
`

const DashboardFullWidthItemContainer = styled(StyledPaper)`
    && {
        height: 250px;
    }
`

const GraphTitle = styled.span`
    && {
        font-size: 18px;
        font-family: "Roboto", "Helvetica", "Arial", sans-serif;
        color: #778296;
        font-weight: bold;
    }
`

const CardTitle = styled.span`
    font-size: 40px;
    font-weight: bold;
    margin-bottom: 10px;
`

const CardDescription = styled.span`
    && {
        font-size: 18px;
        bottom: 15px;
        position: absolute;
    }
`

const DashCard = styled(Card)`
    && {
        height: 100%;
        > div {
            position: relative;
            justify-content: center;
            padding: 0 !important;
            display: flex;
            flex-direction: column;
            align-items: center;
            font-family: "Roboto", "Helvetica", "Arial", sans-serif;
            text-align: center;
            border-left: 6px solid;
            height: 100%;
        }
    }
`

const LegendStyle = {
    fontFamily: "Roboto, Helvetica, Arial, sans-serif",
    lineHeight: '30px'
}

const CardsData     = HomeService.CardsData();
const LineGraphData = HomeService.LineGraphData();
const BarGraphData  = HomeService.BarGraphData()

class Home extends Component {
    constructor(props) {
        super(props)
        props.setBreadcrumb([
            { label: 'Dashboard de Monitoramento' }
        ]);
        this.state = {};
    }

    getUserName() {
        const session = Session();
        if (session) {
            return `Bem-vindo ao Conector, ${session.desName}`;
        }
    }

    render() { 
        return (
            <Monitoramento/>
        )
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        setBreadcrumb: (steps) => dispatch(setBreadcrumb(steps))
    }
}

Home = connect(null, mapDispatchToProps)(Home);
export default Home;