import React, { Component } from "react";
import Translate from "../../../i18n/translate";
import StyledButton from "../../conector-ui/conector-styled-components/styled-button";
import {
    DialogContent,
    Grid,
    DialogActions
} from "@material-ui/core";
import {
    StyledDialog,
    StyledDialogTitle
} from "../../conector-ui/conector-styled-components/styled-dialog";
import GenButton from "./generic/button";

class AllVersion extends Component {
    constructor(props) {
        super(props);
    }

    renderVersion = () => {
        return (
            <div>
                <GenButton
							variant="contained"
                            system="Portal"
							onClick={_ => { this.handleClick }}>{"Portal"}
				</GenButton>
                <GenButton
							variant="contained"
                            system="Worker"
							onClick={_ => { this.handleClick }}>{"Worker"}
				</GenButton>
                <GenButton
							variant="contained"
                            system="MicroBack"
							onClick={_ => { this.handleClick }}>{"MicroBack"}
				</GenButton>
                <GenButton
							variant="contained"
                            system="MicroFront"
							onClick={_ => { this.handleClick }}>{"MicroFront"}
				</GenButton>
                <GenButton
							variant="contained"
                            system="MicroDash"
							onClick={_ => { this.handleClick }}>{"MicroDash"}
				</GenButton>
            </div>
        );
    };
    render() {
        return (
            <StyledDialog
                open={true}
                disableEscapeKeyDown={true}
                disableBackdropClock={true}
                fullWidth={true}
                maxWidth={"sm"}
            >
                <StyledDialogTitle className="info">
                    {Translate("current_versions")}
                </StyledDialogTitle>
                <DialogContent>
                    <Grid container={true} spacing={24}>
                        <Grid item={true} xs={24} sm={24} md={24} lg={12} xl={12}>
                            {this.renderVersion()}
                        </Grid>
                    </Grid>
                </DialogContent>
                <DialogActions>
                    <StyledButton
                        color="primary"
                        variant="contained"
                        onClick={this.props.onDismiss}
                    >
                        {Translate("close")}
                    </StyledButton>
                </DialogActions>
            </StyledDialog>
        );
    }
}

export default AllVersion;
