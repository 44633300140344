import React from 'react';
import { Avatar } from '@material-ui/core';
import { Tooltip } from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';


const ImageWithTooltip = withStyles({
  tooltip: {
    backgroundColor: '#b8c6d6',
    color: 'black',
    fontSize: '14px',
    arrow: 'true'
  },
})(Tooltip);

const styles = (theme) => ({
  pulsatingImage: {
    position: 'relative',
    overflow: 'visible'
  },
  avatar: {
    width: '40px',
    height: '40px',
    backgroundColor: 'rgba(52, 52, 52, 0.0)',
    animation: 'pulse 2s infinite',
    boxShadow: theme.shadows[0]
  },
  image: {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: '100%',
    height: 'auto',
    zIndex: 1
  },
  '@keyframes pulse': {
    '0%': {
      transform: 'scale(1)',
    },
    '50%': {
      transform: 'scale(1.3)',
    },
    '100%': {
      transform: 'scale(1)',
    },
  },
});

class InfoVersionButton extends React.Component {
  render() {
    const { classes } = this.props;

    return (
      <ImageWithTooltip title="Nova versão do worker disponível">
        <div className={classes.pulsatingImage}>
          <Avatar className={classes.avatar}>
            <img
              className={classes.image}
              src="/static/images/warninIcon.svg"
              alt="Icon"
            />
          </Avatar>
        </div>
      </ImageWithTooltip>
    );
  }
}

export default withStyles(styles)(InfoVersionButton);
