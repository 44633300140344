import React, { Component, Fragment } from 'react'
import styled from 'styled-components'
import Toast from './toast'
import Snackbar from '@material-ui/core/Snackbar'
import {
    Info,
    Error,
    CheckCircle,
} from '@material-ui/icons'
import Translate from '../../i18n/translate'

const iconList = {
    success : CheckCircle,
    error : Error,
    info : Info,
}

const StyledSnackbar = styled(Snackbar)`
    && {
        font-weight: 700;
        color: ${props => props.theme.default.toast.textColor};
        svg { font-size: 20px; margin-right: 8px; }
        .info { background-color: ${props => props.theme.default.toast.bgColorWarning}; }
        .error { background-color: ${props => props.theme.default.toast.bgColorError}; }
        .success { background-color: ${props => props.theme.default.toast.bgColorSuccess}; }
        .verticalAlign { align-items: center; display: flex; }
    }
`

class ToastComponent extends Component {
    constructor(props) {
        super(props)
        this.state = {
            ...props.options
        }
    }

    componentWillReceiveProps(props) {
        if (props.options 
            && props.options.type 
            && props.options.message) {
            
            this.setState({
                ...props.options
            }, _ => {
                setTimeout(
                    function() {
                        Toast.clearStore();
                        this.setState({
                            type: null,
                            open: false,
                            message: null,
                            timeout: null
                        });
                    }.bind(this), 
                props.options.timeout || 5000)
            })
        }
    }

    render() { 
        const { type, message, timeout } = this.state
        const open = type && message ? true : false
        const Icon = iconList[type]

        if (!open) {
            return (<Fragment></Fragment>)
        }

        return (
            <StyledSnackbar
                anchorOrigin={{
                    vertical  : 'top',
                    horizontal: 'right',
                }}
                open={open}
                autoHideDuration={timeout || 30000000}
                ContentProps={{ className: type }}
                message={
                    <span className={'verticalAlign'}>
                        <Icon /> {Translate(message)}
                    </span>
                }
            />
        )
    }
}

export default ToastComponent