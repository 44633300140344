import styled from 'styled-components'
import Paper from '@material-ui/core/Paper'
import { darken } from '@material-ui/core/styles/colorManipulator'

const StyledPaper = styled(Paper)`
  && {
    padding: 20px;
    background-color: ${props => props.theme.default.content.paper.bgColor};
    border: 1px solid ${props => props.theme.default.content.paper.borderColor};
    box-shadow: 0 0;

    &.bg_darken_04 {
      background-color: ${props => darken(props.theme.default.content.paper.bgColor, 0.04)};
    } 
  }
`

export default StyledPaper