import React from "react";
import styled from "styled-components";

import Typography from "@material-ui/core/Typography";

import Translate from "../../i18n/translate";

const TitlePageDefault = styled(Typography)`

	&& {
		width: 100%;
		text-align: left;
		font-size: 1.5rem;
		display: inline-block;
		color: ${props => props.theme.default.content.internalPageTitle.textColor};

		&:not(.without-margin) {
			padding: 3px 0;
			margin-bottom: 20px;
		}
	}

`;

const InternalPageTitle = ({title, className}) => {
	return (
		<TitlePageDefault className={className}>{Translate(title)}</TitlePageDefault>
	)
};

export default InternalPageTitle