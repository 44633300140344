import React, { Component, Fragment } from 'react'
import { connect } from 'react-redux'
import Session from '../../utils/session-info'
import Translate from '../../i18n/translate'
import Toast from '../../components/toast/toast'
import Loading from '../../components/conector-ui/conector-loading'
import InternalPageTitle from '../../components/conector-ui/conector-internal-page-title'
import StyledPaper from '../../components/conector-ui/conector-styled-components/styled-paper'
import StyledButton from '../../components/conector-ui/conector-styled-components/styled-button'
import ConectorInputText from '../../components/conector-ui/conector-input-text'
import ConectorInputNumber from '../../components/conector-ui/conector-input-number'
import Grid from '@material-ui/core/Grid'
import { setBreadcrumb } from '../../store/app-state/actions'
import InstancesService from './instances-service'
import TagsInput from 'react-tagsinput'
import './../../themes/react-tagsinput.css'
import UserService from "../../routes/users/user-service";
import InstanceHistory from "./instance-history/instance-history";
class InstanceDetails extends Component {
    constructor(props) {
        super(props)

        let instanceID = window.location.hash.substring(12)

        if (typeof (parseInt(instanceID)) !== 'number' && instanceID !== 'add') {
            window.location.replace('#/instances')
        }

        props.setBreadcrumb([
            { label: 'registrations' },
            { label: 'instances', url: '#/instances' },
            {
                label: instanceID !== 'add'
                    ? "edit_instance"
                    : "add_instance"
            }
        ]);

        this.state = {
            dialogOpenInstanceHistory: false,
            instanceID: instanceID,
            validations: [],
            instance: {},
            inputEmails: [],
            loading: true,
            disabledInputs:false
        }
    }

    

    async componentWillMount() {
        const hasPrivilege = await UserService.FindPrivilege(Session().codUser, 1);
        this.setState({ hasPrivilege });

        const { instanceID } = this.state;
        if (instanceID === "add") {
            this.setState({ loading: false });
        } else {
            this.getInstance(instanceID);
        }

        const resEdit = await InstancesService.GetUserPermissionEditInstance();
        this.setState({
            disabledInputs: resEdit,
        });
    }

    getInstance(codInstance) {
        InstancesService
            .GetInstance(codInstance)
            .then(instance => {
                if (!instance.id) {
                    Toast.error('Instância não encontrada');
                    this.goBack();
                } else {
                    this.setState({ instance, loading: false });
                }
            })
    }

    goBack() {
        window.location.replace('#/instances')
    }

    save() {
        this.setState({
            validations: []
        }, _ => {
            var instance = { ...this.state.instance },
                validations = [];

            if (!instance.description) {
                validations.push('description');
            }

            if (!instance.indMaxThreads) {
                validations.push('indMaxThreads');
            }

            if (validations.length) {
                this.setState({ validations });
                return;
            }

            if (!instance.id) {
                instance.codCompany = Session().codCompany;

                InstancesService
                    .PostInstance(instance)
                    .then(onCallback.bind(this));
            } else {
                InstancesService
                    .PutInstance(instance)
                    .then(onCallback.bind(this));
            }

            function onCallback(res, action) {
                if (res && res.ok) {
                    Toast.success('record_success_saved');
                        localStorage.setItem('instance', JSON.stringify(instance));
                        localStorage.setItem('action', action);
                    window.location.replace('#/instances');

                } else {
                    if(res === "Token does not have access to this instance") Toast.error("userWithoutPermissionInstance")
                    else Toast.error('error_saving_record')
                }
            }
        })
    }

    setInputInstanceEmails(emailArray){
        const { instance } = this.state

        let emailRegex = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
        let enteredEmail = emailArray[emailArray.length - 1]

        if(emailArray.length == 0){
            instance.emailNotification = ''
            this.setState({instance})
        } else {
            if(emailRegex.test(enteredEmail)){
                instance.emailNotification = emailArray.join(';')
                this.setState({instance})
            } else Toast.error('invalid_addresss')
        }


    }

    generateTagsInputValue(){
        const { instance } = this.state

        if(instance.emailNotification === '' || instance.emailNotification === undefined) return new Array

        return instance.emailNotification.split(";")
    }

    onCopy(value) {
        const el = document.createElement('textarea')
        el.value = value
        document.body.appendChild(el)
        el.select()
        document.execCommand('copy')
        document.body.removeChild(el)
    }

    handleChange({ target: { name, value } }) {
        var { instance, validations } = this.state;

        if (name === 'indMaxThreads') {
            instance.indMaxThreads = parseInt(value, 10) || null;
        } else {
            instance[name] = value;
        }

        validations = validations.filter(v => v !== name);

        this.setState({ instance, validations });
    }

    copyHash() {
        this.onCopy(this.state.instance.hash)
    }

    copyInstanceToken() {
        this.onCopy(this.state.instance.instanceToken)
    }
    handleOpenInstanceHistory = () => {
        this.setState({ dialogOpenInstanceHistory: true });
    }

    handleDismissInstanceHistory = () => {
        this.setState({dialogOpenInstanceHistory: false});
    }

    render() {
        const {
            instance,
            validations,
            loading,
            disabledInputs,
            hasPrivilege,
            dialogOpenInstanceHistory
        } = this.state

        if (loading) {
            return (<Loading />)
        }

        return (
            <Fragment>
                <InternalPageTitle title={
                    instance.id
                        ? 'edit_instance'
                        : 'new_instance'
                }
                />

                <StyledPaper>
                    <Grid container={true} spacing={24}>
                        <Grid item={true} xs={12} md={6}>
                            <ConectorInputText
                                label={'description'}
                                help={'enter_description'}
                                name={'description'}
                                required={true}
                                disabled={disabledInputs}
                                value={instance.description}
                                onChange={this.handleChange.bind(this)}
                                errorCondition={validations.find(v =>
                                    v === 'description'
                                )}
                            />
                        </Grid>

                        <Grid item={true} xs={12} md={6}>
                            <ConectorInputNumber
                                label={'quantity_threads'}
                                required={true}
                                disabled={disabledInputs}
                                help={'max_number_threads'}
                                name={'indMaxThreads'}
                                value={instance.indMaxThreads}
                                onChange={this.handleChange.bind(this)}
                                errorCondition={validations.find(v =>
                                    v === 'indMaxThreads'
                                )}
                            />
                        </Grid>
                    </Grid>
                    <Grid container={true} spacing={24}>
                        <Grid item={true} xs={12} md={6}>
                            <ConectorInputText
                                label={'hash'}
                                name={"hash"}
                                type={'text'}
                                disabled={true}
                                help={'Hash da Instância'}
                                value={instance.hash}
                                endIcon={'file_copy'}
                                endTooltip={'copy'}
                                endActionButton={this.copyHash.bind(this)}
                            />
                        </Grid>

                        <Grid item={true} xs={12} md={6}>
                            <ConectorInputText
                                label={'token'}
                                name={'token'}
                                type={'text'}
                                help={'Token da Instância'}
                                disabled={true}
                                value={instance.instanceToken}
                                endIcon={'file_copy'}
                                endTooltip={'copy'}
                                endActionButton={this.copyInstanceToken.bind(this)}
                            />
                        </Grid>

                        <Grid item={true} xs={12} md={6}>
                            <Grid item>
                                <TagsInput value={this.generateTagsInputValue()} inputProps={{placeholder: undefined}} onChange={emailArray => this.setInputInstanceEmails(emailArray)}/>
                                <p class="tagsInputLabel">{Translate('enterEmails')}</p>
                            </Grid>
                        </Grid>
                    </Grid>
                    <Grid container={true} spacing={24} justify="flex-end">
                        <Grid item={true}>
                            {hasPrivilege &&
                                <Fragment>
                                    <StyledButton
                                        variant="contained"
                                        onClick={this.handleOpenInstanceHistory}
                                        color="primary"
                                    >
                                        {Translate("instance_history")}
                                    </StyledButton>
                                </Fragment>
                            }
                            <StyledButton
                                variant="contained"
                                onClick={this.goBack.bind(this)}
                                color="primary">
                                {Translate('cancel')}
                            </StyledButton>
                            <StyledButton
                                className="no-margin"
                                variant="contained"
                                onClick={this.save.bind(this)}>
                                {Translate('save')}
                            </StyledButton>
                        </Grid>
                    </Grid>
                </StyledPaper>
                    {
                        (() => {
                            if (dialogOpenInstanceHistory) {
                                return <InstanceHistory id={instance.id} onDismiss={this.handleDismissInstanceHistory.bind(this)} />
                            }
                        })()
                    }
            </Fragment>
        )
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        setBreadcrumb: (steps) => dispatch(setBreadcrumb(steps)),
    }
}

export default connect(null, mapDispatchToProps)(InstanceDetails)

