import React, { Component, Fragment } from 'react';
import { StyledButtonLogin } from '../login-styles'
import ConectorInputText from '../../../components/conector-ui/conector-input-text'
import { Grid } from '@material-ui/core'



class ForgotYourPasswordForm extends Component {
    onChange = ({target: {name, value}}) => {
        this.props.updateValues(name, value);
    }

    render() { 

        const { user } = this.props;

        return ( 
            <Fragment>
                <Grid spaing={24} container className={"marginForm"}>
                    <Grid item xs={12}>
                        <ConectorInputText 
                            label={"Login"}
                            name={"user"}
                            startIcon={"person"}
                            value={user}
                            required={true}
                            onChange={this.onChange}
                            help={'Informe o endereço de e-mail do seu usuário, e receba em alguns instantes um link de recuperação de senha'}
                        />
                    </Grid>
                        <Grid item xs={12}>
                        <StyledButtonLogin fullWidth 
                            type="submit" 
                            variant="contained"
                            onClick={this.props.sendEmail}>                            
                          Enviar e-mail de recuperação
                       </StyledButtonLogin>
                    </Grid>    
                </Grid>

                <Grid container>
                    <Grid item xs={12} sm={12} lg={12} md={12}>
                        <StyledButtonLogin 
                            fullWidth
                            onClick={this.props.setForgotPassword}
                            variant="contained" 
                            className={"goBack"}
                        >
                            Voltar
                        </StyledButtonLogin>
                    </Grid>
                </Grid>
            </Fragment>
        );
    }
}

export default ForgotYourPasswordForm;