import React, { Component, Fragment } from 'react'
import Session from '../../../../utils/session-info'
import AttributeTypeComponentList from '../../attribute-type-component-list'
import Grid from '@material-ui/core/Grid'

class WizardStructureExtras extends Component {
    constructor(props) {
        super(props);

        this.state = {
            options: {},
            structureExtrasAttributes: [],
            ...props
        }
    }

    componentDidMount = () => {
        const { structureExtrasAttributes } = this.state;

        let options = {};
        for (let i = 0; i < structureExtrasAttributes.length; i++) {
            const attr = structureExtrasAttributes[i];
            if (attr.options) {
                options[`options_attr_${attr.codAttribute}`] = attr.options.map(
                    (option) => ({
                        label: option.label,
                        value: option.codAttribute,
                        description: option.description,
                        master: option.CodMaster
                    })
                );
            }
        }
        this.setState({ options });
    }

    getValue = (attr) => {
        const {
            structureExtras,
            stepStructure
        } = this.state;

        const attrExtra = structureExtras.find(strucExtra =>
            strucExtra.codAttribute === attr.codAttribute
        );

        if (!attrExtra) {
            return;
        }

        const attrExtraValue = stepStructure.structureExtras.find(att =>
            att.codStructureExtra === attrExtra.codStructureExtra
        )

        if (!attrExtraValue) {
            return;
        }

        return attrExtraValue.value;
    }

    handleChange = e => {
        var {
            stepStructure,
            structureExtras
        } = this.state;
        const codAttribute = parseInt(e.target.name, 10);
        let desValue = e.target.value;

        if (e.type === "blur") {
            desValue = desValue.trim();
        }

        let extra = stepStructure.structureExtras.find(extra => {
            if (!extra.codStructureExtraValues) {
                return extra.codAttribute === codAttribute;
            } else {
                const codStrucExtra = structureExtras.find(extra =>
                    extra.codAttribute === codAttribute
                ).codStructureExtra;
                return extra.codStructureExtra === codStrucExtra
            }
        })

        if (!extra) {
            extra = {
                codAttribute: codAttribute,
                value: desValue,
                codStructureExtra: structureExtras.find(extra =>
                    extra.codAttribute === codAttribute
                ).codStructureExtra,
                codStructureExtraValues: 0,
                codStructure: stepStructure.codStructure,
                desValue
            }
            stepStructure.structureExtras.push(extra);
        } else {
            extra.value = desValue
        }

        this.props.updateStepStructure(stepStructure)
    }

    handleChangeSelect = e => {
        var { stepStructure, structureExtras } = this.state;

        let extra = stepStructure.structureExtras.find(extra => {
            if (!extra.codStructureExtraValues) {
                return extra.codAttribute === e.master;
            } else {
                const codStrucExtra = structureExtras.find(extra =>
                    extra.codAttribute === e.master
                ).codStructureExtra;
                return extra.codStructureExtra === codStrucExtra
            }
        })

        if (!extra) {
            extra = {
                codAttribute: e.master,
                value: e.description,
                codStructureExtra: structureExtras.find(extra =>
                    extra.codAttribute === e.master
                ).codStructureExtra,
                codStructureExtraValues: 0,
                codStructure: stepStructure.codStructure,
                desValue: e.value
            }
            stepStructure.structureExtras.push(extra);

        } else {
            extra.desValue = e.value;
            extra.value = e.description
        }

        this.props.updateStepStructure(stepStructure)
    }

    handleChangeCheckbox = e => {
        var { stepStructure, structureExtras } = this.state;
        const codAttribute = parseInt(e.target.name, 10);

        let extra = stepStructure.structureExtras.find(strucExtra => {
            if (!strucExtra.codStructureExtraValues) {
                return strucExtra.codAttribute === codAttribute;
            } else {
                const codStrucExtra = structureExtras.find(extra =>
                    extra.codAttribute === codAttribute
                ).codStructureExtra;
                return strucExtra.codStructureExtra === codStrucExtra
            }
        })


        if (!extra) {
            extra = {
                codAttribute: codAttribute,
                value: 'true',
                codStructureExtra: structureExtras.find(extra =>
                    extra.codAttribute === codAttribute
                ).codStructureExtra,
                codStructureExtraValues: 0,
                codStructure: stepStructure.codStructure,
                desValue: 'true'
            }
            stepStructure.structureExtras.push(extra);

        } else {
            const newValue = extra.value === 'true' ? 'false' : 'true';
            extra.value = newValue;
            extra.desValue = newValue;
        }

        this.props.updateStepStructure(stepStructure)
    }

    getSelectValue = attr => {
        const {
            structureExtras,
            stepStructure,
            options
        } = this.state;

        if (!options || !options[`options_attr_${attr.codAttribute}`]) {
            return;
        }

        const extra = stepStructure.structureExtras.find(strucExtra => {
            if (!strucExtra.codStructureExtraValues) {
                return strucExtra.codAttribute === attr.codAttribute;
            } else {
                const codStrucExtra = structureExtras.find(extra =>
                    extra.codAttribute === attr.codAttribute
                ).codStructureExtra;
                return strucExtra.codStructureExtra === codStrucExtra
            }
        })

        if (extra) {
            return options[`options_attr_${attr.codAttribute}`].find(option => extra.value === option.description);
        }
    }

    getCheckValue = attr => {
        const {
            structureExtras,
            stepStructure
        } = this.state;

        const extra = stepStructure.structureExtras.find(strucExtra => {
            if (!strucExtra.codStructureExtraValues) {
                return strucExtra.codAttribute === attr.codAttribute;
            } else {
                const codStrucExtra = structureExtras.find(extra =>
                    extra.codAttribute === attr.codAttribute
                ).codStructureExtra;
                return strucExtra.codStructureExtra === codStrucExtra;
            }
        });

        if (!extra) {
            return;
        }

        return extra.value === 'true';
    }

    render() {
        const { structureExtrasAttributes, options } = this.state;
        return (
            <Fragment>
                {
                    structureExtrasAttributes.map(attr => {
                        const TypeComponent = AttributeTypeComponentList(attr.codAttributeType)
                        return (
                            <Grid item lg={4} key={attr.codAttributeType}>
                                {
                                    React.createElement(TypeComponent, {
                                        required: attr.isRequired === 'true',
                                        options: options['options_attr_' + attr.codAttribute] || [],
                                        label: attr.label,
                                        showTime: true,
                                        checked: (() => {
                                            if (attr.AttributeType.codAttributeType === 5) {
                                                return this.getCheckValue(attr);
                                            } else {
                                                return false;
                                            }
                                        })(),
                                        value: (() => {
                                            switch (attr.AttributeType.codAttributeType) {
                                                case 7: return this.getSelectValue(attr);
                                                default: return this.getValue(attr);
                                            }
                                        })(),
                                        name: String(attr.codAttribute),
                                        onChange: (() => {
                                            switch (attr.AttributeType.codAttributeType) {
                                                case 5: return this.handleChangeCheckbox.bind(this);
                                                case 7: return this.handleChangeSelect.bind(this);
                                                default: return this.handleChange.bind(this);
                                            }
                                        })(),
                                        onBlur: (() => {
                                            if (attr.AttributeType.codAttributeType) {
                                                return this.handleChange.bind(this);
                                            }
                                        })()
                                    })
                                }
                            </Grid>
                        )
                    })
                }
            </Fragment>
        )
    }
}

export default WizardStructureExtras;