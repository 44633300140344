import React, { Fragment, Component } from 'react'
import ObjectUtils from '../../../utils/object-utils'
import ArrayUtils from '../../../utils/array-utils'
import styled from 'styled-components'
import ConnectorGridFilters from './conector-grid-filters'
import Translate from '../../../i18n/translate'
import { FileCopy } from '@material-ui/icons/'
import {
    Table,
    TableFooter,
    TableBody,
    TableCell,
    TableHead,
    TableRow,
    TablePagination,
    TableSortLabel,
    Divider,
    Tooltip,
    Checkbox
} from '@material-ui/core/'
import Toast from '../../toast/toast'

const StyledDivider = styled(Divider)`
    && {
        bottom: 0;
        position: sticky;
        background-color: #B8C6D6;
    }
`

const StyledGridContainer = styled.div`
    && {
        width: 100%;
        overflow: auto;
        &.scrollWidth { 
            hr { width: 100%; }

            &.scrollHeight {
                hr { width: 100%; }
            }
            :before {
                content: "";
                width: 1px;
                /* height: 100%; */
                right: 32px;
                z-index: 9999;
                position: absolute;
                background-color: #B8C6D6;
            }
        }

        &.scrollHeight {
            hr { width: calc(100% - 7px); }
        }

        ::-webkit-scrollbar-corner { background-color: #eee; }
        ::-webkit-scrollbar {
            width: 6px;
            height: 6px;
            background-color: #eee;
            cursor: pointer;
        }

        ::-webkit-scrollbar-thumb {
            border-radius: 10px;
            background-color: rgba(0, 0, 0, 0.2);
            cursor: pointer;
        }
    }
`

const StyledTableFooter = styled(Table)`
    && { 
        background: white;
        tr { border: 1px solid #B8C6D6; }
    }
`

const StyledTable = styled(Table)`
    && {

        tbody > tr.contentRow > td:not(:last-child):not(.checkbox) {
            padding: 10px 24px;
        }

        tbody > tr.contentRow > td:not(.checkbox) {
            padding-top: 10px;
            padding-bottom: 10px
        }

        tbody td.pointer { cursor: pointer; }

        tr {
            height: auto;
            border-right: 1px solid #B8C6D6;
            border-left: 1px solid #B8C6D6;
            &.MuiTableRow-selected { 
                td { 
                    background-color: rgba(224, 224, 224, 1);
                    cursor: default;
                } 
            }

            th {
                color: #4c637b;
                cursor: pointer;
                padding: 10px 24px;
                background-color: #B8C6D6;
                text-transform: uppercase;
                span { color: #4c637b; }
                &:hover { background-color: #b1bfce; }
                &.bgActive { font-weight: bold; font-size: 0.8rem; }
                &.MuiTableCell-paddingCheckbox {
                    width: 50px;
                    padding: 0 12px;
                    svg { color: #6F8296; } 
                }
            }
            
            td {
                position: relative;
                white-space: nowrap; 
                overflow: hidden;
                text-overflow: ellipsis;
                background-color: #fff;
                color: #6F8296;
                line-height: 25px;

                &.MuiTableCell-paddingCheckbox { 
                    padding: 0 12px;
                    svg { color: #6F8296; } 
                }

                .iconCopy {
                    position: absolute;
                    margin-left: -30px;
                    top: calc(50% - 13px);
                    cursor: pointer;
                    &:hover { color: #009CDF; }
                }

                &.notFound {
                    text-align: center;
                    padding-top: 20px;
                    padding-bottom: 20px;
                    font-weight: bold;
                }
            }

            &:last-child { td { border-bottom: 0; }}
            tbody tr { cursor: pointer; }
        }

        thead > tr > th {
            top: 0;
            z-index: 2;
            position: sticky;
        }

        thead > tr > th:last-child { padding-right: 0px; }

        &&.full-size-container {
            width: 100%;
            margin-right: 0;
        }

        margin: auto;
        border-collapse: collapse;
    }
`

class ConectorGridTable extends Component {

    constructor(props) {
        super(props)
        let gridOptions = {
            sortType: "asc",
            currPage: 0,
            pageSize: 80,
            noDataMessage: 'Nenhum registro cadastrado',
            ...props.gridOptions
        }

        if (gridOptions.multiSelect) {
            gridOptions.selectedRows = []
            if (!gridOptions.sortField) {
                gridOptions.sortField = gridOptions.columns[1].field
            }
        } else {
            if (!gridOptions.sortField) {
                gridOptions.sortField = gridOptions.columns[0].field
            }
        }

        if (!gridOptions.notSort) {
            if (props.dataSource != undefined) {
                if (gridOptions.sortType === "asc") {
                    props.dataSource.sort((obj1, obj2) => {
                        return this.getValue(obj1, gridOptions.sortField) > this.getValue(obj2, gridOptions.sortField) ? 1 : -1
                    })
                } else {
                    props.dataSource.sort((obj1, obj2) => {
                        return this.getValue(obj1, gridOptions.sortField) < this.getValue(obj2, gridOptions.sortField) ? 1 : -1
                    })
                }
            }
        }

        this.state = {
            scrollHeight: false,
            scrollWidth: false,
            valueHeight: 0,
            valueWidth: 0,
            dataSource: props.dataSource || [],
            gridOptions
        }

        this.updateDimensions = this.updateDimensions.bind(this);
    }

    componentWillReceiveProps(props) {

        let state = { ...this.state },
            sort = false;

        if (!props.gridOptions.filter
            || !props.gridOptions.filter.field
            || !props.gridOptions.filter.value) {

            state.gridOptions.filter = {};
        } else if (!ObjectUtils.equals(props.gridOptions.filter, state.gridOptions.filter)) {
            state.gridOptions.filter = props.gridOptions.filter;
        }

        if (!ArrayUtils.shallowEquals(props.dataSource, state.dataSource)) {
            state.gridOptions.selectedRows = [];
            state.gridOptions.selectedRow = {};
            state.gridOptions.currPage = 0;
            state.dataSource = props.dataSource;
            sort = true;
        }

        if (props.gridOptions) {

            if (props.gridOptions.noDataMessage) {
                state.gridOptions.noDataMessage = props.gridOptions.noDataMessage;
            }

            if (props.gridOptions.selectedRow) {
                state.gridOptions.selectedRow = props.gridOptions.selectedRow;
            }

        }

        this.setState(state, _ => { if (sort) { this.sort() } });
    }

    sort = (field) => {
        let state = { ...this.state }

        if (state.gridOptions.sortField === field) {
            state.gridOptions.sortType = state.gridOptions.sortType === "asc" ? "desc" : "asc"
        } else if (field) {
            state.gridOptions.sortField = field
            state.gridOptions.sortType = "asc"
        }

        if (state.gridOptions.sortType === "asc") {
            state.dataSource.sort((obj1, obj2) => {
                return this.getValue(obj1, state.gridOptions.sortField)
                    > this.getValue(obj2, state.gridOptions.sortField)
                    ? 1 : -1
            })
        } else {
            state.dataSource.sort((obj1, obj2) => {
                return this.getValue(obj1, state.gridOptions.sortField)
                    < this.getValue(obj2, state.gridOptions.sortField)
                    ? 1 : -1
            })
        }

        this.setState(state)
        this.refresh(this.state)
    }

    renderHeader(gridOptions) {
        let cellIndex = 0

        const renderHeaderCells = () => {
            let cellsArr = []

            if (gridOptions.multiSelect) {
                cellIndex++
                cellsArr.push(
                    <TableCell
                        key={cellIndex}
                        padding="checkbox">
                        <Checkbox
                            color="default"
                            indeterminate={this.state.gridOptions.selectedRows > 0
                                && (this.state.gridOptions.selectedRows
                                    < this.state.dataSource.length)}
                            checked={this.state.dataSource.length > 0
                                && (this.state.gridOptions.selectedRows.length
                                    === this.state.dataSource.length)}
                            onChange={() => {
                                this.alterMultiSelectSelectionState(this.state.gridOptions.selectedRows)
                            }}
                        />
                    </TableCell>
                )
            }

            const title = (coluna, gridOptions) => {
                return gridOptions.sortable
                    ? (
                        <TableSortLabel className={'contentHeader'}
                            active={coluna.field === gridOptions.sortField}
                            direction={gridOptions.sortType}
                        >
                            {Translate(coluna.title)}
                        </TableSortLabel>
                    )
                    : <span>{Translate(coluna.title)}</span>
            }

            gridOptions.columns.forEach(coluna => {
                cellIndex++
                cellsArr.push(
                    <TableCell
                        className={(this.state.gridOptions.sortable && this.state.gridOptions.sortField === coluna.field) ? 'bgActive' : ''}
                        style={{
                            textAlign: coluna.textAlign ? coluna.textAlign : "left",
                            minWidth: coluna.size ? coluna.size + "px" : "50px",
                            maxWidth: coluna.size ? coluna.size + "px" : "auto"
                        }}
                        key={cellIndex}
                        onClick={() => {
                            if (!gridOptions.sortable) {
                                return
                            }
                            this.sort(coluna.field)
                        }}
                    >
                        {title(coluna, gridOptions)}
                    </TableCell>
                )
            })
            return cellsArr
        }

        return (
            <TableHead>
                <TableRow
                    className={this.state.gridOptions.sortable ? 'columnHeader' : ''}
                    key={0}
                >
                    {renderHeaderCells()}
                </TableRow>
            </TableHead>
        )
    }
    refresh = (gridState) => {
        this.setState({ ...this.state, ...gridState }, _ => {
            if (this.props.connectSelectedRows) {
                const { gridOptions } = this.state;
                const data = gridOptions.multiSelect
                    ? gridOptions.selectedRows
                    : gridOptions.selectedRow
                if (data) {
                    this.props.connectSelectedRows(data);
                }
            }
        })
    }

    alterSelectionState = (row) => {

        let state = Object.assign(this.state)

        if (state.gridOptions.multiSelect) {
            return
        }

        if (state.gridOptions.selectedRow !== row) {
            state.gridOptions.selectedRow = row
        }

        this.setState(state)
        this.state.gridOptions.refresh(this.state)
    }

    alterMultiSelectSelectionState = (newElement = []) => {
        let state = { ...this.state }

        if (newElement instanceof Array) {
            if (state.gridOptions.selectedRows.length > 0) {
                state.gridOptions.selectedRows = []
            } else {
                state.gridOptions.selectedRows = [...state.dataSource]
            }
        } else {
            if (this.isSelected(newElement)) {
                let rowIdx
                state.gridOptions.selectedRows.find((el, index) => {
                    rowIdx = index
                    return el === newElement
                })

                state.gridOptions.selectedRows.splice(rowIdx, 1)
            } else {
                state.gridOptions.selectedRows.push(newElement)
            }
        }

        this.setState(state)
        this.state.gridOptions.refresh(this.state)
    }

    isSelected = (row) => {
        if (this.state.gridOptions.multiSelect) {
            if (!this.state.gridOptions.selectedRows) {
                return false;
            }
            return !!this.state.gridOptions.selectedRows.find(el => {
                return ObjectUtils.equals(el, row);
            })
        }
        return ObjectUtils.equals(row, this.state.gridOptions.selectedRow);
    }

    getValue = (obj, path) => {
        var objPath = obj

        if (!path) {
            return
        }

        if (path instanceof Object) {
            path = path.field
        }

        path.split('.').forEach(function (p) {
            if (objPath) {
                objPath = objPath[p]
            }
        })
        return objPath
    }


    onCopy = value => {
        const el = document.createElement('textarea');
        el.value = value;
        document.body.appendChild(el);
        el.select();
        document.execCommand('copy');
        document.body.removeChild(el);
        Toast.success('Valor copiado!');
    }

    renderContent(gridOptions, dataSource) {
        if (!dataSource || !dataSource.length) {
            return (
                <TableBody>
                    <TableRow
                        className={this.state.gridOptions.sortable ? 'contentRow' : ''}
                        key={"notFound"}
                    >
                        <TableCell className={"notFound"}
                            colSpan={gridOptions.columns.length + (gridOptions.multiSelect ? 1 : 0)}>
                            {gridOptions.noDataMessage}
                        </TableCell>
                    </TableRow>
                </TableBody>
            )
        }

        if (gridOptions.filter && gridOptions.filter.field) {
            dataSource = dataSource.filter(obj => {
                let value = gridOptions.filter.field.indexOf('.') !== -1
                    ? this.getValue(obj, gridOptions.filter.field)
                    : obj[gridOptions.filter.field]

                if (typeof (value) === 'string') {
                    return value.toUpperCase().indexOf(gridOptions.filter.value.toUpperCase()) !== -1
                } else {
                    return value.toString().indexOf(gridOptions.filter.value) !== -1
                }
            })
        }

        let rowIndex = 0

        const criarRow = (row) => {
            rowIndex++
            let cellIndex = 0, cellsArr = []

            if (this.state.gridOptions.multiSelect) {
                cellsArr.push(
                    <TableCell className={"checkbox"} key={cellIndex} padding="checkbox">
                        <Checkbox
                            color="default"
                            checked={this.isSelected(row)}
                            onChange={() => this.alterMultiSelectSelectionState(row)}
                        />
                    </TableCell>
                )
            }

            gridOptions.columns.forEach(coluna => {
                let value = coluna.field.indexOf('.') !== -1
                    ? this.getValue(row, coluna.field)
                    : row[coluna.field]

                if (coluna.cellFilter) {
                    value = ConnectorGridFilters(value, coluna.cellFilter)
                }

                var copy = coluna.copy ?
                    <Tooltip title="Copy" placement="top">
                        <FileCopy className={'iconCopy'} onClick={() => { this.onCopy(value) }} />
                    </Tooltip>
                    : " "

                cellIndex++

                cellsArr.push(
                    <TableCell
                        title={value}
                        key={rowIndex + '+' + cellIndex}
                        className={this.state.gridOptions.multiSelect ? "" : "pointer"}
                        style={{
                            textAlign: coluna.textAlign ? coluna.textAlign : "left",
                            minWidth: coluna.size ? coluna.size + "px" : "50px",
                            maxWidth: coluna.size ? coluna.size + "px" : "auto",
                            paddingLeft: coluna.copy ? "50px" : "24px"
                        }}
                    >
                        {copy}
                        {coluna.translate ? Translate(value) : value}
                    </TableCell>
                )
            })

            return (
                <TableRow
                    className={this.state.gridOptions.sortable ? 'contentRow' : ''}
                    selected={this.isSelected(row)}
                    onClick={() => { this.alterSelectionState(row) }}
                    key={rowIndex}
                    hover={true}>
                    {cellsArr}
                </TableRow>
            )
        }

        return (
            <TableBody>
                {
                    dataSource
                        .slice(
                            gridOptions.currPage * gridOptions.pageSize,
                            ((gridOptions.currPage + 1) * gridOptions.pageSize > dataSource.length)
                                ? dataSource.length
                                : (gridOptions.currPage + 1) * gridOptions.pageSize
                        )
                        .map(criarRow.bind(this))
                }
            </TableBody>
        )
    }

    renderFooter(gridOptions, dataSource) {

        if (gridOptions.pageSize > dataSource.length) {
            return (<TableFooter></TableFooter>)
        }

        return (
            <TableFooter>
                <TableRow>
                    <TablePagination
                        page={gridOptions.currPage}
                        rowsPerPage={gridOptions.pageSize}
                        onChangePage={(e, currPage) => {
                            gridOptions.currPage = currPage
                            this.setState({ gridOptions })
                            this.refresh(this.state)
                        }}
                        labelRowsPerPage="Itens por página:"
                        labelDisplayedRows={() => {
                            let label = 'Exibindo de '
                            gridOptions.currPage === 0
                                ? dataSource.length ? label += '1' : label += '0'
                                : label += gridOptions.currPage * gridOptions.pageSize
                            label += ' até '
                            label += ((gridOptions.currPage + 1) * gridOptions.pageSize > dataSource.length)
                                ? dataSource.length
                                : (gridOptions.currPage + 1) * gridOptions.pageSize
                            label += ", de " + dataSource.length + " registros"
                            return label
                        }}
                        rowsPerPageOptions={[]}
                        count={dataSource.length}
                    ></TablePagination>
                </TableRow>
            </TableFooter>
        )
    }

    updateDimensions() {
        let state = { ...this.state }
        state.valueHeight = window.innerHeight - 310
        state.valueWidth = window.innerWidth > 960 ? window.innerWidth - 280 : window.innerWidth
        state.scrollHeight = this.scrollHeightVisible(this.element)
        state.scrollWidth = this.scrollWidthVisible(this.element)
        this.setState(state)
    }

    componentDidMount() {
        this.updateDimensions()
        window.addEventListener("resize", this.updateDimensions)
    }

    componentDidUpdate() {
        let valueHeight = window.innerHeight - 310
        let valueWidth = window.innerWidth > 960 ? window.innerWidth - 280 : window.innerWidth
        let scrollHeight = this.scrollHeightVisible(this.element)
        let scrollWidth = this.scrollWidthVisible(this.element)

        if (this.state.valueHeight !== valueHeight) {
            this.setState({ valueHeight: valueHeight })
        }

        if (this.state.valueWidth !== valueWidth) {
            this.setState({ valueWidth: valueWidth })
        }

        if (this.state.scrollHeight !== scrollHeight) {
            this.setState({ scrollHeight: scrollHeight })
        }

        if (this.state.scrollWidth !== scrollWidth) {
            this.setState({ scrollWidth: scrollWidth })
        }
    }

    scrollHeightVisible = (element) => {
        return element.scrollHeight > element.clientHeight
    }

    scrollWidthVisible = (element) => {
        return element.scrollWidth > element.clientWidth
    }

    render() {
        return (
            <Fragment>
                <StyledGridContainer
                    ref={div => { this.element = div }}
                    style={{ maxHeight: this.state.valueHeight + 'px', maxWidth: this.state.valueWidth + 'px' }}
                    className={(this.state.scrollHeight ? 'scrollHeight ' : ' ') + (this.state.scrollWidth ? 'scrollWidth ' : ' ')}
                >
                    <StyledTable id="gridContent" className={this.state.gridOptions.fullSize ? "full-size-container" : ""}>
                        {this.renderHeader(this.state.gridOptions)}
                        {this.renderContent(this.state.gridOptions, Object.assign(this.state.dataSource))}
                    </StyledTable>
                    <StyledDivider />
                </StyledGridContainer>
                <StyledTableFooter>
                    {this.renderFooter(this.state.gridOptions, this.state.dataSource)}
                </StyledTableFooter>
            </Fragment>
        )
    }

    componentWillUnmount() {
        window.removeEventListener("resize", this.updateDimensions)
    }
}

export default ConectorGridTable;