import React from 'react'
import ReactDOM from 'react-dom'
import { Provider } from 'react-redux'
import store from './store/store'
import App from './components/app/app'
//import registerServiceWorker from './register-service-worker'
import ColorsTheme from './themes/colors/default-theme-colors'
import DefaultThemeSettings from './themes/default-theme'
import { ThemeProvider } from 'styled-components'
import { createMuiTheme, createGenerateClassName } from '@material-ui/core/styles'
import JssProvider from 'react-jss/lib/JssProvider'
import automaticallyLogOut from './utils/automaticallyLogOut'

const themeSettings = DefaultThemeSettings.getColors(ColorsTheme)
const theme = createMuiTheme({
	typography: {
        useNextVariants: true,
        suppressDeprecationWarnings: true
    },
    default: themeSettings
})

const generateClassName = createGenerateClassName({
    dangerouslyUseGlobalCSS: true
});

function supportsLocalStorage() {
    return typeof(Storage) !== 'undefined'
}

if (!supportsLocalStorage()) {
    ReactDOM.render( 
        <Provider store={store}>
            <div>Favor atualizar o seu navegador</div>
        </Provider>, document.getElementById('root')
    )
} else {
    //Inicia função responsavel por realizar o deslogamento automático por inatividade do usuário em 5 minutos
    automaticallyLogOut()
    ReactDOM.render( 
        <Provider store={store}>
            <JssProvider generateClassName={generateClassName}>
                <ThemeProvider theme={theme}>
                    <App/>
                </ThemeProvider>
            </JssProvider>
        </Provider>, document.getElementById('root')
    )
}

//registerServiceWorker()