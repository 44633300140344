import React, { Component } from 'react'
import { components } from 'react-select'
import styled from 'styled-components'
import Translate from '../../i18n/translate'
import StyledButton from '../../components/conector-ui/conector-styled-components/styled-button'
import Typography from '@material-ui/core/Typography'
import {
    ExpandMore,
    FileCopy,
    Search,
    AddCircle
} from '@material-ui/icons/'

const GridToolBarDiv = styled.div`
    display: flex;
    flex-direction: column;
    min-height: 50px;
`

const TopDiv = styled.div`
    width: 100%;
    margin-bottom: 20px;
    display: flex;
    justify-content: space-between;
    align-items: center;

    p {  
        font-size: 1.5rem;
        color: #6F8296;
        text-align: left;
    }
`

class ProfileToolbar extends Component {
    constructor(props) {
        super(props)

        this.state = {
            ...props
        }
    }

    componentWillReceiveProps(props) {
        this.setState({...props});
    }

    onChangeIconSelect = (value) => {
        const DropdownIndicator = (props) => {
            if (value) {
                return components.DropdownIndicator && (
                    <components.DropdownIndicator {...props}>
                        <Search />
                    </components.DropdownIndicator>
                )
            } else {
                return components.DropdownIndicator && (
                    <components.DropdownIndicator {...props}>
                        <ExpandMore />
                    </components.DropdownIndicator>
                )
            }
        }

        return { DropdownIndicator }
    }

    onChangeSelect = (target) => {
        let state = Object.assign(this.state);
        state.selectValue = target

        if (target === null) {
            state.gridOptions.filter.field = "";
            state.gridOptions.filter.value = "";
        } else if (state.gridOptions.filter.field !== target.value) {
            state.gridOptions.filter.field = target.value;
            state.gridOptions.filter.value = "";
            state.gridOptions.filter.label = target.label;
        }

        this.setState(state, () => {
            this.state.gridOptions.refresh(this.state);
        });
    }

    onBlurInput = () => {
        this.state.gridOptions.refresh(this.state);
    }

    render() {
        const {
            newProfile,
            copyProfile,
            hideButtons
        } = this.state;

        return (
            <GridToolBarDiv>
                <TopDiv>
                    <Typography>{Translate('profile_list')}</Typography>
                    <div style={hideButtons ? { display: 'none' } : {}}>
                        <StyledButton
                            variant="contained"
                            onClick={copyProfile}
                            disabled={!copyProfile}
                        >
                            <FileCopy /> {Translate('copy_profile')}
                        </StyledButton>
                        <StyledButton
                            className="no-margin"
                            variant="contained"
                            onClick={newProfile}
                        >
                            <AddCircle /> {Translate('add_profile')}
                        </StyledButton>
                    </div>
                </TopDiv>
            </GridToolBarDiv>
        )
    }

}

export default ProfileToolbar