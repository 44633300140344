import styled from "styled-components";
import Typography from "@material-ui/core/Typography";

export const TimelineTitle = styled(Typography)`
    && {
        background-color: #4c637b;
        position: relative;
        padding: 5px 15px;
        font-size: 1rem;
        color: #fff;
        position: absolute;
        width: calc(100% - 30px);
        z-index: 999;
        text-align: center;
        &&:first-child {
            top: 0;
        }
        &&:last-child {
            bottom: 0;
        }
    }
`;

export const TimelineDiv = styled.div`
    border-left: 5px solid #4c637b;
    position: relative ;
    height: calc(100vh - 146px);
    padding: 0;
    margin-top: 33px;
    overflow-y: auto;
`;

export const TimelineCard = styled.div`
    background-color: #f4f4f4;
    margin: 10px 20px 10px 35px;
    padding: 10px 10px 0;
    box-shadow: 0 0;
    min-height: 75px;
    position: relative;
    overflow: initial;
    font-family: "Roboto", "Helvetica", "Arial", sans-serif;
    border-radius: 5px;
    border: 1px solid #b8c6d6;
    &:hover {
        background-color: #eee;
    }

    &:before {
        font-family: "Material Icons";
        content: "";
        position: absolute;
        width: 15px;
        height: 15px;
        border-bottom-right-radius: 50%;
        border-top-right-radius: 50%;
        display: inline-block;
        left: -36px;
        top: calc(50% - 13px);
        padding: 5px;
        background-color: #6daedb;

        color: #fff;
        font-weight: normal;
        font-style: normal;
        font-size: 15px;
        line-height: 1;
        letter-spacing: normal;
        text-transform: none;
        white-space: nowrap;
        word-wrap: normal;
        direction: ltr;
        font-feature-settings: "liga";
        -webkit-font-smoothing: antialiased;
    }

    &.success {
        &:before {
            content: "check";
            background-color: #52b4b7;
        }
    }

    &.warning {
        &:before {
            content: "priority_high";
            background-color: #ffa000;
        }
    }

    &.error {
        &:before {
            content: "close";
            background-color: #d32f2f;
        }
    }

    &:after {
        content: "";
        position: absolute;
        border-style: solid;
        display: inline-block;
        border-color: transparent #b8c6d6;
        border-width: 10px 10px 10px 0;
        left: -10px;
        top: calc(50% - 10px);
    }

    > div:last-child {
        width: 100%;
        border-top: 1px solid #b8c6d6;
    }

    h3,
    h4,
    h5 {
        margin: 0;
        color: #6f8296;
    }

    h3 {
        font-size: 0.875rem;
        @media screen and (max-width: 600px) {
            margin-top: 40px;
        }
        @media screen and (max-width: 400px) {
            font-size: 0.6rem;
        }
    }
`;

export const TimelineIcon = styled.div`
    position: relative;
`;

export const DropZoneDiv = styled.div`

    width: calc(100% - 70px);
    height: 100%;
    float: right;
    background-color: #fff;
    border: 1px solid #4c637b;
    border-radius: 4px;
    position: relative;
    box-sizing: border-box;

`;
