import { userService } from './service';
import Toast from '../../components/toast/toast'
import Translate from "../../i18n/translate"
import { getAllGlobals } from '../globals/actions'
import moment from "moment"

export function login(username, password, userIP, onError) {
  return async (dispatch, getState) => {
    userService
      .login(username, password, userIP)
      .then(res => {
        if (!res.avatar) res.avatar = "data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBzdGFuZGFsb25lPSJubyI/PjwhRE9DVFlQRSBzdmcgUFVCTElDICItLy9XM0MvL0RURCBTVkcgMS4xLy9FTiIgImh0dHA6Ly93d3cudzMub3JnL0dyYXBoaWNzL1NWRy8xLjEvRFREL3N2ZzExLmR0ZCI+PHN2ZyBjbGFzcz0iaWNvbiIgaGVpZ2h0PSI1MTIiIHZpZXdCb3g9IjAgMCAxMDI0IDEwMjQiIHZlcnNpb249IjEuMSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj48cGF0aCBkPSJNNTEyIDI0LjM4MDk1MkMyNDIuNTkwNDc2IDI0LjM4MDk1MiAyNC4zODA5NTIgMjQyLjU5MDQ3NiAyNC4zODA5NTIgNTEyczIxOC4yMDk1MjQgNDg3LjYxOTA0OCA0ODcuNjE5MDQ4IDQ4Ny42MTkwNDggNDg3LjYxOTA0OC0yMTguMjA5NTI0IDQ4Ny42MTkwNDgtNDg3LjYxOTA0OFM3ODEuNDA5NTI0IDI0LjM4MDk1MiA1MTIgMjQuMzgwOTUyeiBtLTcuMzE0Mjg2IDE3MC42NjY2NjdjOTAuMjA5NTI0IDAgMTY0LjU3MTQyOSA3MC43MDQ3NjIgMTY0LjU3MTQyOSAxNTguNDc2MTkxcy03My4xNDI4NTcgMTU4LjQ3NjE5LTE2NC41NzE0MjkgMTU4LjQ3NjE5QzQxNC40NzYxOSA1MTIgMzQxLjMzMzMzMyA0NDEuMjk1MjM4IDM0MS4zMzMzMzMgMzUzLjUyMzgxczczLjE0Mjg1Ny0xNTguNDc2MTkgMTYzLjM1MjM4MS0xNTguNDc2MTkxek03OTIuMzgwOTUyIDc4Mi42Mjg1NzFjMCA0NS4xMDQ3NjItOTUuMDg1NzE0IDQ2LjMyMzgxLTIxMi4xMTQyODUgNDYuMzIzODFINDQzLjczMzMzM0MzMjYuNzA0NzYyIDgyOC45NTIzODEgMjMxLjYxOTA0OCA4MjguOTUyMzgxIDIzMS42MTkwNDggNzgyLjYyODU3MXYtMTMuNDA5NTIzYzAtMTEzLjM3MTQyOSA5NS4wODU3MTQtMjA0LjggMjEyLjExNDI4NS0yMDQuOGgxMzYuNTMzMzM0YzExNy4wMjg1NzEgMCAyMTIuMTE0Mjg2IDkxLjQyODU3MSAyMTIuMTE0Mjg1IDIwNC44djEzLjQwOTUyM3oiIGZpbGw9IiM2NjY2NjYiIC8+PC9zdmc+";

        if (!res.language) res.language = "pt-BR";

        const dateFormat = "YYYY-MM-DD HH:mm:ss";
        
        localStorage.setItem('user', JSON.stringify(res));
        localStorage.setItem("loginTime", moment().format(dateFormat));

        window.location.replace('#/') 

        dispatch(getAllGlobals(getState));
      })
      .catch(res => {
        if (onError) {
          onError();
        }
        switch (res.status) {
          case 403:
            Toast.error('invalid_user')
            break;
          case 405:
            Toast.error('user_blocked')
            break;
          case 401:
            res.json()
            .then(res => {
              if (res.message == 'first_login') localStorage.setItem("firstLogin", true)
              localStorage.setItem("tokenResetExpiredPassword", res.Token)
            })
            .finally(() => window.location.reload())
            break;
          case 409:
            Toast.error(Translate("userAlreadyLoggedIntoTheSystem"))
            break;
          default:
            Toast.error('internal_error')
            break;
        }
      });
  }
};

export const postChangedAuthentication = (codCompany, codInstance) => {
  var auths = userService.postAuthenticationChange(codCompany, codInstance);
  return auths;
}